define('projecthelm/routes/income-protection/compare/simple', ['exports', 'projecthelm/routes/income-protection/compare/layout', 'projecthelm/utils/global-tracking', 'projecthelm/utils/adobe-tracking'], function (exports, _layout, _globalTracking, _adobeTracking) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _layout.default.extend({
        theme: Ember.inject.service(),

        afterModel: function afterModel(quote) {
            var products = quote.get('products').sortBy('value').toArray();
            var vertical = quote.get('quoteType');
            var text = this.get('theme.id') === 'compare-the-market' ? 'Summary View' : 'Price';
            _globalTracking.showResults(vertical, products, text);
            _adobeTracking.trackToolbar(text);
        }
    });
});