define('projecthelm/components/quiz-progress', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        questions: null,
        current: null
    });
});