define('projecthelm/components/sticky-header', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['sticky-header'],
        activeClass: 'sticky-active',
        triggerClass: 'js-sticky-trigger',

        /**
         * Add scroll trigger on didInsertElement only if
         * isPalm (application controller) is true. Also fires
         * on change of isPalm to capture screen resize.
         */
        didInsertElement: Ember.observer('media.isPalm', function () {
            if (this.get('media.isPalm')) {
                Ember.$(window).on("scroll resize", { scope: this }, this.onWindowScroll);
            }
        }),

        /**
         * Creates a throttled version of onWindowScrollThrottle.
         */
        onWindowScroll: function onWindowScroll(event) {
            Ember.run.throttle(event.data.scope, 'onWindowScrollThrottle', 250, false);
        },


        /**
         * Adds sticky-active class if user has scrolled below table header.
         *
         */
        onWindowScrollThrottle: function onWindowScrollThrottle() {

            var $triggerEl = Ember.$('.' + this.get('triggerClass'));

            if (!$triggerEl.length) {
                return;
            }

            var triggerOffset = $triggerEl.offset().top;

            if (Ember.$(document).scrollTop() > triggerOffset && !this.get('isOffCanvasOpen')) {
                this.$().addClass(this.get('activeClass'));
            } else {
                this.$().removeClass(this.get('activeClass'));
            }
        },


        /**
         * Disable the scroll event.
         */
        removeEvent: function removeEvent() {
            Ember.$(window).off("scroll resize", this.onWindowScroll);
        },


        /**
         * Trigger removeEvent on teardown of view.
         */
        willDestroyElement: function willDestroyElement() {
            this.removeEvent();
            this.$().removeClass(this.get('activeClass'));
        },


        /**
         * Trigger removeEvent on change of isPalm
         * and only if isPalm returns false.
         */
        disableStickyHeader: Ember.observer('media.isPalm', function () {
            if (!this.get('media.isPalm')) {
                this.removeEvent();
            }
        })

    });
});