define('projecthelm/controllers/comparison', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        currentUser: Ember.inject.service(),
        queryParams: ['prospectid'],

        prospectid: Ember.computed.alias('currentUser.prospectId')
    });
});