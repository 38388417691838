define('projecthelm/routes/income-protection', ['exports', 'projecthelm/routes/quote', 'projecthelm/utils/forms'], function (exports, _quote, _forms) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _quote.default.extend({
        currentUser: Ember.inject.service('current-user'),
        beforeModel: function beforeModel(transition) {
            var params = transition.queryParams;

            if (params.gender) {
                this.get('currentUser').set('ipQuote.gender', params.gender);
            }

            if (params.income) {
                var applicationController = this.controllerFor('application');
                applicationController.set('incomeResultsInput', true);
                this.get('currentUser').set('ipQuote.income', transition.queryParams.income);
                this.get('currentUser').set('ipQuote.benefit', (0, _forms.calculateMaxBenefit)(transition.queryParams.income));
            }
        },


        quoteType: 'ip-quote',
        quoteTypeRoute: 'income-protection'
    });
});