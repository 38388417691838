define('projecthelm/models/quiz-question', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        scores: Ember.inject.service('quiz-scores'),

        title: _emberData.default.attr('string'),
        answers: _emberData.default.hasMany('quiz-answer'),
        currentAnswer: Ember.computed('scores.answers', function () {
            return this.get('scores').fetch(this.get('id'));
        }),

        allAnswerValues: Ember.computed.mapBy('answers', 'value'),
        highestAnswerValue: Ember.computed.max('allAnswerValues')
    });
});