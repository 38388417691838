define('projecthelm/services/tal-calc-progress', ['exports', 'tal-calculator/services/tal-calc-progress'], function (exports, _talCalcProgress) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _talCalcProgress.default;
    }
  });
});