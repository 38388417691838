define('projecthelm/routes/tal-calc-results', ['exports', 'tal-calculator/routes/tal-calc-results'], function (exports, _talCalcResults) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _talCalcResults.default;
    }
  });
});