define("projecthelm/controllers/modals/apply", ["exports", "projecthelm/mixins/modal"], function (exports, _modal) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_modal.default, {
        gtm: Ember.inject.service(),
        currentUser: Ember.inject.service(),
        lead: Ember.computed.alias('currentUser.lead'),
        product: Ember.computed.alias('model'),

        ctmProductClick: Ember.observer('product', function () {
            var _this = this;

            this.get('gtm').setProps({
                event: 'productClick',
                hitTimeStamp: new Date(),
                ecommerce: {
                    click: {
                        products: [this.get('product')].map(function (product, index) {
                            return {
                                name: product.get('company') + " - " + product.get('name'),
                                id: product.get('id'),
                                price: product.get('valueRounded'),
                                brand: product.get('company'),
                                category: _this.get('currentUser.coverString'),
                                variant: _this.get('currentUser.viewString'),
                                position: index + 1
                            };
                        })
                    }
                }
            });
        }),

        actions: {
            applyOffline: function applyOffline(lead) {

                dataLayer.push({
                    'event': 'submitApplication'
                });

                dataLayer.push({
                    event: 'submitModal',
                    name: 'apply'
                });

                this.get('currentUser').submitLead(lead);
            },
            applyOnline: function applyOnline(lead) {
                var product = this.get('product');

                dataLayer.push({
                    event: 'applicationProgress',
                    eventAction: 'Step 2: Start Application',
                    policyType: product.get('name')
                });

                this.get('currentUser').submitLead(lead);
                this.transitionToRoute('customise', product);
            },
            applyFasttrack: function applyFasttrack(lead) {
                dataLayer.push({
                    'event': 'submitFasttrack'
                });

                this.get('currentUser').submitLead(lead);

                var affiliate = this.get('currentUser.activeQuote.id');
                var policy = this.get('product.id');

                var token = window.btoa(JSON.stringify({
                    email: this.get('lead.email'),
                    name: this.get('lead.name'),
                    phone: this.get('lead.phone')
                }));

                window.location = "http://application.lifebroker.com.au/#/" + affiliate + "/" + policy + "/?token=" + token;
            },
            saveQuote: function saveQuote() {
                var _this2 = this;

                if (this.get('quote.leadCreated')) {
                    this.get('quote').save().then(function () {
                        Ember.get(_this2, 'flashMessages').add({
                            message: 'Your comparison has been saved and can be retrieved using the link emailed to you previously.',
                            type: 'success'
                        });
                    });
                    this.send('closeModal');
                } else {
                    this.send('openModal', 'saveQuote');
                }
            }
        }
    });
});