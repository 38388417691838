define('projecthelm/components/cta-occupation', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.heading = this.get('innerContent').htmlSafe();
        },


        actions: {
            compareWithOccupation: function compareWithOccupation() {
                var redirect = '/comparison/life-insurance?occupation=';
                var occupation = this.get('occupation');

                var event = {
                    event: 'occupationCtaSubmit',
                    occupation: 'Not Selected'
                };

                if (occupation) {
                    redirect = redirect + occupation.reference;
                    event.occupation = occupation.value;
                }

                dataLayer.push(event);

                window.location = redirect;
            }
        }
    });
});