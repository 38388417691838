define('projecthelm/controllers/customise/important-information/product-disclosure-statement', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        customiser: Ember.inject.service(),
        actions: {
            submit: function submit() {
                this.get('step').set('isComplete', true);
                this.transitionToRoute('customise.important-information.duty-of-disclosure');
            }
        }
    });
});