define('projecthelm/components/get-a-callback-embed', ['exports', 'projecthelm/utils/forms', 'moment', 'ember-validations', 'projecthelm/mixins/call-options-embed', 'projecthelm/data/public-holidays', 'projecthelm/utils/global-tracking'], function (exports, _forms, _moment, _emberValidations, _callOptionsEmbed, _publicHolidays, _globalTracking) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var computed = Ember.computed,
        inject = Ember.inject;
    var alias = computed.alias;
    exports.default = Ember.Component.extend(_emberValidations.default, Ember.Evented, _callOptionsEmbed.default, {
        currentUser: inject.service(),
        quote: alias('currentUser.activeQuote'),
        lead: alias('currentUser.lead'),

        progress: 'Request-Call',
        requestCall: true,
        stateOptions: _forms.stateOptions,
        acceptedTerms: false,
        submitted: false,
        isProcessing: false,
        hideTerms: false,

        minDate: (0, _moment.default)(),
        maxDate: (0, _moment.default)().add(2, 'weeks'),
        disabledDates: ['Sat', 'Sun'].concat(_toConsumableArray(_publicHolidays.default)),

        theme: Ember.inject.service(),

        validations: {
            'lead.name': _forms.validation.name,
            'lead.email': _forms.validation.email,
            'lead.state': _forms.validation.state,
            'lead.phone': _forms.validation.phone,
            'callDay': _forms.validation.callDay,
            'callWindow': _forms.validation.callWindow,
            'acceptedTerms': {
                acceptance: { message: "Please accept the terms and conditions to proceed" }
            }
        },

        bindLeadEventListeners: Ember.on('init', function () {
            var _this = this;

            var currentUser = this.get('currentUser');
            currentUser.on('leadSubmissionSuccess', function () {
                _this.set('submitted', true);
            });

            currentUser.on('leadSubmissionComplete', function () {
                _this.set('isProcessing', false);
            });
        }),

        init: function init() {
            if (this.get('hideTerms')) {
                this.set('acceptedTerms', true);
            }
            this._super.apply(this, arguments);
        },


        actions: {
            reset: function reset() {
                this._reset();
            },
            submit: function submit() {
                if (!this.get('isValid')) {
                    this.set('displayErrors', true);

                    if (this.get('hasValidPhone')) {
                        this.set('displayPhoneErrors', true);
                    }

                    return;
                }
                this.set('isProcessing', true);

                var properties = new Ember.Object();
                var options = new Ember.Object();

                properties.additional = this.getProperties(['comments', 'callTime']);
                if (this.get('callWindow')) {
                    properties.additional.callbackRange = this.get('callWindow');
                }

                properties.additional.progress = this.get('progress');

                this.get('currentUser').submitLead(properties, options);

                var eventName = this.get('progress') === 'no-products' ? 'no_products_form_submit' : 'need_help_form_submit';
                _globalTracking.generalData(this.get("currentUser.activeQuote.lifeBenefit"), this.get("currentUser.activeQuote.tpdBenefit"), this.get("currentUser.activeQuote.traumaBenefit"), this.get("currentUser.activeQuote.income"), this.get("currentUser.activeQuote.benefit"), this.get("currentUser.activeQuote.gender"), this.get("currentUser.activeQuote.age"), this.get("currentUser.activeQuote.postcode"), this.get("currentUser.activeQuote.state"), this.get("currentUser.activeQuote.smoker"), this.get("currentUser.activeQuote.occupation"), eventName);
            },
            leadSubmission: function leadSubmission() {
                this._super();
            },
            leadSubmissionSuccess: function leadSubmissionSuccess() {
                // Push event to GTM
                dataLayer.push({
                    event: 'submitModal',
                    name: 'requestCall'
                });
            }
        }
    });
});