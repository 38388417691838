define('projecthelm/components/postcode-auto-complete', ['exports', 'projecthelm/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var inject = Ember.inject,
        computed = Ember.computed;
    exports.default = Ember.Component.extend({
        currentUser: inject.service(),
        suggestions: [],
        fieldActive: false,
        // Set dev URL to the wrangler dev server
        endpoint: _environment.default.environment === 'production' ? _environment.default.host : '//helm-api-stable.lifebroker.com.au',

        showSuggestions: computed('fieldActive', 'suggestions', function () {
            return this.get('fieldActive') && this.get('suggestions').length;
        }).readOnly(),

        scrollable: computed('suggestions', function () {
            // Used to only include scroll bar on MS Edge when it is needed...
            return this.get('suggestions').length > 4 ? 'postcode-autofill__list--scroll' : '';
        }).readOnly(),

        actions: {
            handleKeyUp: function handleKeyUp(value) {
                var _this = this;

                if (value.length > 3) {
                    var endpoint = this.get('endpoint');
                    var data = fetch(endpoint + '/v1/postcode', {
                        method: 'POST',
                        body: JSON.stringify({ q: value })
                    });

                    data.then(function (res) {
                        return res.json();
                    }).then(function (data) {
                        // TODO - This is a patch for handling API responses between local / stable. Make responses uniform and remove.
                        data = data.localities ? data.localities.locality : data;
                        // API responds with an array until full postcode given and it swaps to the single matching result object... 
                        data = data.category ? [data] : data;
                        if (!data || !data.length) return;
                        var formattedData = data.map(function (obj) {
                            var location = obj.location.split(' ');
                            // Fix capitalisation...
                            location = location.reduce(function (acc, current) {
                                return acc + ' ' + (current.charAt(0) + current.slice(1).toLowerCase());
                            }, '');
                            return {
                                label: location + ', ' + obj.state + ', ' + obj.postcode,
                                postcode: obj.postcode,
                                state: obj.state.toLowerCase()
                            };
                        });
                        _this.set('suggestions', formattedData);
                    });
                } else {
                    this.set('suggestions', []);
                }
            },
            focusIn: function focusIn() {
                this.set('fieldActive', true);
            },
            focusOut: function focusOut() {
                var _this2 = this;

                // Wait for focus item to update
                window.setTimeout(function () {
                    // If focus is not in the list or input, then hide 
                    if (document.activeElement.closest('.postcode-autofill')) return;
                    _this2.set('fieldActive', false);
                }, 300);
            },
            setPostcode: function setPostcode(value, event) {
                if (event.type == 'click' || event.type == 'keydown' && event.key == 'Enter') {
                    this.set('fieldActive', false);
                    event.preventDefault();
                    this.get('currentUser.activeQuote').setProperties({
                        postcode: value.postcode
                    });
                    this.get('currentUser.activeQuote').setProperties({
                        state: value.state.toLowerCase()
                    });
                }
            }
        }
    });
});