define('projecthelm/models/lead', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr;
    exports.default = _emberData.default.Model.extend({
        name: attr('string'),
        email: attr('string'),
        phone: attr('string'),
        state: attr('string'),
        ipBenefit: attr('boolean', { defaultValue: null }),
        lifeBenefit: attr('boolean', { defaultValue: null }),
        sendEmail: attr('boolean', { defaultValue: false }),
        additional: attr({
            defaultValue: function defaultValue() {
                return {};
            }
        }),
        quote: _emberData.default.belongsTo('quote', { async: true }),
        quoteType: attr('string'),
        product: _emberData.default.belongsTo('product', { async: true })

    });
});