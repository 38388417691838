define('projecthelm/components/power-select', ['exports', 'ember-power-select/components/power-select'], function (exports, _powerSelect) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _powerSelect.default.extend({
        searchEnabled: false,
        searchField: 'label'
    });
});