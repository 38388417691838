define('projecthelm/controllers/income-protection/edit', ['exports', 'projecthelm/controllers/ip-insurance-quote', 'projecthelm/utils/forms'], function (exports, _ipInsuranceQuote, _forms) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var inject = Ember.inject;
    exports.default = _ipInsuranceQuote.default.extend({
        applicationController: inject.controller('application'),
        tooltips: _forms.tooltips,

        validations: {
            'quote.benefit': {
                numericality: {
                    allowBlank: true,
                    onlyInteger: true,
                    lessThanOrEqualTo: 'maxBenefit',
                    messages: {
                        lessThanOrEqualTo: 'Please enter an amount which is less than 70% of your income'
                    }
                }
            }
        },

        actions: {
            reset: function reset() {

                if (this.get('model')) {
                    this.get('model').rollbackAttributes();
                }

                this.set('displayErrors', false);
            },
            confirmExit: function confirmExit() {
                var quote = this.get('model');

                if (quote.get('hasDirtyAttributes') || !quote.get('leadCreated')) {
                    this.send('openModal', 'saveBeforeNewQuote', quote);
                } else {
                    this.send('createThirdPartyQuote');
                }
            },


            /**
             *
             * @method updateCoverTriggered
             *
             */
            updateCoverTriggered: function updateCoverTriggered() {

                if (this.get('coverHasNotChange')) {
                    return;
                }

                if (this.get('isValid')) {

                    if (this.get('hasCoverTypeChanged') && this.get('hasShortlist')) {
                        this.send('openModal', 'displayCoverHasChanged', this.get('model'));
                        return;
                    }

                    this.send('replaceQuote', this.get('model'));

                    this.set('displayErrors', false);
                } else {

                    this.set('displayErrors', true);
                }
            }
        }
    });
});