define('projecthelm/controllers/customise/important-information/duty-of-disclosure', ['exports', 'ember-validations'], function (exports, _emberValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_emberValidations.default, {
        customiser: Ember.inject.service(),
        displayTranscript: false,

        validations: {
            dutyAgree: {
                acceptance: { message: 'Please accept the duty of disclosure to proceed' }
            }
        },

        actions: {
            submit: function submit() {
                if (this.get('isValid')) {
                    this.send('completeStep', this.get('step'));
                } else {
                    this.get('displayErrors', true);
                }

                this.send('goToNextStep');
            }
        }
    });
});