define('projecthelm/controllers/income-protection', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed,
        inject = Ember.inject;
    var alias = computed.alias;
    exports.default = Ember.Controller.extend({
        applicationController: inject.controller('application'),
        aboutYouController: inject.controller('income-protection/about-you'),
        chooseCoverController: inject.controller('income-protection/choose-cover'),

        isAboutYouComplete: alias('aboutYouController.isComplete'),
        isChooseCoverComplete: alias('chooseCoverController.isComplete')
    });
});