define('projecthelm/controllers/customise/pre-assessment', ['exports', 'ember-validations'], function (exports, _emberValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed;
    exports.default = Ember.Controller.extend(_emberValidations.default, {
        customiser: Ember.inject.service(),
        assessment: computed.alias('customiser.assessment'),

        validations: {
            'assessment.heartCancerOrOrganCondition': {
                presence: { message: 'This field is required' }
            },
            'assessment.familyHistory': {
                presence: { message: 'This field is required' }
            },
            'assessment.bankrupt': {
                presence: { message: 'This field is required' }
            },
            'assessment.illicitDrugs': {
                presence: { message: 'This field is required' }
            },
            'assessment.smoker': {
                presence: { message: 'This field is required' }
            },
            'assessment.bloodPressureChecked': {
                presence: {
                    'if': function _if(object) {
                        return object.get('assessment.highBloodPressure');
                    },
                    message: 'This field is required'
                }
            },
            'assessment.bloodPressureOver': {
                presence: {
                    'if': function _if(object) {
                        return object.get('assessment.bloodPressureChecked');
                    },
                    message: 'This field is required'
                }
            },
            'assessment.cholesterolChecked': {
                presence: {
                    'if': function _if(object) {
                        return object.get('assessment.highCholesterol');
                    },
                    message: 'This field is required'
                }
            },
            'assessment.cholesterolOver': {
                presence: {
                    'if': function _if(object) {
                        return object.get('assessment.cholesterolChecked');
                    },
                    message: 'This field is required'
                }
            },
            'assessment.cholesterolMedication': {
                presence: {
                    'if': function _if(object) {
                        return object.get('assessment.cholesterolOver');
                    },
                    message: 'This field is required'
                }
            },
            'assessment.sleepApnoeaDiagnosed': {
                presence: {
                    'if': function _if(object) {
                        return object.get('assessment.sleepApnoea');
                    },
                    message: 'This field is required'
                }
            },
            'assessment.cpapMachine': {
                presence: {
                    'if': function _if(object) {
                        return object.get('assessment.sleepApnoeaDiagnosed');
                    },
                    message: 'This field is required'
                }
            },
            'assessment.thyroidConditionDiagnosed': {
                presence: {
                    'if': function _if(object) {
                        return object.get('assessment.thyroidCondition');
                    },
                    message: 'This field is required'
                }
            },
            'assessment.thyroidConditionTreatment': {
                presence: {
                    'if': function _if(object) {
                        return object.get('assessment.thyroidConditionDiagnosed');
                    },
                    message: 'This field is required'
                }
            },
            'assessment.thyroidConditionMedication': {
                presence: {
                    'if': function _if(object) {
                        return object.get('assessment.thyroidConditionTreatment');
                    },
                    message: 'This field is required'
                }
            },
            'assessment.familyHistoryDiagnosed': {
                presence: {
                    'if': function _if(object) {
                        return object.get('assessment.familyHistory');
                    },
                    message: 'This field is required'
                }
            },
            'assessment.bankruptDischarged': {
                presence: {
                    'if': function _if(object) {
                        return object.get('assessment.bankrupt');
                    },
                    message: 'This field is required'
                }
            },
            'assessment.motorSportsCompete': {
                presence: {
                    'if': function _if(object) {
                        return object.get('assessment.motorSports');
                    },
                    message: 'This field is required'
                }
            },
            'assessment.weight': {
                presence: { message: 'This field is required' }
            },
            'assessment.height': {
                presence: { message: 'This field is required' }
            }
        },

        hasBackNeckShoulderCondition: computed.or('assessment.bnsGeneralPain', 'assessment.bnsProlapsedDisc', 'assessment.bnsSlippedDisc', 'assessment.bnsDiscProtrusion', 'assessment.bnsHerinatedDisc', 'assessment.bnsDiscBulge'),

        hasMentalHealthCondition: computed.or('assessment.mhDepression', 'assessment.mhAnxiety', 'assessment.mhPanicAttacks', 'assessment.mhStress', 'assessment.mhPsychosis', 'assessment.mhSchizophrenia', 'assessment.mhBipolarDisorder', 'assessment.mhAttemptedSuicide', 'assessment.mhChronicFatigue', 'assessment.mhPostNatalDepression', 'assessment.mhOther'),

        revisedTermsMessage: Ember.String.htmlSafe('Based on the information provided, the insurer may require further information when assessing your application or apply a loading (a premium increase) or exclusion (exclusion of a certain condition or injury) to your policy. If you have any questions at all, please contact our team on <a href="tel:135433">13 54 33</a> for assistance.'),

        furtherInfoMessage: Ember.String.htmlSafe('When assessing your application the insurer may require further information about this. If you have any questions, please call our team on <a href="tel:135433">13 54 33</a>.'),

        bmi: computed('assessment.height', 'assessment.weight', function () {
            return Number(this.get('assessment.weight')) / Math.pow(Number(this.get('assessment.height')) / 100, 2);
        }),

        badBMI: computed('bmi', function () {
            return this.get('bmi') > 32 || this.get('bmi') < 19;
        }),

        hasFailedBloodPressure: computed.and('assessment.highBloodPressure', 'assessment.bloodPressureChecked', 'assessment.bloodPressureOver'),

        hasFailedCholesterol: computed('assessment.highCholesterol', 'assessment.cholesterolOver', 'assessment.cholesterolMedication', function () {
            return this.get('assessment.highCholesterol') && this.get('assessment.cholesterolOver') && !this.get('assessment.cholesterolMedication');
        }),

        hasFailedMentalHealth: computed.or('assessment.mhSchizophrenia', 'assessment.mhBipolarDisorder', 'assessment.mhAttemptedSuicide'),

        hasFailedThyroidCondition: computed('assessment.thyroidCondition', 'assessment.thyroidConditionTreatment', 'assessment.thyroidConditionMedication', function () {
            return this.get('assessment.thyroidCondition') && this.get('assessment.thyroidConditionTreatment') && !this.get('assessment.thyroidConditionMedication');
        }),

        hasFailedBankrupt: computed('assessment.bankrupt', 'assessment.bankruptDischarged', function () {
            return this.get('assessment.bankrupt') && !this.get('assessment.bankruptDischarged');
        }),

        hasFailedBMI: computed('badBMI', 'assessment.highBloodPressure', 'assessment.highCholesterol', 'assessment.sleepApnoea', 'assessment.thyroidCondition', function () {
            return this.get('badBMI') && this.get('assessment.highBloodPressure') || this.get('badBMI') && this.get('assessment.highCholesterol') || this.get('badBMI') && this.get('assessment.sleepApnoea') || this.get('badBMI') && this.get('assessment.thyroidCondition') || this.get('badBMI') || false;
        }),

        hasFailedBackNeckShoulderCondition: computed('hasBackNeckShoulderCondition', 'badBMI', 'assessment.highBloodPressure', 'assessment.highCholesterol', 'assessment.sleepApnoea', 'assessment.thyroidCondition', function () {
            return this.get('hasBackNeckShoulderCondition') && this.get('assessment.highBloodPressure') || this.get('hasBackNeckShoulderCondition') && this.get('assessment.highCholesterol') || this.get('hasBackNeckShoulderCondition') && this.get('assessment.sleepApnoea') || this.get('hasBackNeckShoulderCondition') && this.get('assessment.thyroidCondition') || this.get('hasBackNeckShoulderCondition') && this.get('assessment.badBMI') || false;
        }),

        hasFailedFamilyHistory: computed('assessment.familyHistory', 'hasBackNeckShoulderCondition', 'badBMI', 'assessment.highBloodPressure', 'assessment.highCholesterol', 'assessment.sleepApnoea', 'assessment.thyroidCondition', function () {
            return this.get('assessment.familyHistory') && this.get('assessment.highBloodPressure') || this.get('assessment.familyHistory') && this.get('assessment.highCholesterol') || this.get('assessment.familyHistory') && this.get('assessment.sleepApnoea') || this.get('assessment.familyHistory') && this.get('assessment.thyroidCondition') || this.get('assessment.familyHistory') && this.get('badBMI') || this.get('assessment.familyHistory') && this.get('hasBackNeckShoulderCondition') || false;
        }),

        hasFailedAssessment: computed.or('assessment.diabetes', 'assessment.heartCancerOrOrganCondition', 'assessment.illicitDrugs', 'hasFailedBackNeckShoulderCondition', 'hasFailedBankrupt', 'hasFailedBloodPressure', 'hasFailedBMI', 'hasFailedCholesterol', 'hasFailedFamilyHistory', 'hasFailedMentalHealth', 'hasFailedThyroidCondition'),

        hasPassedAssessment: computed.not('hasFailedAssessment'),

        bloodPressureObserver: Ember.observer('assessment.highBloodPressure', function () {
            if (!this.get('assessment.highBloodPressure')) {
                this.setProperties({
                    'assessment.bloodPressureChecked': null,
                    'assessment.bloodPressureOver': null
                });
            }
        }),

        cholesterolObserver: Ember.observer('assessment.highCholesterol', function () {
            if (!this.get('assessment.highCholesterol')) {
                this.setProperties({
                    'assessment.cholesterolChecked': null,
                    'assessment.cholesterolOver': null,
                    'assessment.cholesterolMedication': null
                });
            }
        }),

        sleepApnoeaObserver: Ember.observer('assessment.sleepApnoea', function () {
            if (!this.get('assessment.sleepApnoea')) {
                this.setProperties({
                    'assessment.sleepApnoeaDiagnosed': null,
                    'assessment.cpapMachine': null
                });
            }
        }),

        thyroidConditionObserver: Ember.observer('assessment.thyroidCondition', function () {
            if (!this.get('assessment.thyroidCondition')) {
                this.setProperties({
                    'assessment.thyroidConditionDiagnosed': null,
                    'assessment.thyroidConditionTreatment': null,
                    'assessment.thyroidConditionMedication': null
                });
            }
        }),

        bmiObserver: Ember.observer('bmi', function () {
            this.set('assessment.bmi', this.get('bmi'));
        }),

        actions: {
            submit: function submit() {
                if (this.get('isValid') && this.get('hasPassedAssessment')) {
                    dataLayer.push({
                        event: 'applicationProgress',
                        eventAction: 'Step 5: Pre Assessment'
                    });
                    this.send('completeStep', this.get('section'));
                } else if (this.get('isValid') && this.get('hasFailedAssessment')) {
                    this.replaceRoute('customise.pre-assessment-failed');
                    return false;
                } else {
                    this.set('displayErrors', true);
                }

                this.transitionToRoute('customise.policy-options');
            }
        }
    });
});