define('projecthelm/models/life-quote', ['exports', 'projecthelm/models/quote', 'ember-data'], function (exports, _quote, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr;
    exports.default = _quote.default.extend({
        quoteType: attr('string', {
            defaultValue: 'life'
        }),

        lifeCover: attr('boolean', {
            defaultValue: true
        }),

        lifeBenefit: attr('number'),

        tpdCover: attr('boolean', {
            defaultValue: false
        }),

        tpdBenefit: attr('number'),

        traumaCover: attr('boolean', {
            defaultValue: false
        }),

        tpdAnyOwn: attr('string', {
            defaultValue: 'A'
        }),

        traumaBenefit: attr('number'),

        buyBack: attr('boolean', { defaultValue: false }),
        reinstateAfterClaim: attr('boolean', { defaultValue: false }),
        waiverOnClaim: attr('boolean', { defaultValue: false })
    });
});