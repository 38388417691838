define('projecthelm/helpers/tal-calc-currency', ['exports', 'tal-calculator/helpers/tal-calc-currency'], function (exports, _talCalcCurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _talCalcCurrency.default;
    }
  });
  Object.defineProperty(exports, 'formatCurrency', {
    enumerable: true,
    get: function () {
      return _talCalcCurrency.formatCurrency;
    }
  });
});