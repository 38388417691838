define('projecthelm/components/broker/registration-form', ['exports', 'ember-validations', 'projecthelm/utils/forms'], function (exports, _emberValidations, _forms) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed;
    var alias = computed.alias,
        empty = computed.empty;
    exports.default = Ember.Component.extend(_emberValidations.default, Ember.Evented, {
        lead: alias('currentUser.lead'),
        formieFormHandle: 'brokerRegistration',
        stateOptions: _forms.stateOptions,

        validations: {
            'fields.customerName': _forms.validation.name,
            'fields.state': _forms.validation.state,
            'fields.email': _forms.validation.email,
            'fields.phone': _forms.validation.phone,
            'memberOfMortgageGroup': {
                presence: { message: 'Please confirm if you are a member of a mortgage group' }
            },
            'fields.mortgageGroup': {
                conditionalPresence: {
                    if: 'memberOfMortgageGroup',
                    message: 'Please specify the name of your mortgage group'
                }
            }
        },

        hasValidMemberOfMortgageGroup: empty('errors.memberOfMortgageGroup'),

        init: function init() {
            this._super.apply(this, arguments);

            this.setProperties({
                displayErrors: false,
                isProcessing: false,
                formSubmitted: false,
                fields: {},
                memberOfMortgageGroup: null
            });
        },
        _reset: function _reset() {
            this.setProperties({
                formSubmitted: false,
                isProcessing: false,
                displayErrors: false,
                fields: {},
                memberOfMortgageGroup: null
            });
        },


        actions: {
            createSubmission: function createSubmission() {
                var _this = this;

                if (!this.get('isValid')) {

                    this.set('displayErrors', true);

                    if (this.get('hasValidMemberOfMortgageGroup')) {
                        this.set('displayMortgageGroupErrors', true);
                    }

                    return;
                }

                this.set('isProcessing', true);
                this.send('formSubmission');

                var formData = new FormData();
                Object.keys(this.get('fields')).forEach(function (key) {
                    return formData.append('fields[' + key + ']', _this.get('fields')[key]);
                });
                formData.append('action', 'formie/submissions/submit');
                formData.append('handle', this.get('formieFormHandle'));
                formData.append(window.csrfTokenName, window.csrfTokenValue);

                fetch('/', {
                    method: 'POST',
                    credentials: 'same-origin',
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        Accept: 'application/json'
                    },
                    body: formData
                }).then(function () {
                    _this.send('formSubmissionSuccess');
                }).catch(function (error) {
                    _this.send('formSubmissionFail', error);
                }).finally(function () {
                    _this.set('isProcessing', false);
                });
            },
            formSubmission: function formSubmission() {
                this.set('formSubmitted', true);
            },
            formSubmissionFail: function formSubmissionFail(error) {
                this._reset();

                Ember.get(this, 'flashMessages').add({
                    message: 'We\'re sorry, something went wrong and we couldn\'t process your question. To speak to a consultant, call  1300 304 964.',
                    type: 'danger',
                    sticky: true
                });

                throw Error(error);
            },
            formSubmissionSuccess: function formSubmissionSuccess() {
                dataLayer.push({
                    event: 'brokerAccount',
                    accountAction: 'Create Account'
                });
            }
        }
    });
});