define('projecthelm/router', ['exports', 'ember-router-scroll', 'projecthelm/config/environment', 'tal-calculator/router', 'projecthelm/utils/adobe-tracking'], function (exports, _emberRouterScroll, _environment, _router, _adobeTracking) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend(_emberRouterScroll.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Ember.Router.reopen({
    trackRouteChange: _adobeTracking.pageView.on('didTransition')
  });

  Router.map(function () {
    // Index
    this.route('comparison', function () {

      // Life Insurance
      this.route('life-insurance', { resetNamespace: true }, function () {
        this.route('about-you');
        this.route('choose-cover');

        this.route('compare', { path: '/compare/:lifeQuote_id' }, function () {

          this.route('simple', function () {
            this.route('shortlist');
          });

          this.route('detailed', function () {
            this.route('shortlist');
          });

          this.route('loading');
        });

        this.route('no-products');
        this.route('continue-quote');
      });

      // // Income Protection
      this.route('income-protection', { resetNamespace: true }, function () {
        this.route('about-you');
        this.route('choose-cover');

        this.route('compare', { path: '/compare/:ipQuote_id' }, function () {

          this.route('simple', function () {
            this.route('shortlist');
          });

          this.route('detailed', function () {
            this.route('shortlist');
          });

          this.route('loading');
        });

        this.route('no-products');
        this.route('continue-quote');
      });

      this.route('customise', { path: '/customise/:product_id', resetNamespace: true }, function () {
        this.route('index', { path: '/' });
        this.route('introduction');
        this.route('pre-assessment');
        this.route('pre-assessment-failed');
        this.route('summary');
        this.route('important-information', function () {
          this.route('product-disclosure-statement');
          this.route('duty-of-disclosure');
          this.route('standard-exclusions');
        });
        this.route('policy-options', function () {
          this.route('type-of-premium');
          this.route('occupation-cover');
          this.route('waiting-and-benefit-periods');
          this.route('policy-style');
          this.route('premium-frequency');
          this.route('additional-options');
        });
      });

      this.route('expired', { resetNamespace: true });
      this.route('request-a-callback', { resetNamespace: true });

      // Calculator
      (0, _router.default)(this, '/calculator');

      this.route('error404', { path: '/*wildcard' });
    });

    this.route('loading');

    // Error routes
    this.route('error500');
    this.route('error404');

    // CMS route for use with ember islands PHP application
    this.route('cms', { path: '/*wildcard' });
  });

  exports.default = Router;
});