define('projecthelm/components/apply-offline', ['exports', 'ember-validations', 'projecthelm/utils/forms', 'projecthelm/mixins/call-options-embed', 'projecthelm/utils/iselect-tracking', 'moment', 'projecthelm/data/public-holidays', 'projecthelm/utils/adobe-tracking', 'projecthelm/utils/global-tracking'], function (exports, _emberValidations, _forms, _callOptionsEmbed, _iselectTracking, _moment, _publicHolidays, _adobeTracking, _globalTracking) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = Ember.Component.extend(_emberValidations.default, _callOptionsEmbed.default, {
        gtm: Ember.inject.service(),
        currentUser: Ember.inject.service(),
        theme: Ember.inject.service(),
        progress: 'Apply',
        requestCall: false,
        stateOptions: _forms.stateOptions,

        minDate: (0, _moment.default)(),
        maxDate: (0, _moment.default)().add(2, 'weeks'),
        disabledDates: ['Sat', 'Sun'].concat(_toConsumableArray(_publicHolidays.default)),

        init: function init() {
            this._super.apply(this, arguments);
            // if (this.get('currentUser.lead.email')) {
            //     const lead =  EmberObject.create({
            //         product: this.get('product'),
            //         sendEmail: false,
            //         additional: {
            //             progress: 'Select',
            //             callTime: this.get('callTime'),
            //         },
            //     });
            //     this.get('onSubmit')(lead);
            // }
        },


        validations: {
            'lead.name': _forms.validation.name,
            'lead.email': _forms.validation.email,
            'lead.state': _forms.validation.state,
            'lead.phone': _forms.validation.phone,
            'callDay': _forms.validation.callDay,
            'callWindow': _forms.validation.callWindow
        },

        ctmSubmitCallBack: function ctmSubmitCallBack() {
            var _this = this;

            this.get('gtm').setProps({
                event: 'submitCallBack',
                hitTimeStamp: new Date(),
                submitCallBack: {
                    callMeWhen: this.get('requestCall') ? "custom" : "business hours",
                    callMeDay: this.get('callDay'),
                    callMeTime: this.callWindowLookup(this.get('callWindow')),
                    dateTimeSubmitted: new Date()
                },
                ecommerce: {
                    checkout: {
                        products: [this.get('product')].map(function (product) {
                            return {
                                name: product.get('name'),
                                id: product.get('id'),
                                price: product.get('valueRounded'),
                                brand: product.get('company'),
                                category: _this.get('currentUser.coverString'),
                                variant: _this.get('currentUser.viewString'),
                                quantity: 1
                            };
                        })
                    }
                }
            });
        },


        actions: {
            submit: function submit() {
                if (!this.get('isValid')) {
                    this.set('displayErrors', true);
                    return;
                }
                _globalTracking.submitSidebarForm(this.get('product'));
                (0, _iselectTracking.trackSubmit)();
                _adobeTracking.complete(this.get('product').get('name') + ' - ' + this.get('product').get('company'));
                var lead = Ember.Object.create({
                    product: this.get('product'),
                    sendEmail: false,
                    additional: {
                        progress: 'Apply',
                        callTime: this.get('callTime'),
                        callbackRange: this.get('callWindow')
                    }
                });

                this.get('onSubmit')(lead);
                this.set('leadSubmitted', true);
                this.ctmSubmitCallBack();
            },
            saveQuote: function saveQuote() {
                this.get('saveQuote')();
            }
        }
    });
});