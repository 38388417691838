define('projecthelm/serializers/application', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.RESTSerializer.extend({
        isNewSerializerAPI: true,

        // All POST/PUT will not have a root key
        serializeIntoHash: function serializeIntoHash(data, type, record, options) {
            Ember.merge(data, this.serialize(record, options));
        }
    });
});