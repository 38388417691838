define('projecthelm/utils/iselect-tracking', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var getCookie = function getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    };

    var getAndResetSessionIDCookie = exports.getAndResetSessionIDCookie = function getAndResetSessionIDCookie() {
        // If cookie exists, use that ID, but reset the expiry . If doesn't exist, set one.
        if (!document.cookie) return 0;
        var ID = getCookie("sessionID") || Date.now(); // use timestamp as unique ID
        var now = new Date();
        var time = now.getTime();
        var expireTime = time + 1800000; // 30 mins;
        now.setTime(expireTime);
        document.cookie = 'sessionID=' + ID + ';expires=' + now.toUTCString() + ';path=/';
        return ID;
    };

    var trackingEnabled = function trackingEnabled() {
        // Dont track if not iSelect and don't track if browser has blocked tracking scripts ... 
        return window.s && window.s.t && window.s.tl && window.iselect && window._satellite && window._satellite.track;
    };

    var trackNeeds1 = exports.trackNeeds1 = function trackNeeds1(productType) {
        if (trackingEnabled() && window.iselect.analytics.vertical.step !== "Needs 1 [sf2]") {
            window.setTimeout(function () {
                window.s.clearVars();
                window.iselect.analytics.vertical.name = productType;
                window.iselect.analytics.vertical.step = "Needs 1 [sf2]";
                window.iselect.analytics.vertical.sessionID = getAndResetSessionIDCookie();
                window.s.linkTrackEvents = "event1";
                window.s.events = "event1";
                window.s.t();
                window._satellite.track('lifebroker-event');
            }, 2000);
        }
    };

    var trackNoResults = exports.trackNoResults = function trackNoResults() {
        if (trackingEnabled()) {
            window.setTimeout(function () {
                window.s.clearVars();
                window.iselect.analytics.life.noresultsreturn = "0";
                window.iselect.analytics.vertical.step = "results-zero";
                window.iselect.analytics.vertical.sessionID = getAndResetSessionIDCookie();
                window.s.linkTrackEvents = "event1";
                window.s.events = "event1";
                window.s.t();
                window.window._satellite.track('lifebroker-event');
            }, 2000);
        }
    };

    var trackNeeds2 = exports.trackNeeds2 = function trackNeeds2(app, productType) {
        if (trackingEnabled()) {
            window.setTimeout(function () {
                window.s.clearVars();
                window.iselect.analytics[productType].gender = app.get('gender');
                window.iselect.analytics[productType].age = app.get('age');
                window.iselect.analytics[productType].state = app.get('state');
                window.iselect.analytics[productType].issmoking = app.get('smoker');
                window.iselect.analytics[productType].occupation = app.get('occupation').value;

                window.iselect.analytics.vertical.step = "needs 2";
                window.iselect.analytics.vertical.sessionID = getAndResetSessionIDCookie();
                window.s.linkTrackEvents = "event1";
                window.s.events = "event1";
                window.s.t();
                window._satellite.track('lifebroker-event');
            }, 2000);
        }
    };

    var trackCaptureLife = exports.trackCaptureLife = function trackCaptureLife(lifeAdded, tpdAdded, traumaAdded) {
        if (trackingEnabled()) {
            window.setTimeout(function () {
                var cover = 'life cover only';
                cover = tpdAdded ? 'life cover and added TPD' : cover;
                cover = traumaAdded ? 'life cover and added Trauma Cover' : cover;
                cover = traumaAdded && tpdAdded ? 'life cover and added TPD and Trauma Cover' : cover;
                window.s.clearVars();
                window.iselect.analytics.life.cover = cover;
                window.iselect.analytics.vertical.name = "life";
                window.iselect.analytics.vertical.step = "capture";
                window.iselect.analytics.vertical.sessionID = getAndResetSessionIDCookie();
                window.s.linkTrackEvents = "event1";
                window.s.events = "event1";
                window.s.t();
                window._satellite.track('lifebroker-event');
            }, 2000);
        }
    };

    var trackCaptureIncome = exports.trackCaptureIncome = function trackCaptureIncome(income, benefit) {
        if (trackingEnabled()) {
            window.setTimeout(function () {
                window.iselect.analytics.vertical.name = "income";
                window.iselect.analytics.income.ip_annual_income_before_tax = income;
                window.iselect.analytics.income.ip_max_monthly_benefit = benefit;
                window.iselect.analytics.vertical.step = "capture";
                window.iselect.analytics.vertical.sessionID = getAndResetSessionIDCookie();
                window.s.linkTrackEvents = "event1";
                window.s.events = "event1";
                window.s.t();
                window._satellite.track('lifebroker-event');
            }, 2000);
        }
    };

    var trackResults = exports.trackResults = function trackResults() {
        if (trackingEnabled() && window.iselect.analytics.vertical.step !== 'results') {
            window.setTimeout(function () {
                window.s.clearVars();
                window.iselect.analytics.vertical.step = "results";
                window.iselect.analytics.vertical.sessionID = getAndResetSessionIDCookie();
                window.s.linkTrackEvents = "event1";
                window.s.events = "event1";
                window.s.t();
                window._satellite.track('lifebroker-event');
            }, 2000);
        }
    };

    var trackProductSelection = exports.trackProductSelection = function trackProductSelection(productID) {
        if (trackingEnabled()) {
            window.s.clearVars();
            window.iselect.analytics.vertical.step = "results";
            window.s.linkTrackEvents = "event1,event55:" + window.iselect.analytics.vertical.sessionID;
            window.s.events = "event1,event55:" + window.iselect.analytics.vertical.sessionID;
            window.iselect.analytics.vertical.sessionID = getAndResetSessionIDCookie();
            var button = document.querySelector('#' + productID);
            window.s.tl(button, "o", "Select");
            window._satellite.track('lifebroker-event');
        }
    };

    var trackSubmit = exports.trackSubmit = function trackSubmit() {
        if (trackingEnabled()) {
            window.s.clearVars();
            window.iselect.analytics.vertical.step = "results";
            window.s.linkTrackEvents = "event1,event56:" + window.iselect.analytics.vertical.sessionID;
            window.s.events = "event1,event56:" + window.iselect.analytics.vertical.sessionID;
            window.iselect.analytics.vertical.sessionID = getAndResetSessionIDCookie();
            var button = document.querySelector('.modal-apply .btn');
            window.s.tl(button, "o", "Submit");
            window._satellite.track('lifebroker-event');
        }
    };
});