define('projecthelm/controllers/income-protection/about-you', ['exports', 'projecthelm/mixins/validation-ip-combined', 'projecthelm/controllers/ip-insurance-quote', 'projecthelm/utils/global-tracking'], function (exports, _validationIpCombined, _ipInsuranceQuote, _globalTracking) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var inject = Ember.inject,
        computed = Ember.computed;
    exports.default = _ipInsuranceQuote.default.extend(_validationIpCombined.default, {
        incomeProtectionController: inject.controller('income-protection'),
        isComplete: Ember.computed.alias('isValid'),
        displayErrors: false,
        ageIsInvalid: false,
        postcodeIsInvalid: false,
        displayPostcodeErrors: computed.or('displayErrors', 'postcodeIsInvalid'),
        incomeResultsInput: Ember.computed.alias('applicationController.incomeResultsInput'),
        queryParams: ['member'],
        member: null,

        quoteChanged: Ember.observer('quote.age', function () {
            if (this.get('quote.age') !== undefined && !this.get('incomeProtectionController.isValid')) {
                this.set('ageIsInvalid', true);
            }
        }),

        reset: function reset() {
            this.setProperties({
                displayNoCoverMessage: false,
                displayErrors: false
            });
        },


        actions: {
            submit: function submit() {
                this.transitionToRoute('income-protection.choose-cover');
            },
            reset: function reset() {
                this.reset();
            },
            setMaxBenefit: function setMaxBenefit() {
                this.set('quote.benefit', this.get('maxBenefit'));
            },
            validateBenefit: function validateBenefit() {
                var quoteBenefit = this.get('quote.benefit'),
                    quoteIncome = this.get('quote.income'),
                    maxBenefit = this.get('maxBenefit');

                this.reset();

                if (Ember.isBlank(quoteIncome)) {
                    this.set('displayCustomError', true);
                    return;
                }

                if (quoteBenefit > maxBenefit) {
                    this.set('quote.benefit', maxBenefit);
                    this.set('displayCustomInfo', true);
                    return;
                }

                if (Ember.isBlank(quoteBenefit)) {
                    this.set('quote.benefit', maxBenefit);
                    return;
                }
            },


            /**
             *
             * @method completeStep
             * @param  {string} redirect [route to enter following quote creation]
             */
            createQuote: function createQuote() {
                this.reset();
                this.send('scrollToTop');
                if (this.get('quote.income') > 320000) {
                    var quote = this.get('quote');
                    _globalTracking.generalData(quote.get("lifeBenefit"), quote.get("tpdBenefit"), quote.get("traumaBenefit"), quote.get("income"), quote.get("benefit"), quote.get("gender"), quote.get("age"), quote.get("postcode"), quote.get("state"), quote.get("smoker"), quote.get("occupation"), 'no_products_found');

                    // The only instance in which a user is directed to continue-quote
                    this.transitionToRoute('income-protection.continue-quote');
                    return false;
                }

                if (this.get('noCoverValue')) {
                    this.setProperties({
                        displayNoCoverMessage: true,
                        displayErrors: false
                    });
                } else if (this.get('isValid')) {
                    this.reset();
                    return true;
                } else {
                    this.setProperties({
                        displayNoCoverMessage: false,
                        displayErrors: true
                    });
                }
            },


            /**
             * toggleCoverType
             *
             * @param  {string} coverType [property to be toggled]
             */
            toggleCoverType: function toggleCoverType(quote, coverType) {
                quote.toggleProperty(coverType);
            }
        }
    });
});