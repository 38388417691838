define('projecthelm/components/x-footer', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var inject = Ember.inject;
    exports.default = Ember.Component.extend({
        theme: inject.service(),
        modal: inject.service()
    });
});