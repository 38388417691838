define('projecthelm/initializers/modal', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.initialize = initialize;
    function initialize(application) {
        application.inject('service:modal', 'applicationController', 'controller:application');
    }

    exports.default = {
        name: 'modal',
        initialize: initialize
    };
});