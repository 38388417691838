define('projecthelm/routes/quote/compare/layout', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        setupController: function setupController(controller, model) {

            var layout = this.routeName.substring(this.routeName.lastIndexOf('.') + 1);

            this._super(controller, model);

            controller.setProperties({
                layout: layout,
                shortlistUrl: this.routeName + '.shortlist',
                compareUrl: this.routeName + '.index'
            });
        }
    });
});