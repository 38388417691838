define('projecthelm/routes/application', ['exports', 'projecthelm/config/environment', 'projecthelm/utils/iselect-tracking', 'projecthelm/utils/adobe-tracking', 'projecthelm/utils/global-tracking'], function (exports, _environment, _iselectTracking, _adobeTracking, _globalTracking) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var modalActiveClass = 'is-mfp-open';

    exports.default = Ember.Route.extend({
        modalOpen: false,
        currentUser: Ember.inject.service(),

        beforeModel: function beforeModel(transition) {
            this._super.apply(this, arguments);
            this.trackUser(transition);
        },
        afterModel: function afterModel() {
            Ember.$('body').removeClass('is-loading');
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
        },
        trackUser: function trackUser(transition) {

            if (!sessionStorage.getItem('userTracker')) {

                var userTracker = {
                    getParameters: JSON.stringify(transition.queryParams),
                    siteEntry: window.location.pathname,
                    sessionStartTime: new Date()
                };

                sessionStorage.setItem('userTracker', JSON.stringify(userTracker));
            }
            // mcode is used on the /comparison main site landing page where the callback form is included as an ember embed...
            var mediaCode = transition.queryParams.mediaCode || transition.queryParams.mcode;
            if (mediaCode) {
                sessionStorage.setItem('mediaCode', mediaCode);
            }
        },
        _closeModal: function _closeModal() {

            Ember.$.magnificPopup.instance.close();
            this.set('modalOpen', false);

            Ember.$('html').removeClass('' + modalActiveClass);

            Ember.$('html').removeClass(function (index, css) {
                return (css.match(/\bmfp-\S+/g) || []).join(' ');
            });
        },
        _cleanupModal: function _cleanupModal() {
            return this.render('modals/empty', {
                into: 'application',
                outlet: 'modal'
            });
        },


        actions: {

            /**
             * Open Modal
             * @param {string} [modalName] Modal controller name
             * @param {model} [model] Model to be passed modal controller
             */
            openModal: function openModal(modalName, model, options) {
                var _this = this;

                if (modalName === 'apply') {
                    _adobeTracking.addProduct(model.get('company') + ' - ' + model.get('name'));
                    _globalTracking.selectProduct(model);
                }

                var modalPath = 'modals/' + modalName;
                var controller = this.controllerFor(modalPath);
                options = Ember.Object.create(options ? options : {});

                controller.send('reset');

                controller.setProperties({
                    model: model,
                    options: options
                });

                dataLayer.push({
                    event: 'openModal',
                    name: modalName.camelize()
                });

                if (modalName === 'apply') {
                    (0, _iselectTracking.trackProductSelection)(model.id);
                }

                Ember.$('html').addClass(modalActiveClass + ' mfp-' + modalName);

                if (this.get('modalOpen') === true) {

                    this._closeModal();

                    // Wait for magnific cleanup prior to rendering new modal
                    return Ember.run.later(this, function () {

                        _this._cleanupModal();

                        _this.render(modalPath, {
                            into: 'application',
                            outlet: 'modal'
                        });
                    }, 300);
                }

                this.set('modalOpen', true);

                return this.render(modalPath, {
                    into: 'application',
                    outlet: 'modal'
                });
            },
            redirectToTal: function redirectToTal(product) {
                _globalTracking.selectProduct(product);
                // const lead =  EmberObject.create({
                //     product: product,
                //     additional: {
                //         progress: 'Select',
                //         callTime: 'Customer has requested a call back at anytime during business hours.',
                //     },
                // });
                // this.get('currentUser').submitLead(lead);
                this.send('openModal', 'redirecting', product);
            },


            /**
             * Close Modal
             */
            closeModal: function closeModal() {
                this._closeModal();
            },
            cleanupModal: function cleanupModal() {
                this._cleanupModal();
            },


            /**
             * Scroll to the top of the window
             *
             * @method scrollToTop
             */
            scrollToTop: function scrollToTop() {
                Ember.$('html, body').scrollTop(0);
            },


            /**
             * Close modal and offCanvas on route transition
             *
             * @method willTransition
             * @param {string} [transition] Transition object
             * @return {boolean} willTranstion action
             */
            willTransition: function willTransition() {
                this.send('closeModal');
                return true;
            },
            triggerSecondaryQuoteFlash: function triggerSecondaryQuoteFlash() {},
            error: function error(_error) {

                this.controller.setProperties({
                    quoteSaving: false,
                    quoteProcessing: false
                });
                /* eslint-disable no-console */
                console.log(_error);
                if (_error.status === 404) {
                    this.replaceWith('/error404');
                } else if (_error.status === 500 && _environment.default.environment !== 'development') {
                    this.replaceWith('error500');
                } else if (_environment.default.environment === 'development') {
                    return true;
                } else {
                    this.replaceWith('error500');
                }
            }
        }
    });
});