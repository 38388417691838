define('projecthelm/controllers/tal-calc-calculator', ['exports', 'tal-calculator/controllers/tal-calc-calculator'], function (exports, _talCalcCalculator) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _talCalcCalculator.default.extend({
        theme: Ember.inject.service(),
        queryParams: ['referer', 'affiliateId', 'standalone'],
        referer: null,
        affiliateId: null,
        standalone: false
    });
});