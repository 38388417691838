define('projecthelm/components/validated-input', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNameBindings: ['invalid:form-input--error', 'valid:form-input--valid'],
        hasErrors: Ember.computed.notEmpty('errors').readOnly(),
        noErrors: Ember.computed.empty('errors').readOnly(),
        invalid: Ember.computed.and('hasErrors', 'displayErrors').readOnly(),
        valid: Ember.computed.and('noErrors', 'displayErrors').readOnly()
    });
});