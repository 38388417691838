define('projecthelm/services/modal', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        open: function open() {
            var _arguments = Array.prototype.slice.call(arguments),
                modalName = _arguments[0],
                model = _arguments[1],
                params = _arguments[2];

            var applicationController = this.get('applicationController');
            applicationController.send('openModal', modalName, model, params);
        },
        cleanup: function cleanup() {
            this.get('applicationController').send('cleanupModal');
        }
    });
});