define('projecthelm/controllers/modals/save-before-new-quote', ['exports', 'projecthelm/mixins/modal'], function (exports, _modal) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_modal.default, {
        actions: {
            saveQuote: function saveQuote() {
                var quote = this.get('model');
                if (quote.get('leadCreated')) {
                    this.send('quoteDidChange');
                    this.send('createThirdPartyQuote');
                } else {
                    var options = {
                        reset: true
                    };
                    this.send('openModal', 'saveQuote', quote, options);
                }
            }
        }
    });
});