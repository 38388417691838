define('projecthelm/components/product-favourite', ['exports', 'projecthelm/utils/adobe-tracking'], function (exports, _adobeTracking) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        product: undefined,
        favouriteProducts: [],

        isFavourite: Ember.computed('favouriteProducts.length', function () {
            return this.get('favouriteProducts').includes(this.get('product'));
        }).readOnly(),

        isStickyNav: false,
        isNotStickyNav: Ember.computed.not('isStickyNav').readOnly(),

        /**
         * Tooltip message dependent which depends on whether the product
         *  has been favourited
         *  @method favouriteTipMessage
         *  @returns {string} Message to be used in tooltip
         */
        favouriteTipMessage: Ember.computed('isFavourite', function () {
            return this.get('isFavourite') ? 'Click to remove this insurer from your shortlist' : 'Click to save this insurer to your shortlist';
        }).readOnly(),

        actions: {
            toggleFavourite: function toggleFavourite() {
                var product = this.get('product');
                _adobeTracking.favourite(product.get('company'), product.get('name'), this.get('isFavourite'));
                this.sendAction('action', this.get('product'), this.get('favouriteProducts'));
            }
        }
    });
});