define('projecthelm/controllers/modals/redirecting', ['exports', 'projecthelm/utils/global-tracking', 'projecthelm/mixins/modal'], function (exports, _globalTracking, _modal) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_modal.default, {
        trackModalClose: true,
        actions: {
            close: function close() {
                if (this.get('trackModalClose')) {
                    _globalTracking.closeRedirectModal(this.get('model'));
                }
                this.set('trackModalClose', true);
                this.send('closeModal');
            },
            reset: function reset() {},
            confirmRedirect: function confirmRedirect() {
                var mediaCodes = {
                    lifebroker: 'TALLBOA01',
                    'compare-the-market': 'TALCTMOA01',
                    iselect: 'TALISUOA01'
                };
                var code = mediaCodes[this.get('theme.id')];
                _globalTracking.confirmRedirectModal(this.get('model'));
                window.open('https://coverbuilder.tal.com.au/#/partner?sb=TALD&mcode=' + code, '_blank').focus();
                this.set('trackModalClose', false);
                this.send('closeModal');
            }
        }
    });
});