define('projecthelm/components/off-canvas-body', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['off-canvas__body'],

        click: function click() {
            this.sendAction();
        }
    });
});