define('projecthelm/utils/forms', ['exports', 'projecthelm/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.lifeInsuranceValidations = exports.incomeProtectionValidations = exports.calculateMaxBenefit = exports.validation = exports.tooltips = exports.stateOptions = undefined;
    var phone = _environment.default.theme.phone;
    var stateOptions = exports.stateOptions = [{ value: 'act', label: 'Australian Capital Territory' }, { value: 'nsw', label: 'New South Wales' }, { value: 'nt', label: 'Northern Territory' }, { value: 'qld', label: 'Queensland' }, { value: 'sa', label: 'South Australia' }, { value: 'tas', label: 'Tasmania' }, { value: 'vic', label: 'Victoria' }, { value: 'wa', label: 'Western Australia' }];

    var tooltips = exports.tooltips = {
        lifeCover: "Life cover pays an agreed lump sum in the event of your death or if you're diagnosed with a terminal illness",
        tpdCover: "TPD cover pays an agreed lump sum in the event you’re totally and permanently disabled and are unlikely to work again",
        traumaCover: "Trauma cover pays an agreed lump sum if you suffer from an insured trauma event or medical condition",
        incomeProtection: "Income protection provides a monthly benefit of up to 70% of your income if you become ill or injured and unable to work for an extended period time",
        indemnityPolicy: "With an indemnity value policy, you are insured for what you say you earn, but you are required to verify your income when you make a claim. If your income has reduced since you applied for cover, your claim will be paid on the reduced amount",
        waitPeriod: "The period of time that you have to be ill or injured before you can qualify to receive benefit payments",
        benefitPeriod: "The period of time that you could receive a benefit should you make a claim",
        annualIncome: "Please enter your gross annual income before tax is deducted"
    };

    var validation = exports.validation = {

        name: {
            presence: { message: "Please enter your name" },
            format: {
                // No numbers, or 'http' 
                // Standard character name with max 4 spaces 
                with: /^(?!.*http)(?!.*\d)([a-zA-Z]*[\s]?){0,4}[^\s]*$/,
                allowBlank: true,
                message: 'Please enter a valid name'
            }
        },

        phone: {
            presence: { message: "Please enter your phone number" },
            format: {
                with: /^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/,
                allowBlank: true,
                message: 'Please enter a valid Australian telephone number'
            }
        },

        email: {
            presence: { message: "Please enter your email address" },
            format: {
                with: /[^@]+@[^@]+\.[a-zA-Z]{2,}/,
                allowBlank: true,
                message: 'Please enter a valid email address'
            }
        },

        state: {
            presence: { message: "Please select your state" }
        },

        callDay: {
            conditionalPresence: {
                'if': 'requestCall',
                message: "Please select a day for a call"
            }
        },

        callWindow: {
            conditionalPresence: {
                'if': 'requestCall',
                message: "Please select a day for a call"
            }
        }
    };

    var calculateMaxBenefit = exports.calculateMaxBenefit = function calculateMaxBenefit(income) {
        var MAXIMUM_PERCENTAGE_OF_INCOME = 0.7;

        var maximumBenefit = income * MAXIMUM_PERCENTAGE_OF_INCOME,
            monthlyBenefit = maximumBenefit / 12,
            roundedBenefit = Math.floor(monthlyBenefit);

        return roundedBenefit > 0 ? roundedBenefit : undefined;
    };

    var ipValidations = {
        'quote.income': {
            presence: { message: "You need to enter your yearly income before tax" },
            numericality: {
                allowBlank: true,
                onlyInteger: true,
                greaterThanOrEqualTo: 20000,
                messages: {
                    lessThanOrEqualTo: 'Enter an income under $320,000 or call <a href="tel:' + phone + '">' + phone + '</a> to compare',
                    greaterThanOrEqualTo: 'Enter an income over $20,000 or call <a href="tel:' + phone + '">' + phone + '</a> to compare'
                }
            }
        },
        'quote.benefit': {
            presence: { message: "Please enter your benefit" },
            numericality: {
                allowBlank: true,
                onlyInteger: true
            }
        }
    };
    var incomeProtectionValidations = exports.incomeProtectionValidations = ipValidations;

    var lifeValidations = {
        'quote.lifeBenefit': {
            conditionalNumericality: {
                'if': 'quote.lifeCover',
                allowBlank: true,
                onlyInteger: true,
                greaterThanOrEqualTo: 1500,
                messages: {
                    lessThanOrEqualTo: 'Please enter an amount under $5,000,000 to start',
                    greaterThanOrEqualTo: 'Please enter an amount greater than $1,500'
                }
            }
        },
        'quote.tpdBenefit': {
            conditionalNumericality: {
                'if': 'quote.tpdCover',
                allowBlank: true,
                onlyInteger: true,
                greaterThan: 0,
                lessThanOrEqualTo: 5000000,
                messages: {
                    greaterThan: 'Please enter the amount of cover you would like',
                    lessThanOrEqualTo: 'Please enter an amount under $5,000,000 to start'
                }
            }
        },
        'quote.traumaBenefit': {
            conditionalNumericality: {
                'if': 'quote.traumaCover',
                allowBlank: true,
                onlyInteger: true,
                greaterThan: 0,
                lessThanOrEqualTo: 2000000,
                messages: {
                    greaterThan: 'Please enter the amount of cover you would like',
                    lessThanOrEqualTo: 'Please enter an amount under $2,000,000 to start'
                }
            }
        }
    };

    var lifeInsuranceValidations = exports.lifeInsuranceValidations = lifeValidations;
});