define('projecthelm/routes/customise', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed,
        inject = Ember.inject;


    function objectValuesToArray(object) {
        return Object.values(object).reduce(function (sum, value) {
            if (value.length) {
                return sum.concat(value);
            }
            return sum;
        }, []);
    }

    exports.default = Ember.Route.extend({
        customiser: inject.service(),
        currentUser: inject.service(),
        activeQuote: computed.alias('currentUser.activeQuote'),

        // Enable for debugging purposes
        // beforeModel() {
        //     return this.store.query('lifeQuote', {
        //         affiliateId: 'PumpkinCoyote4431',
        //         quoteType: 'life',
        //     }).then( (response) => {
        //         return this.get('currentUser').set('activeQuote', response.get('firstObject'))
        //     });
        // },

        model: function model(_ref) {
            var product_id = _ref.product_id;

            return this.store.peekRecord('product', product_id);
        },
        afterModel: function afterModel(model) {
            if (!model) {
                window.location = '/comparison';
            }

            this.get('customiser').set('product', model);
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);

            controller.setProperties({
                product: model,
                customiser: this.get('customiser')
            });

            this.get('customiser').set('product', model);
        },


        actions: {
            enableNav: function enableNav() {
                this.controller.set('isNavEnabled', true);
            },
            disableNav: function disableNav() {
                this.controller.set('isNavEnabled', false);
            },
            toggleNav: function toggleNav() {
                this.controller.toggleProperty('isNavOpen');
            },
            stepNavClick: function stepNavClick() {
                if (this.get('media.isPalm')) {
                    this.controller.set('isNavOpen', false);
                }
            },
            updateCurrentStep: function updateCurrentStep(step) {
                this.get('customiser').set('currentStep', step);
            },
            goToNextStep: function goToNextStep() {
                this.transitionTo(this.get('customiser.nextStep.route'));
            },
            completeStep: function completeStep(step) {
                step.setProperties({
                    errors: [],
                    isComplete: true,
                    hasBeenSkipped: false
                });
            },
            skipStep: function skipStep(step, errors) {

                step.setProperties({
                    hasBeenSkipped: true,
                    isComplete: false
                });

                if (errors) {
                    step.set('errors', objectValuesToArray(errors));
                }
            },
            skipSection: function skipSection(section) {
                section.set('hasBeenSkipped', true);
            },
            updateQuote: function updateQuote() {
                var _this = this;

                this.get('customiser').set('isLoading', true);

                this.get('activeQuote').save().then(function () {
                    _this.get('customiser').set('isLoading', false);
                }).catch(function () {
                    // console.error('Error saving activeQuote');
                    _this.get('customiser').set('isLoading', false);
                });
            },
            startApplication: function startApplication() {
                var _this2 = this;

                dataLayer.push({
                    event: 'applicationProgress',
                    eventAction: 'Step 7: Continue to Omnium'
                });

                var quote = this.get('currentUser.activeQuote');

                this.replaceWith('loading');

                quote.apply({
                    premiumFrequency: quote.get('frequency'),
                    productId: this.get('customiser').get('product.id'),
                    leadReference: this.get('currentUser').get('lead.id'),
                    notes: this.get('customiser').get('assessment'),
                    quoteType: quote.get('quoteType')
                }).then(function (_ref2) {
                    var form = _ref2.form;


                    if (!form.url) {
                        _this2.replaceWith('error500');

                        return Ember.get(_this2, 'flashMessages').add({
                            message: 'There has been an issue starting your application. Please call 13 54 33 and one of consultants will assist you.',
                            type: 'danger'
                        });
                    }

                    return window.location = form.url;
                });
            }
        }

    });
});