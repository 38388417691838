define('projecthelm/services/tal-calc-config', ['exports', 'tal-calculator/services/tal-calc-config'], function (exports, _talCalcConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _talCalcConfig.default;
    }
  });
});