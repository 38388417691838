define('projecthelm/routes/customise/summary', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        customiser: Ember.inject.service(),

        setupController: function setupController() {
            this._super.apply(this, arguments);
            this.controller.send('disableNav');
        },
        deactivate: function deactivate() {
            this.controller.send('enableNav');
        }
    });
});