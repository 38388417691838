define("projecthelm/components/occupation-select", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var whiteCollarOccupationReference = "3";
    var blueCollarOccupationReference = "5";

    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),

        init: function init() {
            this._super.apply(this, arguments);
            var occupationsAvailable = 'occupations' in window;

            if (!occupationsAvailable) {
                this.get('store').findAll('occupation');
            }
        },


        actions: {
            skipShortSearches: function skipShortSearches(term, select) {
                if (term.length < 2) {
                    select.actions.search('');
                    return false;
                }
            },
            search: function search(term, select) {
                var occupations = window.occupations;

                if (occupations) {
                    var results = occupations.filter(function (occupation) {
                        return occupation.value.toUpperCase().indexOf(term.toUpperCase()) > -1;
                    });
                    results.splice(10);
                    return results;
                }

                select.actions.search('');
                return false;
            },


            // Setup context for default occupations
            focus: function focus(select) {
                this.set('select', select);
            },


            // Remove selection if searchText is empty
            blur: function blur(select) {
                if (select.searchText === "") {
                    select.actions.choose(null);
                }
            },
            submitChange: function submitChange(value) {
                this.sendAction('onchange', value);
            },
            defaultToWhiteCollar: function defaultToWhiteCollar() {
                var whiteCollarOccupation = window.occupations.findBy('reference', whiteCollarOccupationReference);
                this.get('select').actions.choose(whiteCollarOccupation);
            },
            defaultToBlueCollar: function defaultToBlueCollar() {
                var blueCollarOccupation = window.occupations.findBy('reference', blueCollarOccupationReference);
                this.get('select').actions.choose(blueCollarOccupation);
            }
        }
    });
});