define('projecthelm/routes/customise/important-information/duty-of-disclosure', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model() {
            var section = this.modelFor('customise.important-information');
            return section.get('steps').findBy('id', 2);
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('step', model);
            this.controller.send('updateCurrentStep', model);
        },
        deactivate: function deactivate() {
            this._super.apply(this, arguments);
            if (this.controller.get('isValid')) {
                this.send('completeStep', this.controller.get('step'));
            } else {
                this.controller.set('displayErrors', true);
                this.send('skipStep', this.controller.get('step'), this.controller.get('errors'));
            }
        }
    });
});