define('projecthelm/controllers/life-insurance/no-products', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var inject = Ember.inject;
    exports.default = Ember.Controller.extend({
        theme: inject.service()
    });
});