define('projecthelm/services/theme', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        currentUser: Ember.inject.service(),

        init: function init() {
            this._super.apply(this, arguments);

            var config = Ember.getOwner(this).resolveRegistration('config:environment');
            var themeData = config.theme;
            themeData.apppath = config.APP.apppath;

            this.setProperties(themeData);
        },


        phoneNumber: Ember.computed('currentUser.activeQuote.affiliateId', function () {
            return this.get('currentUser.activeQuote.affiliateId') ? this.get('phoneQuote') : this.get('phone');
        })

    });
});