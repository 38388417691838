define('projecthelm/transitions', ['exports', 'tal-calculator/transitions'], function (exports, _transitions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    exports.default = function () {

        _transitions.default.apply(this, arguments);

        this.transition(this.toRoute(function (routeName) {
            return (/loading/.test(routeName)
            );
        }), this.use('fade', { duration: 200 }));

        this.transition(this.toRoute(function (routeName) {
            return (/life-insurance/.test(routeName) || /income-protection/.test(routeName)
            );
        }), this.fromRoute(function (routeName) {
            return 'index' === routeName;
        }), this.use('fade', { duration: 100 }), this.reverse('fade', { duration: 100 }));

        this.transition(this.toRoute(function (routeName) {
            return (/choose-cover/.test(routeName)
            );
        }), this.fromRoute(function (routeName) {
            return (/about-you/.test(routeName)
            );
        }), this.use('toLeft'), this.reverse('toRight'));

        this.transition(this.hasClass('fade'), this.toValue(true), this.use('fade', { duration: 300 }), this.reverse('fade', { duration: 300 }));

        this.transition(this.hasClass('crossfade'), this.use('crossFade', { duration: 150 }));
    };
});