define('projecthelm/controllers/quote', ['exports', 'ember-validations', 'projecthelm/utils/forms'], function (exports, _emberValidations, _forms) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed,
        inject = Ember.inject;
    var stateOptions = _forms.default.stateOptions;
    exports.default = Ember.Controller.extend(_emberValidations.default, {
        quote: computed.alias('model'),
        applicationController: inject.controller('application'),
        hasShortlist: computed.notEmpty('quote.favouriteProducts').readOnly(),
        stateOptions: stateOptions
    });
});