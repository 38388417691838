define('projecthelm/components/input-currency', ['exports', 'projecthelm/components/input-mask'], function (exports, _inputMask) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _inputMask.default.extend({
        classNames: ['input-text'],
        type: 'tel',
        options: {
            prefix: "$ ",
            groupSeparator: ",",
            alias: "numeric",
            autoGroup: true,
            digits: 0,
            rightAlign: false,
            placeholder: '',
            showMaskOnHover: false,
            allowMinus: false,
            allowPlus: false
        },

        /**
        * Update computed property that is dependent on value
        **/
        focusOut: function focusOut() {
            var value = this.$().inputmask('unmaskedvalue');

            if (value === "0" || value === "") {
                this.set('nullValueEntered', false);
            }
        }
    });
});