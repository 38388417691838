define('projecthelm/controllers/customise/important-information/standard-exclusions', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var inject = Ember.inject;
    exports.default = Ember.Controller.extend({
        customiser: inject.service(),

        actions: {
            nextStep: function nextStep() {
                dataLayer.push({
                    event: 'applicationProgress',
                    eventAction: 'Step 4: Policy Information'
                });
                this.transitionToRoute('customise.pre-assessment');
            }
        }
    });
});