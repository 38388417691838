define('projecthelm/routes/income-protection/about-you', ['exports', 'projecthelm/mixins/check-validation-before-transition', 'projecthelm/utils/iselect-tracking'], function (exports, _checkValidationBeforeTransition, _iselectTracking) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var inject = Ember.inject;
    exports.default = Ember.Route.extend(_checkValidationBeforeTransition.default, {
        theme: inject.service(),
        currentUser: inject.service(),
        cleanUri: location.protocol + "//" + location.host + location.pathname,
        init: function init() {
            // Clean up uri
            // window.history.replaceState({}, document.title, this.cleanUri);
            return this._super();
        },

        afterModel: function afterModel() {
            if (this.get('theme.id') === 'lifebroker') {
                document.querySelector('meta[name="description"]').setAttribute("content", 'Compare income protection quotes from leading insurance providers in a few simple steps.');
            }
        },
        model: function model() {
            return this.get('currentUser.ipQuote');
        },


        setupController: function setupController(controller, model) {
            this._super(controller, model);
            (0, _iselectTracking.trackNeeds1)('income');
        }

    });
});