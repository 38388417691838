define('projecthelm/serializers/feature', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.RESTSerializer.extend({
        isNewSerializerAPI: true,

        keyForRelationship: function keyForRelationship(key) {
            return key + 'Id';
        }
    });
});