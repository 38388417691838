define('projecthelm/controllers/modals/broker-contact', ['exports', 'projecthelm/mixins/modal', 'projecthelm/controllers/formie-form', 'projecthelm/utils/forms'], function (exports, _modal, _formieForm, _forms) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var inject = Ember.inject;
    exports.default = _formieForm.default.extend(_modal.default, {
        currentUser: inject.service(),
        formieFormHandle: 'brokerContact',
        stateOptions: _forms.stateOptions,

        validations: {
            'customerName': _forms.validation.name,
            'phone': _forms.validation.phone,
            'email': _forms.validation.email,
            'state': _forms.validation.state,
            'question': {
                presence: { message: 'Please enter your message' }
            }
        },

        init: function init() {

            var currentUser = this.get('currentUser');

            this.setProperties({
                customerName: currentUser.get('fullName'),
                phone: currentUser.get('phone'),
                email: currentUser.get('email')
            });

            this._super.apply(this, arguments);
        },


        actions: {
            reset: function reset() {
                this._reset();
                this._super();
            },
            formSubmission: function formSubmission() {
                this._super.apply(this, arguments);
                this.send('closeModal');

                dataLayer.push({
                    event: 'submitModal',
                    name: 'brokerContact'
                });

                Ember.get(this, 'flashMessages').add({
                    message: 'Thank you, we\'ll be in touch soon!',
                    type: 'success'
                });
            }
        }
    });
});