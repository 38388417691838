define('projecthelm/components/broker/referral-form', ['exports', 'ember-validations', 'moment', 'projecthelm/config/environment', 'projecthelm/data/public-holidays', 'projecthelm/utils/forms'], function (exports, _emberValidations, _moment, _environment, _publicHolidays, _forms) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var computed = Ember.computed,
        inject = Ember.inject;
    var alias = computed.alias;
    exports.default = Ember.Component.extend(_emberValidations.default, {
        currentUser: inject.service(),
        theme: inject.service(),
        lead: alias('currentUser.lead'),
        stateOptions: _forms.stateOptions,
        center: (0, _moment.default)(),
        minDate: (0, _moment.default)(),
        maxDate: (0, _moment.default)().add(2, 'weeks'),
        disabledDates: ['Sat', 'Sun'].concat(_toConsumableArray(_publicHolidays.default)),
        showCustomBrokerName: false,

        validations: {
            'lead.name': {
                presence: {
                    message: 'Please enter your client’s name'
                }
            },
            'lead.phone': {
                presence: {
                    message: 'Please enter your client’s contact number'
                },
                format: _forms.validation.phone.format
            },
            'lead.email': {
                presence: {
                    message: 'Please enter your client’s contact number'
                },
                format: _forms.validation.email.format
            },
            'lead.state': {
                presence: {
                    message: 'Please select a state'
                }
            },
            'acceptedTerms': {
                acceptance: {
                    message: 'Please accept the terms and conditions to proceed'
                }
            },
            'lead.customBrokerName': {
                presence: {
                    message: 'Please enter a broker name',
                    'if': function _if() {
                        return window.customBrokerName;
                    }
                }
            }

        },

        init: function init() {
            this._super.apply(this, arguments);
            // Check for variable set in main LB site where form is rendered. Comes from a light switch on the user. 
            this.set('showCustomBrokerName', window.customBrokerName);
            if (this.get('content')) {
                this.content = this.get('content').htmlSafe();
            }

            this.reset();
        },
        reset: function reset() {
            this.setProperties({
                leadSubmitted: false,
                isProcessing: false,
                displayErrors: false,
                displayPhoneErrors: false,
                comments: null,
                consentDate: (0, _moment.default)().format('YYYY-MM-DD'),
                callDay: null,
                callWindow: null,
                acceptedTerms: null,
                callWindowOptions: [{ value: '9', label: '9.00am – 11.00am AEST' }, { value: '11', label: '11.00am – 1.00pm AEST' }, { value: '13', label: '1:00pm – 3.00pm AEST' }, { value: '15', label: '3.00pm – 5.00pm AEST' }],
                brokerNames: window.brokerNames
            });

            this.get('lead').setProperties({
                name: null,
                phone: null,
                email: null,
                state: null,
                customBrokerName: null
            });
        },


        callTime: computed('callDay', 'callWindow', function () {

            var callDay = this.get('callDay');
            var callWindow = this.get('callWindow');

            if (callDay && callWindow) {
                return callDay.hour(callWindow).format(_environment.default.moment.outputFormat);
            }

            return undefined;
        }).readOnly(),

        actions: {
            reset: function reset() {
                this.reset();
            },
            createLead: function createLead() {

                if (!this.get('isValid')) {
                    this.set('displayErrors', true);

                    if (this.get('hasValidPhone')) {
                        this.set('displayPhoneErrors', true);
                    }

                    return;
                }

                this.setProperties({
                    isProcessing: true,
                    leadSubmitted: true
                });

                dataLayer.push({
                    event: 'brokerAction',
                    accountAction: 'Make a referral'
                });

                var properties = new Ember.Object();
                var options = new Ember.Object();

                properties.additional = this.getProperties(['comments', 'callTime', 'consentDate']);
                properties.additional.timestamp = Date.now();

                options.set('sendEmail', true);

                this.get('currentUser').submitLead(properties, options);
            }
        }
    });
});