define('projecthelm/mixins/ip-cover-options', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        indemnityOptions: [{ value: 'I', label: 'Indemnity', prefix: 'Indemnity' }],

        benefitPeriodOptions: [{ value: 1, label: '1 year', prefix: 'Benefit period' }, { value: 2, label: '2 years', prefix: 'Benefit period' }, { value: 5, label: '5 years', prefix: 'Benefit period' }, { value: 65, label: 'To age 65', prefix: 'Benefit period' }, { value: 70, label: 'To age 70', prefix: 'Benefit period' }],

        waitPeriodOptions: [{ value: 14, label: '14 days', prefix: 'Wait period' }, { value: 30, label: '30 days', prefix: 'Wait period' }, { value: 60, label: '60 days', prefix: 'Wait period' }, { value: 90, label: '90 days', prefix: 'Wait period' }, { value: 180, label: '180 days', prefix: 'Wait period' }, { value: 1, label: '1 year', prefix: 'Wait period' }, { value: 2, label: '2 years', prefix: 'Wait period' }]
    });
});