define('projecthelm/serializers/lead', ['exports', 'ember-data', 'projecthelm/serializers/application', 'projecthelm/utils/media-codes'], function (exports, _emberData, _application, _mediaCodes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var inject = Ember.inject,
        computed = Ember.computed;


    var LIFE_THROUGH_SUPER_QUOTES = ['lifeThroughSuper', 'coverInSuper', 'incomeProtectionSuper'];

    exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
        currentUser: inject.service(),
        quote: computed.alias('currentUser.activeQuote'),
        theme: inject.service(),
        primaryKey: 'reference',

        attrs: {
            quote: {
                embedded: 'always'
            },
            product: {
                embedded: 'always'
            }
        },

        serialize: function serialize() {
            var json = this._super.apply(this, arguments);
            var currentUser = this.get('currentUser');
            var trackingData = currentUser.get('trackingData');

            json.additional = Ember.merge(json.additional, trackingData);
            json.additional.lifeThroughSuper = 0;
            if (currentUser.get('groups') && currentUser.get('groups').includes('brokers')) {
                json.additional.fcode = currentUser.get('fcode');
                json.additional.brokerName = currentUser.get('fullName');
                json.customBrokerName = undefined;
                json.additional.customBrokerName = currentUser.get('lead.customBrokerName') || undefined;
                json.additional.brokerEmail = currentUser.get('email');
                json.additional.quoteType = 'Contact My Client';
                json.additional.quoteAction = 'Submit Lead';
            }

            if (json.quote) {
                json.affiliateId = json.quote.affiliateId;
                json.quoteType = json.quoteType ? json.quoteType : json.quote.quoteType;
                json.additional.comments = json.quote.additional ? json.quote.additional.comments : '';
                json.additional.cid = sessionStorage.getItem('cid');

                // Default to quote state
                if (!json.state) {
                    json.state = json.quote.state;
                }

                if (json.quote.memberNumber) {
                    json.additional.memberNumber = json.quote.memberNumber;
                    json.additional.fcode = 'MA';
                    json.additional.brokerName = 'Member Advantage';
                }
            }

            if (json.product) {
                json.productId = json.product.id;
                json.additional.selectedCompany = json.product.company;
                json.additional.calculatedPremium = json.product.value;
                json.additional.comments = 'Customer is interested in applying for ' + json.additional.selectedCompany;
            }

            // Is it a life through super lead?
            if (LIFE_THROUGH_SUPER_QUOTES.indexOf(json.quoteType) !== -1) {
                json.additional.lifeThroughSuper = 1;
            }

            if (this.get('theme.id') === 'compare-the-market') {
                if ('ga' in window) {
                    window.ga(function (tracker) {
                        json.additional.gaClientId = tracker.get('clientId');
                    });
                }

                json.additional.ipAddress = this.get('currentUser.ip');
            }

            if (json.additional.progress === 'Save') {
                json.additional.comments = 'Customer has saved quote ' + window.location.href;
            }

            if (sessionStorage.getItem('mediaCode') === 'SFTEDM01') {
                json.additional.fcode = 'SFT-001';
                json.additional.brokerName = 'Steadfast Life';
            }

            json.additional.mediaCode = (0, _mediaCodes.default)({
                quoteType: this.get('quote.quoteType'),
                themeId: this.get('theme.id'),
                progress: json.additional.progress
            });

            delete json.quote;
            delete json.product;

            return json;
        },
        normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {

            var data = {
                lead: payload
            };

            return this._super(store, primaryModelClass, data, id, requestType);
        }
    });
});