define('projecthelm/utils/media-codes', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    exports.default = function (_ref) {
        var quoteType = _ref.quoteType,
            themeId = _ref.themeId,
            progress = _ref.progress;

        // Client does not understand media codes at all... 
        // Ongoing conversation so we can use single media code combined with 'progress' value rather than unique codes for each funnel stage...
        var mediaCode = sessionStorage.getItem('mediaCode');

        if (mediaCode === 'SFTEDM01') {
            var _codes = {
                'Start': 'SFTEDM01',
                'Save': 'SFTEDM01',
                'Select': 'SFTFSM002',
                'Apply': 'SFTFSM001',
                'no-products': 'SFTNPF001',
                'Request-Call': 'SFTCB001'
            };
            mediaCode = _codes[progress];
        }

        if (mediaCode === 'SFLI001' && quoteType === 'life') {
            var _codes2 = {
                'Start': 'SFLI001',
                'Save': '',
                'Select': '',
                'Apply': 'SFLIFSM002',
                'no-products': 'SFLINPF001',
                'Request-Call': 'SFLICB001'
            };
            mediaCode = _codes2[progress];
        }

        if (mediaCode === 'SFLI001' && quoteType === 'ip') {
            var _codes3 = {
                'Start': 'SFLI002',
                'Save': '',
                'Select': '',
                'Apply': 'SFLIFSM002',
                'no-products': 'SFLINPF001',
                'Request-Call': 'SFLICB001'
            };
            mediaCode = _codes3[progress];
        }

        if (themeId === 'lifebroker' && mediaCode) return mediaCode;
        var codes = {
            'compare-the-market': {
                'Save': 'CTMEDM1',
                'Start': 'CTMEDM1',
                'Select': 'CTMFSM002',
                'Apply': 'CTMFSM001',
                'no-products': 'CTMNPF001',
                'Request-Call': 'CTMCB001'
            },
            'lifebroker': {
                'Save': '',
                'Start': '',
                'Select': 'LBFSM002',
                'Apply': 'LBFSM001',
                'no-products': 'LBNPF001',
                'Request-Call': 'LBCB001'
            },
            'iselect': {
                'Save': '',
                'Start': '',
                'Select': 'ISELECTFSM002',
                'Apply': 'ISELECTFSM001',
                'no-products': 'ISELECTNPF001',
                'Request-Call': 'ISELECTCB001'
            }
        };
        mediaCode = codes[themeId][progress] || mediaCode;

        return mediaCode;
    };
});