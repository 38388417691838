define('projecthelm/routes/customise/policy-options/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        redirect: function redirect() {
            return this.transitionTo('customise.policy-options.type-of-premium');
        }
    });
});