define('projecthelm/controllers/life-insurance/about-you', ['exports', 'projecthelm/mixins/validation-life-combined', 'projecthelm/controllers/life-insurance-quote', 'projecthelm/utils/forms', 'projecthelm/config/environment'], function (exports, _validationLifeCombined, _lifeInsuranceQuote, _forms, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var calculatorUrl = _environment.default.theme.calculatorUrl;
    var computed = Ember.computed,
        inject = Ember.inject;
    exports.default = _lifeInsuranceQuote.default.extend(_validationLifeCombined.default, {
        currentUser: inject.service(),
        applicationController: inject.controller('application'),
        lifeInsuranceController: inject.controller('life-insurance'),
        isComplete: computed.alias('isValid'),
        displayErrors: false,
        ageIsInvalid: false,
        tooltips: _forms.tooltips,
        postcodeIsInvalid: false,
        displayPostcodeErrors: computed.or('displayErrors', 'postcodeIsInvalid'),

        chooseCoverController: inject.controller('life-insurance/choose-cover'),
        calculatorResultsInput: Ember.computed.alias('applicationController.calculatorResultsInput'),
        progressiveDisclosureEnabled: Ember.computed(function () {
            return false;
        }),
        displayCoverAmounts: Ember.computed('progressiveDisclosureEnabled', 'calculatorResultsInput', {
            get: function get() {

                if (this.get('progressiveDisclosureEnabled')) {
                    return this.get('calculatorResultsInput');
                }

                return true;
            },
            set: function set(key, value) {
                return value;
            }
        }),

        quoteChanged: Ember.observer('quote.age', function () {
            if (this.get('quote.age') !== undefined && !this.get('lifeInsuranceController.isValid')) {
                this.set('ageIsInvalid', true);
            }
        }),

        cleanUri: location.protocol + "//" + location.host + location.pathname,
        queryParams: ['life', 'tpd', 'trauma', 'member'],
        life: null,
        tpd: null,
        trauma: null,
        member: null,
        init: function init() {
            // Clean up uri
            // window.history.replaceState({}, document.title, this.cleanUri);
            return this._super();
        },

        trackCoverInputs: function trackCoverInputs() {
            var _this = this;

            var tpdAdded = this.get('quote.tpdCover') && this.get('quote.tpdBenefit');
            var traumaAdded = this.get('quote.traumaCover') && this.get('quote.traumaBenefit');

            var stateObject = _forms.stateOptions.find(function (option) {
                return option.value === _this.get('quote.state');
            });
            var coverType = 'life cover only';
            coverType = tpdAdded ? 'life cover and added TPD' : coverType;
            coverType = traumaAdded ? 'life cover and added Trauma Cover' : coverType;
            coverType = tpdAdded && traumaAdded ? 'life cover and added TPD and Trauma Cover' : coverType;

            dataLayer.push({
                event: 'Cover Chosen',
                tpdInput: tpdAdded,
                traumaInput: traumaAdded,
                gender: this.get('quote.gender') === 'F' ? 'female' : 'male',
                age: this.get('quote.age'),
                state: stateObject.label,
                issmoking: this.get('quote.smoker') === 'N' ? 'No' : 'Yes',
                occupation: this.get('quote.occupation').value,
                covertype: coverType
            });
        },


        /**
        * Reset controller to default state
        *
        * @method _reset
        */
        reset: function reset() {
            this.setProperties({
                displayNoCoverMessage: false,
                displayErrors: false
            });
        },


        prefillQuote: Ember.observer('life', 'tpd', 'trauma', function () {
            var life = this.get('life');
            var tpd = this.get('tpd');
            var trauma = this.get('trauma');

            this.get('currentUser.lifeQuote').setProperties({
                lifeCover: !!life, // coerce to boolean
                lifeBenefit: life,
                tpdCover: !!tpd, // coerce to boolean
                tpdBenefit: tpd,
                traumaCover: !!trauma, // coerce to boolean
                traumaBenefit: trauma
            });

            this.set('applicationController.calculatorResultsInput', true);
        }),

        actions: {
            handleCalculatorReturnVisit: function handleCalculatorReturnVisit() {
                var _this2 = this;

                // Users returning to the funnel from the LI calculator will have their data pre-filled from the calculator results. 
                // Check data is valid and advance to next step.  
                Ember.run.scheduleOnce('afterRender', function () {
                    if (_this2.get('isValid')) {
                        _this2.transitionToRoute('life-insurance.choose-cover');
                    }
                });
            },
            redirectToCalculator: function redirectToCalculator() {
                var occupation = this.get('quote.occupation');
                var data = {
                    gender: this.get('quote.gender'),
                    age: this.get('quote.age'),
                    state: this.get('quote.state'),
                    smoker: this.get('quote.smoker'),
                    postcode: this.get('quote.postcode'),
                    occupation: occupation && occupation.reference
                };
                sessionStorage.setItem('comparisonData', JSON.stringify(data));
                window.location = calculatorUrl;
            },

            /**
            * toggleCoverType
            *
            * @param  {string} coverType [property to be toggled]
            */
            toggleCoverType: function toggleCoverType(quote, coverType) {
                quote.toggleProperty(coverType);
            },
            submit: function submit() {
                var showVariant = window.google_optimize && window.google_optimize.get('FbrEXHEZRc-nTGHHTzem_A') === '1';

                if (showVariant) {
                    this.get('currentUser.lifeQuote').setProperties({
                        tpdCover: false,
                        traumaCover: false
                    });
                }
                this.transitionToRoute('life-insurance.choose-cover');
            },
            toggleCoverAmounts: function toggleCoverAmounts() {
                this.toggleProperty('displayCoverAmounts', true);
            },
            reset: function reset() {
                this.reset();
            },


            /**
             *
             * @method completeStep
             * @param  {string} redirect [route to enter following quote creation]
             */
            createQuote: function createQuote() {
                if (this.get('isValid') && !this.get('noCoverValue')) {
                    this.trackCoverInputs();
                    this.send('scrollToTop');
                    this.reset();
                    this.set('calculatorResultsInput', false);
                    return true;
                } else {
                    this.setProperties({
                        displayNoCoverMessage: true,
                        displayErrors: true
                    });
                }
            }
        }
    });
});