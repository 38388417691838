define('projecthelm/routes/life-insurance', ['exports', 'projecthelm/routes/quote'], function (exports, _quote) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _quote.default.extend({
        currentUser: Ember.inject.service('current-user'),
        quoteType: 'life-quote',
        quoteTypeRoute: 'life-insurance',

        beforeModel: function beforeModel(transition) {
            var params = transition.queryParams;

            if (params.age) {
                this.get('currentUser').set('lifeQuote.age', params.age);
            }

            if (params.gender) {
                this.get('currentUser').set('lifeQuote.gender', params.gender);
            }

            if (params.state) {
                this.get('currentUser').set('lifeQuote.state', params.state);
            }

            if (params.smoker) {
                this.get('currentUser').set('lifeQuote.smoker', params.smoker);
            }

            if (params.postcode) {
                this.get('currentUser').set('lifeQuote.postcode', params.postcode);
            }

            if (params.occupation) {
                var occupation = window.occupations.findBy('reference', transition.queryParams.occupation);
                this.get('currentUser').set('lifeQuote.occupation', occupation);
            }

            // Populate data for user returning from calculator and advance to next step
            var sessionData = sessionStorage.getItem('comparisonData');
            if (sessionData) {
                var currentUser = this.get('currentUser');
                var data = JSON.parse(sessionData);
                if (data) {
                    currentUser.set('lifeQuote.smoker', data.smoker);
                    currentUser.set('lifeQuote.age', data.age);
                    currentUser.set('lifeQuote.gender', data.gender);
                    currentUser.set('lifeQuote.occupation', window.occupations.findBy('reference', data.occupation));
                    currentUser.set('lifeQuote.state', data.state);
                    currentUser.set('lifeQuote.postcode', data.postcode);
                    currentUser.set('calculatorResturnVisit', true);
                    sessionStorage.removeItem('comparisonData');
                }
            }
        }
    });
});