define('projecthelm/components/quiz-question', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        scores: Ember.inject.service('quiz-scores'),
        question: null,
        quiz: null,

        actions: {
            setAnswer: function setAnswer(answer) {
                // Delete existing answer for question
                this.get('scores').delete(this.get('question.id'));

                // Add new answer
                this.get('scores').add(answer);

                // GTM Event
                dataLayer.push({
                    event: 'quizProgression',
                    eventAction: this.get('quiz.title') + ': Progression',
                    eventLabel: 'Question ' + this.get('question.id') + ': ' + answer.get('title')
                });

                // Goto next question
                this.get('questionAnswered')(answer);
            }
        }
    });
});