define('projecthelm/adapters/quote', ['exports', 'ember-data', 'projecthelm/adapters/application'], function (exports, _emberData, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        flashMessages: Ember.inject.service('flash-messages'),
        cookies: Ember.inject.service('cookies'),

        primaryKey: 'affiliateId',

        pathForType: function pathForType() {
            return 'quotes';
        },
        urlForUpdateRecord: function urlForUpdateRecord() {
            return this.get('host') + '/' + this.get('namespace') + '/quotes';
        },
        methodForRequest: function methodForRequest(_ref) {
            var requestType = _ref.requestType;


            if (requestType === "updateRecord") {
                return "POST";
            }

            return this._super.apply(this, arguments);
        },
        handleResponse: function handleResponse(status, headers, payload, requestData) {

            if (status === 200 && Ember.isEmpty(payload) && requestData.method === "PUSH") {
                return new _emberData.default.AdapterError({});
            }

            return this._super(status, headers, payload, requestData);
        },
        apply: function apply(model, params) {
            var id = model.get('id');
            var url = this.host + '/' + this.namespace + '/' + this.pathForType() + '/' + id + '/omnium';
            return this.ajax(url, 'POST', { data: params });
        },
        updateRecord: function updateRecord() {
            var cookieService = this.get('cookies');
            var now = new Date().getTime();
            var delay = now - 2500;

            var lastApiHit = cookieService.read('lastApiHit');
            cookieService.write('lastApiHit', now);

            if (isNaN(lastApiHit) || lastApiHit < delay) {
                return this._super.apply(this, arguments);
            } else {
                Ember.get(this, 'flashMessages').add({
                    message: 'You have reached your API request limit. Please wait 3 seconds and try again',
                    type: 'danger'
                });

                return false;
            }
        }
    });
});