define('projecthelm/components/input-tel', ['exports', 'projecthelm/components/input-mask'], function (exports, _inputMask) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _inputMask.default.extend({
        classNames: ['input-text inspectletIgnore'],
        type: 'tel',
        options: {
            mask: [{ "mask": "92 9999 9999", "cc": "AU", "cd": "Australia", "city": "Central East" }, { "mask": "93 9999 9999", "cc": "AU", "cd": "Australia", "city": "South East" }, { "mask": "97 9999 9999", "cc": "AU", "cd": "Australia", "city": "North East" }, { "mask": "98 9999 9999", "cc": "AU", "cd": "Australia", "city": "Central and West" }, { "mask": "9499 999 999", "cc": "AU", "cd": "Australia", "city": "Mobile" }],
            keepStatic: false,
            showMaskOnHover: false,
            showMaskOnFocus: false,
            placeholder: '',
            onUnMask: function onUnMask(maskedValue) {
                return maskedValue.replace(/\s+/g, '');
            }
        }

    });
});