define('projecthelm/mixins/validation-life', ['exports', 'projecthelm/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var phone = _environment.default.theme.phone;
    exports.default = Ember.Mixin.create({
        validations: {
            'quote.gender': {
                presence: { message: "Please select your gender" }
            },
            'quote.age': {
                presence: { message: "Please enter your age" },
                numericality: {
                    allowBlank: false,
                    onlyInteger: true,
                    greaterThanOrEqualTo: 18,
                    lessThanOrEqualTo: 75,
                    messages: {
                        greaterThanOrEqualTo: 'Unfortunately due to your age we\'re unable to offer you any cover. If you\'d like to discuss your options further, please call us on <a href="tel:' + phone + '">' + phone + '</a>.',
                        lessThanOrEqualTo: 'Unfortunately due to your age we\'re unable to offer you any cover. If you\'d like to discuss your options further, please call us on <a href="tel:' + phone + '">' + phone + '</a>.'
                    }
                }
            },
            'quote.state': {
                presence: { message: "Please select your state" }
            },
            'quote.postcode': {
                numericality: {
                    allowBlank: false,
                    onlyInteger: true
                },
                length: {
                    number: 4,
                    is: 4,
                    messages: {
                        wrongLength: "Please enter a 4 digit postcode."
                    }
                }
            },
            'quote.smoker': {
                presence: { message: "Please select your smoking status" }
            },
            'quote.occupation': {
                presence: { message: "Please enter your occupation" }
            }
        }
    });
});