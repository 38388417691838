define('projecthelm/routes/tal-calc-calculator', ['exports', 'tal-calculator/routes/tal-calc-calculator', 'projecthelm/data/data'], function (exports, _talCalcCalculator, _data) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _talCalcCalculator.default.extend({
        currentUser: Ember.inject.service(),
        gtm: Ember.inject.service(),
        talCalcProgress: Ember.inject.service(),
        theme: Ember.inject.service(),
        init: function init() {
            this._super(_data.default);
        },


        // calcToGtm: on('init', observer('talCalcProgress.state.@each.value', function() {
        //     const calcData = {};

        //     if (this.get('talCalcProgress.state.length')) {
        //         this.get('talCalcProgress.state').forEach((question) => {
        //             // const value =
        //             calcData[question.key] = isNaN(question.value) ? null : question.value;
        //         });
        //     }

        //     this.get('gtm').setProps({
        //         calculator: calcData
        //     })
        // })),

        afterModel: function afterModel() {
            if (this.get('theme.id') === 'lifebroker') {
                document.title = 'Life Insurance Calculator | Life Insurance Comparison | Lifebroker';
                document.querySelector('meta[name="description"]').setAttribute("content", 'Wondering how much life insurance cover you may need? Calculate your life insurance options based on your family situation, income and debts.');
            }
        },


        actions: {
            closeTalCalculator: function closeTalCalculator() {
                var referer = this.controllerFor('tal-calc-calculator').get('referer');
                var affiliateId = this.controllerFor('tal-calc-calculator').get('affiliateId');
                if (referer && affiliateId) {
                    this.transitionTo(referer, affiliateId, { queryParams: { calc: true } });
                } else {
                    this.transitionTo('life-insurance.choose-cover');
                }
            },
            outputResults: function outputResults(life, tpd, trauma) {
                var applicationController = this.controllerFor('application');
                var activeLifeQuote = this.get('currentUser.lifeQuote');

                activeLifeQuote.setProperties({
                    lifeCover: true,
                    lifeBenefit: life,
                    tpdCover: true,
                    tpdBenefit: tpd,
                    traumaCover: true,
                    traumaBenefit: trauma
                });

                applicationController.set('calculatorResultsInput', true);
                this.send('closeTalCalculator');
            }
        }
    });
});