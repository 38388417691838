define('projecthelm/validators/local/conditional-presence', ['exports', 'ember-validations/validators/local/presence'], function (exports, _presence) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _presence.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      var condition;

      if (this.conditionals) {
        condition = this.conditionals['if'] || this.conditionals['unless'];
      }

      Ember.assert('You must specify "if" or "unless" in the options for the conditional presence validator.', !!condition);

      if (Ember.typeOf(condition) === 'string') {
        // Condition based on another property. Make sure to watch that property
        this.dependentValidationKeys.pushObject(condition);
      }
    }
  });
});