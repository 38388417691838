define('projecthelm/routes/customise/policy-options/additional-options', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model() {
            var section = this.modelFor('customise.policy-options');
            return section.get('steps').findBy('id', 9);
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('step', model);
            controller.send('completeStep', model);
        },
        deactivate: function deactivate() {
            this._super.apply(this, arguments);
        }
    });
});