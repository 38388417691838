define('projecthelm/components/tal-calc-question', ['exports', 'tal-calculator/components/tal-calc-question'], function (exports, _talCalcQuestion) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _talCalcQuestion.default;
    }
  });
});