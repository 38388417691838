define('projecthelm/services/quiz-scores', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        store: Ember.inject.service(),
        answers: [],
        totalScore: Ember.computed('answers', function () {
            var total = this.get('answers').reduce(function (total, answer) {
                var answerValue = parseInt(answer.get('value'));
                return total + answerValue;
            }, 0);

            return Math.floor(total / this.get('maxPossibleResult') * 100);
        }),
        questions: Ember.computed('store', function () {
            var store = this.get('store');
            return store.peekAll('quiz-question');
        }),
        questionMaximums: Ember.computed.mapBy('questions', 'highestAnswerValue'),
        maxPossibleResult: Ember.computed.sum('questionMaximums'),

        add: function add(answer) {
            this.get('answers').pushObject(answer);
        },
        fetch: function fetch(questionId) {
            return this.get('answers').find(function (answer) {
                return answer.get('question.id') === questionId;
            });
        },
        all: function all() {
            return JSON.stringify(this.get('answers'));
        },
        count: function count() {
            return this.get('answers.length');
        },
        delete: function _delete(questionId) {
            // Delete answer with questionId
            var newAnswers = this.get('answers').reject(function (answer) {
                return answer.get('question.id') === questionId;
            });

            this.set('answers', newAnswers);
        },
        clear: function clear() {
            this.set('answers', []);
        }
    });
});