define('projecthelm/controllers/modals/request-call', ['exports', 'projecthelm/mixins/modal', 'projecthelm/controllers/lead'], function (exports, _modal, _lead) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _lead.default.extend(_modal.default, {
        actions: {
            reset: function reset() {
                this._reset();
            },
            leadSubmission: function leadSubmission() {
                this._super();
            },
            leadSubmissionSuccess: function leadSubmissionSuccess() {
                // Push event to GTM
                dataLayer.push({
                    event: 'submitModal',
                    name: 'requestCall'
                });
            }
        }
    });
});