define('projecthelm/components/calculator-custom-value', ['exports', 'ember-validations'], function (exports, _emberValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_emberValidations.default, {
        willDestroyElement: function willDestroyElement() {
            this.set('displayErrors', false);
        },


        // Custom value validation
        validations: {
            value: {
                numericality: {
                    onlyInteger: true,
                    greaterThan: 0
                }
            }
        },

        notAnswered: Ember.computed('value', function () {
            var value = parseInt(this.get('value')),
                validation = parseInt(this.get('validation'));
            return value > validation ? false : true;
        }),

        actions: {
            submitValue: function submitValue() {
                if (this.get('isValid')) {
                    this.sendAction();
                } else {
                    this.set('displayErrors', true);
                }
            }
        }
    });
});