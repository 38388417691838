define('projecthelm/components/x-quiz', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        scores: Ember.inject.service('quiz-scores'),

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            var questionIds = 1;
            var answerIds = 1;
            var resultIds = 1;

            this.get('questionsData').forEach(function (question) {
                var questionRecord = _this.get('store').createRecord('quiz-question', {
                    id: questionIds,
                    title: question.title
                });

                question.answers.forEach(function (answer) {
                    _this.get('store').createRecord('quiz-answer', {
                        id: answerIds,
                        title: answer.title,
                        value: answer.value,
                        image: answer.image,
                        question: questionRecord
                    });
                    answerIds++;
                });
                questionIds++;
            });

            this.get('resultsData').forEach(function (result) {
                _this.get('store').createRecord('quiz-result', {
                    id: resultIds,
                    pageUrl: result.pageUrl,
                    rangeMin: result.rangeMin,
                    rangeMax: result.rangeMax
                });
                resultIds++;
            });

            // Skip Splash page
            this.send('gotoQuestion', 1);
        },


        questions: Ember.computed('store', function () {
            var store = this.get('store');
            return store.peekAll('quiz-question');
        }),

        results: Ember.computed('store', function () {
            var store = this.get('store');
            return store.peekAll('quiz-result');
        }),

        questionsLength: Ember.computed.alias('questions.length'),

        currentQuestion: null,

        answersPopulated: Ember.computed('scores.answers', function () {
            return this.get('scores').count() === this.get('questionsLength');
        }),

        showHome: Ember.computed('currentQuestion', 'scores.answers', function () {
            if (this.get('currentQuestion') === null && this.get('scores').count() === 0) {
                return true;
            }
            return false;
        }),

        showResults: Ember.observer('currentQuestion', 'answersPopulated', function () {
            if (this.get('currentQuestion') === null && this.get('answersPopulated')) {
                this.send('openResults');
            }
            return false;
        }),

        answerDebug: Ember.computed('currentQuestion', function () {
            return this.get('scores').count();
        }),

        actions: {
            gotoQuestion: function gotoQuestion(id) {
                var _this2 = this;

                if (id <= this.get('questionsLength')) {
                    var store = this.get('store');
                    store.findRecord('quiz-question', id).then(function (question) {
                        _this2.set('currentQuestion', question);
                    });

                    // if (id === 1) {
                    //     dataLayer.push({
                    //         event: 'quizProgression',
                    //         eventAction: this.get('quizData.title') + ': Progression',
                    //         eventLabel: 'Start Quiz'
                    //     });
                    // }

                    if (this.get('media.isPalm')) {
                        window.scrollTo(0, 0);
                    }
                } else if (this.get('answersPopulated')) {
                    this.set('currentQuestion', null);
                }
            },
            nextQuestion: function nextQuestion() {
                var nextId = parseInt(this.get('currentQuestion.id')) + 1;
                this.send('gotoQuestion', nextId);
            },
            openResults: function openResults() {
                var total = this.get('scores.totalScore');

                this.get('results').find(function (result) {
                    if (total >= result.get('rangeMin') && total <= result.get('rangeMax')) {
                        window.location.assign(result.get('pageUrl') + '?score=' + total);
                    }
                });
            },
            reset: function reset() {
                this.get('scores').clear();
            }
        }
    });
});