define('projecthelm/controllers/customise/pre-assessment-failed', ['exports', 'projecthelm/controllers/lead', 'projecthelm/utils/forms'], function (exports, _lead, _forms) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _lead.default.extend({
        progress: 'PreAssessmentFailed',
        requestCall: false,
        stateOptions: _forms.stateOptions,

        validations: {
            'lead.name': _forms.validation.name,
            'lead.email': _forms.validation.email,
            'lead.state': _forms.validation.state,
            'lead.phone': _forms.validation.phone,
            'callDay': _forms.validation.callDay,
            'callWindow': _forms.validation.callWindow
        },

        actions: {
            reset: function reset() {
                this._reset();
            },
            leadSubmission: function leadSubmission() {
                this._super();
            },
            leadSubmissionSuccess: function leadSubmissionSuccess() {
                window.location.href = '/life-insurance-confirmation?name=' + this.get('lead.name');
                // Push event to GTM
                // dataLayer.push({
                //     event: 'submitModal',
                //     name: 'requestCall'
                // });
            }
        }
    });
});