define('projecthelm/routes/life-insurance/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        redirect: function redirect() {
            return this.replaceWith('life-insurance.about-you');
        },
        afterModel: function afterModel() {
            var newMeta = document.createElement("meta");
            newMeta.setAttribute("name", "robots");
            newMeta.setAttribute("content", "noindex,nofollow");
            document.head.appendChild(newMeta);
        }
    });
});