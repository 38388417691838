define('projecthelm/routes/income-protection/compare', ['exports', 'projecthelm/routes/compare'], function (exports, _compare) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _compare.default.extend({
        model: function model(params) {
            return this.store.query('ipQuote', {
                affiliateId: params.ipQuote_id,
                quoteType: 'ip'
            }).then(function (response) {
                return response.get('firstObject');
            });
        },
        afterModel: function afterModel(quote) {
            this._super(quote);
            this.get('currentUser').set('ipQuote', quote);
        }
    });
});