define('projecthelm/controllers/customise/introduction', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            nextStep: function nextStep() {
                dataLayer.push({
                    event: 'applicationProgress',
                    eventAction: 'Step 3: Introduction'
                });

                this.transitionToRoute('customise.important-information');
            }
        }
    });
});