define('projecthelm/transforms/occupation', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Transform.extend({
        deserialize: function deserialize(serialized) {
            var occupation = window.occupations.findBy('reference', String(serialized));
            return occupation ? occupation : null;
        },
        serialize: function serialize(deserialized) {

            if (deserialized && deserialized.reference) {
                return deserialized.reference;
            } else if (deserialized) {
                return deserialized;
            }
        }
    });
});