define('projecthelm/controllers/customise', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        currentUser: Ember.inject.service(),
        isNavEnabled: true,

        isNavOpen: Ember.computed('media.isJumbo', {
            get: function get() {
                return this.get('media.isJumbo');
            },
            set: function set(key, value) {
                return value;
            }
        }),

        progressStyle: Ember.computed('customiser.percentageComplete', function () {
            return Ember.String.htmlSafe('width: ' + this.get('customiser.percentageComplete') + '%');
        })
    });
});