define('projecthelm/components/income-calculator', ['exports', 'ember-validations', 'projecthelm/utils/forms', 'projecthelm/config/environment'], function (exports, _emberValidations, _forms, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var phone = _environment.default.theme.phone;
    exports.default = Ember.Component.extend(_emberValidations.default, Ember.Evented, {
        tooltips: _forms.tooltips,

        validations: {
            'income': {
                presence: { message: "You need to enter your income first" },
                numericality: {
                    allowBlank: true,
                    onlyInteger: true,
                    lessThanOrEqualTo: 320000,
                    greaterThanOrEqualTo: 20000,
                    messages: {
                        lessThanOrEqualTo: 'Enter an income under $320,000 or call <a href="tel:' + phone + '">' + phone + '</a> to compare',
                        greaterThanOrEqualTo: 'Enter an income over $20,000 or call <a href="tel:' + phone + '">' + phone + '</a> to compare'
                    }
                }
            }
        },

        init: function init() {
            this._super.apply(this, arguments);
            this._reset();
            this.set('formLabel', 'Enter your annual income before tax');
        },
        _reset: function _reset() {
            this.setProperties({
                income: null,
                noIncome: true,
                formLabel: 'Recalculate with a different income'
            });
            this.set('displayErrors', false);
        },


        gtmEvent: Ember.observer('noIncome', function () {
            if (!this.get('noIncome')) {
                window.dataLayer.push({
                    event: 'ipCalculator'
                });
            }
        }),

        actions: {
            formSubmission: function formSubmission() {
                if (!this.get('isValid')) {
                    this.set('displayErrors', true);
                    return;
                }

                this.set('maxBenefit', (0, _forms.calculateMaxBenefit)(this.get('income')));
                this.set('isProcessing', true);
                this.set('noIncome', false);
            },
            resetForm: function resetForm() {
                this._reset();
            }
        }
    });
});