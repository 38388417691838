define('projecthelm/controllers/lead', ['exports', 'ember-validations', 'projecthelm/mixins/call-options-embed'], function (exports, _emberValidations, _callOptionsEmbed) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed,
        inject = Ember.inject;
    var alias = computed.alias,
        equal = computed.equal,
        empty = computed.empty;
    exports.default = Ember.Controller.extend(_emberValidations.default, Ember.Evented, _callOptionsEmbed.default, {
        currentUser: inject.service(),
        quote: alias('currentUser.activeQuote'),
        lead: alias('currentUser.lead'),
        product: alias('model'),
        gating: false,
        hasValidPhone: empty('errors.lead.phone'),
        isIpQuote: equal('quote.quoteType', 'ip'),
        isLifeQuote: equal('quote.quoteType', 'life'),

        init: function init() {
            this._super.apply(this, arguments);

            this.setProperties({
                isProcessing: false,
                displayErrors: false,
                displayPhoneErrors: false,
                leadSubmitted: false
            });
        },


        /**
         * Listen for currentUser service lead events
         */
        bindLeadEventListeners: Ember.on('init', function () {
            var _this = this;

            var currentUser = this.get('currentUser');

            currentUser.on('leadSubmission', function (lead) {
                // Check if current modal submitted the lead
                if (lead.get('additional.progress') === _this.get('progress')) {
                    _this.send('leadSubmission');
                }
            });

            currentUser.on('leadSubmissionSuccess', function (lead) {
                // Check if current modal submitted the lead
                if (lead.get('additional.progress') === _this.get('progress')) {
                    _this.send('leadSubmissionSuccess');
                }
            });

            currentUser.on('leadSubmissionComplete', function () {
                _this.set('isProcessing', false);
            });
        }),

        /**
         * @method _reset
         * Reset state of lead
         */
        _reset: function _reset() {
            this.setProperties({
                leadSubmitted: false,
                displayErrors: false
            });
            this.send('closeModal');
        },


        actions: {

            /**
             * @method createLead
             * Create modelled lead based on current properties of controller
             * This is done due to the API limitation of not being about to
             * handle a PUT request
             * @return {[type]} [description]
             */
            createLead: function createLead() {
                // Block duplicate submission if button is clicked more than once
                if (this.get('isProcessing')) return;

                if (!this.get('isValid')) {
                    this.set('displayErrors', true);

                    if (this.get('requestCall')) {
                        this.set('displayPhoneErrors', true);
                    }

                    return;
                }

                this.set('isProcessing', true);

                var product = this.get('product');
                var properties = new Ember.Object();
                var options = new Ember.Object();

                properties.additional = this.getProperties(['progress', 'callTime', 'comments']);

                if (this.get('callWindow')) {
                    properties.additional.callbackRange = this.get('callWindow');
                }

                // Product will exist if they have used the apply button
                if (product) {
                    properties.set('product', product);
                }

                if (this.get('sendEmail') && !this.get('quote.leadCreated')) {
                    options.set('sendEmail', true);
                }

                if (this.get('gating')) {
                    options.set('gating', true);
                }

                if (this.get('options.quoteType')) {
                    properties.set('quoteType', this.get('options.quoteType'));
                }

                this.get('currentUser').submitLead(properties, options);
            },
            leadSubmission: function leadSubmission() {
                this.set('leadSubmitted', true);
            },
            leadSubmissionFail: function leadSubmissionFail() {
                this._reset();
            },


            leadSubmissionSuccess: Ember.k
        }
    });
});