define('projecthelm/mixins/call-options-embed', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed;


    function nextAvailableCallTime(day, hour) {
        // Set day (moment obj) to the chosen time (string).
        return day.hour(hour);
    }

    exports.default = Ember.Mixin.create({
        init: function init() {
            this._super.apply(this, arguments);

            this.setProperties({
                callDay: null
            });
        },


        callWindowOptions: computed('callDay', function () {

            return [{ value: '1', label: 'Call me before 11.00AM' }, { value: '2', label: 'Call me between 11.00AM - 2.00PM' }, { value: '3', label: 'Call me between 2.00PM - 4.00PM' }, { value: '4', label: 'Call me after 4.00PM' }];
        }).readOnly(),

        callWindow: computed('callDay', {
            get: function get() {
                if (!this.get('callDay')) {
                    return null;
                }
            },
            set: function set(key, callWindow) {
                return callWindow;
            }
        }),

        comments: computed('callDay', 'callWindow', function () {
            var callDay = this.get('callDay');
            var callWindow = this.get('callWindow');
            var callWindowLabel = this.callWindowLookup(callWindow);
            if (!callWindow || !callDay) {
                return "Customer has requested a call back at anytime during business hours.";
            } else {
                var nextAvailable = nextAvailableCallTime(callDay, callWindow).format('YYYY-MM-DD');
                return callWindowLabel + ' - Customer has requested a call back ' + nextAvailable + '.';
            }
        }).readOnly(),

        callTime: computed('callDay', 'callWindow', function () {
            var callDay = this.get('callDay');
            var callWindow = this.get('callWindow');
            if (callWindow && callDay) {
                return nextAvailableCallTime(callDay, callWindow).format('YYYY-MM-DD');
            }
        }).readOnly(),

        callWindowLookup: function callWindowLookup(value) {
            var entry = this.get('callWindowOptions').findBy('value', value);
            return entry ? entry.label : null;
        }
    });
});