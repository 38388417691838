define('projecthelm/data/customiser', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var sections = exports.sections = [{
        id: 1,
        name: 'Introduction',
        route: 'customise.introduction',
        isComplete: false,
        steps: []
    }, {
        id: 2,
        name: 'Important Policy Info',
        route: 'customise.important-information',
        steps: [{
            id: 1,
            name: 'Product Disclosure Statement',
            route: 'customise.important-information.product-disclosure-statement'
        }, {
            id: 2,
            name: 'Duty of Disclosure',
            route: 'customise.important-information.duty-of-disclosure'
        }, {
            id: 3,
            name: 'Standard Exclusions',
            route: 'customise.important-information.standard-exclusions'
        }]
    }, {
        id: 3,
        name: 'Pre-Assessment',
        route: 'customise.pre-assessment',
        isComplete: false,
        steps: []
    }, {
        id: 4,
        name: 'Policy Options',
        route: 'customise.policy-options',
        steps: [{
            id: 4,
            name: 'Type of Premium',
            route: 'customise.policy-options.type-of-premium'
        }, {
            id: 5,
            name: 'Occupation Cover',
            route: 'customise.policy-options.occupation-cover',
            quoteType: 'life',
            tpdOnly: true
        }, {
            id: 6,
            name: 'Waiting & Benefit Periods',
            route: 'customise.policy-options.waiting-and-benefit-periods',
            quoteType: 'ip'
        }, {
            id: 7,
            name: 'Policy Style',
            route: 'customise.policy-options.policy-style',
            quoteType: 'ip'
        }, {
            id: 8,
            name: 'Premium Frequency',
            route: 'customise.policy-options.premium-frequency'
        }, {
            id: 9,
            name: 'Additional Options',
            route: 'customise.policy-options.additional-options'
        }]
    }];
});