define('projecthelm/controllers/compare', ['exports', 'ember-cli-pagination/computed/paged-array', 'projecthelm/utils/adobe-tracking'], function (exports, _pagedArray, _adobeTracking) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed,
        observer = Ember.observer,
        inject = Ember.inject;
    var alias = computed.alias,
        equal = computed.equal,
        notEmpty = computed.notEmpty,
        sort = computed.sort;
    exports.default = Ember.Controller.extend({
        queryParams: ['page', 'calc'],

        applicationController: inject.controller('application'),

        router: inject.service(),

        isQuoteSaving: alias('applicationController.quoteSaving'),

        isQuoteProcessing: alias('applicationController.quoteProcessing'),

        calc: false,

        shortlist: false,

        requiresSecondarySort: ["size.value:asc", "formattedClaimsPaid:desc", "spRatingNumeric:desc"],

        sortBy: computed('model.sortBy', function () {
            _adobeTracking.sortBy(this.get('model.sortBy'));

            var sortBy = [this.get('model.sortBy')];

            if (this.get('requiresSecondarySort').includes(this.get('model.sortBy'))) {
                sortBy.push('value:asc');
            }

            return sortBy;
        }).readOnly(),

        offCanvasOpen: false,

        layout: '',

        view: alias('model.view'),

        favouritesCount: alias('model.favouriteProducts.length'),

        areFavourites: notEmpty('model.favouriteProducts'),

        isDetailedLayout: equal('layout', 'detailed'),

        detailedLayoutCategoryColspan: computed('pagedProducts.length', function () {
            var productCount = this.get('pagedProducts.length');

            if (this.get('media.isPalm')) {
                return productCount;
            } else {
                return productCount + 1;
            }
        }).readOnly(),

        isSimpleLayout: equal('layout', 'simple'),

        // open edit panel if directed from calc
        triggerOffCanvas: observer('calc', function () {
            this.set('offCanvasOpen', this.get('calc'));
        }),

        /**
         * Possible sort options
         *
         * @returns {array} sortOptions
         */
        sortOptions: [{ label: "Price (low - high)", value: "value:asc" }, { label: "Price (high - low)", value: "value:desc" }, { label: "Company Size", value: "size.value:asc" }, { label: "Claims Paid", value: "claimsPaid:desc" }],

        showOptions: [{ label: "All", value: "all" }, { label: "Selected", value: "selected" }],

        activeView: computed('router.currentRouteName', function () {
            return this.get('router.currentRouteName').includes('shortlist') ? 'selected' : 'all';
        }).readOnly(),

        /**
         * Returns current products sorted by current sort selection
         *
         * @method sortedAll
         * @returns {array} sorted array of all products
        */
        sortedAll: sort('model.products', 'sortBy'),

        /**
         * Returns favourite products sorted by current sort selection
         *
         * @method sortedShortlist
         * @returns {array} sorted array of all favourite products
        */
        sortedShortlist: sort('model.favouriteProducts', 'sortBy'),

        /**
         * Returns sorted current products depending on whether we're
         * in the shortlist or full routes
         *
         * @method sortedProducts
         * @returns {array} sorted array of products
         */
        sortedProducts: computed('shortlist', 'sortedShortlist', 'sortedAll', function () {
            return this.get('shortlist') ? this.get('sortedShortlist') : this.get('sortedAll');
        }).readOnly(),

        /**
         * When sortBy changes dispatch GTM event and update model
         *
         */
        sortByObserver: observer('sortBy', function () {

            dataLayer.push({
                'event': 'sortBy',
                'currentSort': this.get('model.sortBy')
            });
        }),

        /**
         * Pagination
         */
        page: 1,

        /**
         * Calculate the number of products to show per page based on the screen width
         * @method perPage
         * @returns {int} number of products per page
         */
        perPage: computed('media.matches.[]', function () {

            var media = this.get('media');

            if (media.get('isPalm')) {
                return 2;
            } else if (media.get('isJumbo')) {
                return 4;
            } else {
                return 3;
            }
        }).readOnly(),

        pagedProducts: (0, _pagedArray.default)('sortedProducts', {
            page: Ember.computed.alias('parent.page'),
            perPage: Ember.computed.alias('parent.perPage')
        }).readOnly(),

        totalPages: Ember.computed.oneWay('pagedProducts.totalPages').readOnly(),

        /**
         * Reset pagination on sort change
         * @method sortDidChange
         */
        sortDidChange: observer('sortedProducts.[]', function () {
            Ember.run.once(this, 'resetPage');
        }),

        /**
         * Set current pagination to page one
         * @method resetPage
         */
        resetPage: function resetPage() {
            this.set('page', 1);
        },


        /**
         * Actions
         */
        actions: {
            toggleCategoryVisibility: function toggleCategoryVisibility(category) {
                category.toggleProperty('isHidden');
            },
            changeView: function changeView(value) {
                var page = value.value === 'selected' ? 'shortlistUrl' : 'compareUrl';
                this.transitionToRoute(this.get(page));
            },
            toolbarSaveQuote: function toolbarSaveQuote() {
                var _this = this;

                _adobeTracking.saveQuote();

                var quote = this.get('model'),
                    applicationController = this.get('applicationController');

                if (this.get('isQuoteSaving')) {
                    return;
                }

                if (quote.get('leadCreated')) {

                    applicationController.set('quoteSaving', true);

                    quote.save().then(function () {
                        Ember.get(_this, 'flashMessages').add({
                            message: 'Your comparison has been saved and can be retrieved using the link emailed to you previously.',
                            type: 'success'
                        });
                    }).catch(function () {
                        Ember.get(_this, 'flashMessages').add({
                            message: 'There has been an issue saving your quote. To speak to a consultant, call 13 5433.',
                            type: 'danger',
                            sticky: true
                        });
                    }).finally(function () {
                        applicationController.set('quoteSaving', false);
                    });
                } else {
                    this.send('openModal', 'saveQuote');
                }
            }
        }

    });
});