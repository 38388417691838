define('projecthelm/serializers/occupation', ['exports', 'projecthelm/serializers/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        isNewSerializerAPI: true,

        normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {

            window.occupations = payload.occupations;

            var documentHash = {
                data: null,
                included: []
            };

            return documentHash;
        }
    });
});