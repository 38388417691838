define('projecthelm/controllers/modals/save-quote', ['exports', 'projecthelm/mixins/modal', 'projecthelm/controllers/lead', 'projecthelm/utils/forms'], function (exports, _modal, _lead, _forms) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _lead.default.extend(_modal.default, {
        progress: 'Save',
        sendEmail: true,

        validations: {
            'lead.name': _forms.validation.name,
            'lead.phone': _forms.validation.phone,
            'lead.email': _forms.validation.email
        },

        actions: {

            /**
             * Send quoteDidChange to save quote
             * If reset send createThirdPartyQuote
             *
             * @method leadSubmission
             */
            leadSubmission: function leadSubmission() {
                this._super();
                var options = this.get('options');
                this.send('closeModal');

                if (!this.get('leadSubmitted')) {
                    return true;
                }

                this._reset();
                this.send('quoteDidChange');

                if (options && options.reset) {
                    this.send('createThirdPartyQuote');
                }
            },


            /**
             * Reset modal and show flash message when lead is submitted
             *
             * @method leadSubmissionSuccess
             */
            leadSubmissionSuccess: function leadSubmissionSuccess() {
                this._super();

                var quote = this.get('quote');
                quote.set('leadCreated', true);

                Ember.get(this, 'flashMessages').add({
                    message: 'Your comparison has been saved. We\'ve emailed you a unique link so you can view it later.',
                    type: 'success'
                });

                // Push event to GTM
                dataLayer.push({
                    'event': 'saveComparison'
                });

                dataLayer.push({
                    event: 'submitModal',
                    name: 'saveQuote'
                });
            }
        }
    });
});