define('projecthelm/routes/compare', ['exports', 'projecthelm/utils/iselect-tracking', 'projecthelm/utils/adobe-tracking', 'projecthelm/utils/global-tracking'], function (exports, _iselectTracking, _adobeTracking, _globalTracking) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var inject = Ember.inject;
    exports.default = Ember.Route.extend({
        currentUser: inject.service(),
        cookies: inject.service(),
        gtm: inject.service(),
        router: inject.service(),
        theme: inject.service(),

        afterModel: function afterModel(quote) {
            var _this = this;

            var products = quote.get('products').sortBy('value').toArray();

            // Redirect if no products
            if (Ember.isEmpty(quote.get('products'))) {
                (0, _iselectTracking.trackNoResults)();
                _adobeTracking.noResults(quote.get("lifeBenefit"), quote.get("traumaBenefit"), quote.get("tpdBenefit"), quote.get("income"), quote.get("benefit"));
                window.dataLayer.push({
                    event: 'no products found'
                });

                _globalTracking.generalData(quote.get("lifeBenefit"), quote.get("tpdBenefit"), quote.get("traumaBenefit"), quote.get("income"), quote.get("benefit"), quote.get("gender"), quote.get("age"), quote.get("postcode"), quote.get("state"), quote.get("smoker"), quote.get("occupation"), 'no_products_found');
                return this.transitionTo(this.get('parentRoute') + '.no-products');
            }

            // Redirect if quote expired
            if (quote.get('hasExpired')) {
                return this.transitionTo('expired');
            }

            // Set active quote to be the current quote
            this.get('currentUser').set('activeQuote', quote);

            // quoteId sent before in order for pathName to be formatted correctly.
            this.get('gtm').setProps({ quoteId: quote.get('id') });

            var vertical = quote.get('quoteType') == 'ip' ? 'income' : 'life';

            this.get('gtm').setProps({
                event: 'quoteResults',
                hitTimeStamp: new Date(),
                pathName: this.get('router.currentURL'),
                pageName: document.title,
                vertical: vertical,
                ecommerce: {
                    impressions: products.map(function (product, index) {
                        return {
                            name: product.get('name'),
                            id: product.get('id'),
                            price: product.get('valueRounded'),
                            brand: product.get('company'),
                            category: _this.get('currentUser.coverString'),
                            variant: _this.get('currentUser.viewString'),
                            list: vertical,
                            position: index + 1
                        };
                    })
                }
            });

            // Open gating modal if quote hasn't passed gate, gating is enabled
            // and A/B test signals it
            if (!quote.get('gatePassed')) {

                if (window.location.href.indexOf('comparison/life-insurance/') > -1) {
                    var tpdAdded = quote.get('tpdCover') && quote.get('tpdBenefit');
                    var traumaAdded = quote.get('traumaCover') && quote.get('traumaBenefit');
                    var lifeAdded = quote.get('lifeCover') && quote.get('lifeBenefit');
                    (0, _iselectTracking.trackCaptureLife)(lifeAdded, tpdAdded, traumaAdded);
                    _adobeTracking.stepTwo(quote.get("lifeBenefit"), quote.get("traumaBenefit"), quote.get("tpdBenefit"));
                } else {
                    var income = quote.get('income');
                    var benefit = quote.get('benefit');
                    (0, _iselectTracking.trackCaptureIncome)(income, benefit);
                    _adobeTracking.stepTwo(null, null, null, income, benefit);
                }

                this.send('openModal', 'gating');

                // Push event to GTM
                dataLayer.push({
                    'event': 'openGate'
                });
            } else {

                Ember.run.schedule('afterRender', function () {
                    _this._triggerSecondaryQuoteFlash(quote);
                });

                (0, _iselectTracking.trackResults)();
                _adobeTracking.results();
            }

            // Auto populate lead state from quote data so user doesn't have to re-enter in apply form.
            this.set('currentUser.lead.state', this.get('currentUser.activeQuote.state'));
        },
        setupController: function setupController(controller, model) {
            // Call _super for default behavior
            this._super(controller, model);
        },


        // Render edit quote view into off-canvas outlet
        renderTemplate: function renderTemplate(controller, model) {

            var parentRoute = this.get('parentRoute');

            this.render();

            this.render(parentRoute + '/edit', {
                into: parentRoute + '/compare',
                outlet: 'off-canvas',
                model: model
            });
        },


        /**
         * On change of sub-route set view of model to be current route
         *
         * @method quoteViewUpdate
         */
        quoteViewUpdate: function quoteViewUpdate() {

            var applicationController = this.controllerFor('application'),
                quote = this.get('currentModel'),
                currentPath = applicationController.get('currentRouteName').match(/\.(.*)$/, "")[1];

            if (currentPath) {
                quote.set('view', currentPath);
            }
        },


        /**
         *
         * @method debouncedSaveQuote
         */
        debouncedSaveQuote: function debouncedSaveQuote() {
            Ember.run.debounce(this, this.saveQuote, 500);
        },


        /**
         * Save quote to API
         *
         * @method saveQuote
         */
        saveQuote: function saveQuote() {
            var _this2 = this;

            var applicationController = this.controllerFor('application'),
                quote = this.get('currentModel');

            if (quote.get('isSaving') || quote.get('isDeleted')) {
                return;
            }

            if (quote.get('hasDirtyAttributes')) {

                applicationController.set('quoteSaving', true);

                quote.save().catch(function () {

                    Ember.get(_this2, 'flashMessages').add({
                        message: 'There has been an issue saving your quote. To speak to a consultant, call 13 5433.',
                        type: 'danger',
                        sticky: true
                    });

                    return true;
                }).finally(function () {
                    applicationController.set('quoteSaving', false);
                });
            }
        },


        /**
        * Set isComparing on application controller on activate
        *
        * @method setIsComparing
        */
        setIsComparing: Ember.on('activate', function () {
            this.controllerFor('application').set('isComparing', true);
        }),

        /**
         * Unset isComparing on application controller on deactivate
         *
         * @method unsetIsComparing
         */
        unsetIsComparing: Ember.on('deactivate', function () {
            this.controllerFor('application').set('isComparing', false);
        }),

        /**
         * Display flash message indicating that the quote
         * required a secondary quote in order to get results
         *
         * @method _triggerSecondaryQuoteFlash
         */
        _triggerSecondaryQuoteFlash: function _triggerSecondaryQuoteFlash(quote) {

            if (quote.get('secondaryQuote') && !quote.get('hasViewedSecondaryQuoteMessage')) {
                Ember.get(this, 'flashMessages').add({
                    message: 'Due to the information you have provided and the cover options available, this comparison has been calculated based on an indemnity style policy, a waiting period of 90 days and a 2 year benefit period.',
                    type: 'info',
                    sticky: true
                });
            }
        },


        /**
         * Reset controller to default state on exit route
         *
         * @method resetController
         */
        resetController: function resetController(controller, isExiting) {

            if (isExiting) {
                controller.set('offCanvasOpen', false);
            }
        },


        /**
         * Split routeName to return parent route
         *
         * @method parentRoute
         * @returns {string} parent route
         */
        parentRoute: Ember.computed('routeName', function () {
            return this.routeName.split('.')[0];
        }),

        actions: {
            resetEditController: function resetEditController() {
                this.controllerFor(this.get('parentRoute') + '.edit').send('reset');
            },


            /**
             * Expose triggerSecondaryQuoteFlash
             *
             * @method triggerSecondaryQuoteFlash
             */
            triggerSecondaryQuoteFlash: function triggerSecondaryQuoteFlash() {

                var currentQuote = this.get('currentModel');

                this._triggerSecondaryQuoteFlash(currentQuote);
            },
            openOffCanvas: function openOffCanvas() {
                if (this.controller.get('quoteProcessing')) {
                    return;
                }

                this.controller.set('offCanvasOpen', true);

                Ember.$('.off-canvas__aside').find('input').first().trigger('focus');
            },
            closeOffCanvas: function closeOffCanvas() {
                this.controllerFor('application').set('calculatorResultsInput', false);
                this.controllerFor('compare').set('calc', false);
                this.controller.set('offCanvasOpen', false);
            },
            toggleOffCanvas: function toggleOffCanvas() {
                this.controller.toggleProperty('offCanvasOpen');
            },
            confirmCloseOffcanvas: function confirmCloseOffcanvas() {

                var editController = this.controllerFor(this.get('parentRoute') + '.edit');

                if (editController.get('hasCoverChanged') && this.controller.get('offCanvasOpen')) {
                    this.send('openModal', 'confirmCloseOffcanvas');
                } else {
                    this.send('closeOffCanvas');
                }
            },
            confirmToggleOffCanvas: function confirmToggleOffCanvas() {
                if (this.controller.get('offCanvasOpen')) {
                    this.send('confirmCloseOffcanvas');
                } else {
                    _adobeTracking.trackToolbar('Edit my cover');
                    this.send('openOffCanvas');
                }
            },


            /**
             * Set current view on model on transition
             *
             * @method didTransition
             */
            didTransition: function didTransition() {
                Ember.run.later(this, this.quoteViewUpdate);
            },


            /**
             * Set current view on model on transition
             *
             * @method quoteDidChange
             */
            quoteDidChange: function quoteDidChange() {
                Ember.run.once(this, this.debouncedSaveQuote);
            },


            /**
             * Toggle product within quote favourite products
             *
             * @method toggleFavourite
             * @param {object} [product] product from a quote
             * @param {array} [favouriteProducts] array of favouriteProducts from quote
             */
            toggleFavourite: function toggleFavourite(product, favouriteProducts) {
                var _this3 = this;

                if (favouriteProducts.includes(product)) {
                    favouriteProducts.removeObject(product);
                    this.controller.set('isRemovingFavourite', true);
                } else {
                    favouriteProducts.pushObject(product);
                    this.controller.set('isAddingFavourite', true);
                }

                Ember.run.later(function () {
                    _this3.controller.setProperties({
                        isRemovingFavourite: false,
                        isAddingFavourite: false
                    });
                }, 400);

                this.get('currentModel').send('becomeDirty');
            }
        }
    });
});