define('projecthelm/controllers/life-insurance/choose-cover', ['exports', 'projecthelm/controllers/life-insurance-quote', 'projecthelm/utils/forms', 'projecthelm/config/environment'], function (exports, _lifeInsuranceQuote, _forms, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var calculatorUrl = _environment.default.theme.calculatorUrl;
    var inject = Ember.inject;


    var validations = Ember.assign({
        'quote.acceptedTerms': {
            acceptance: { message: "Please accept the terms and conditions to proceed" }
        }
    }, _forms.lifeInsuranceValidations);

    exports.default = _lifeInsuranceQuote.default.extend({
        applicationController: inject.controller('application'),
        lifeInsuranceController: inject.controller('life-insurance'),
        currentUser: inject.service(),
        theme: inject.service(),
        tooltips: _forms.tooltips,
        isComplete: Ember.computed.alias('isValid'),
        calculatorResultsInput: Ember.computed.alias('applicationController.calculatorResultsInput'),
        progressiveDisclosureEnabled: Ember.computed(function () {

            return false;
        }),
        displayCoverAmounts: Ember.computed('progressiveDisclosureEnabled', 'calculatorResultsInput', {
            get: function get() {

                if (this.get('progressiveDisclosureEnabled')) {
                    return this.get('calculatorResultsInput');
                }

                return true;
            },
            set: function set(key, value) {
                return value;
            }
        }),

        validations: validations,

        init: function init() {
            this._super.apply(this, arguments);
            this.reset();
        },


        /**
         * Reset controller to default state
         *
         * @method _reset
         */
        reset: function reset() {
            this.setProperties({
                displayNoCoverMessage: false,
                displayErrors: false
            });
        },
        trackCoverInputs: function trackCoverInputs() {
            var _this = this;

            var tpdAdded = this.get('quote.tpdCover') && this.get('quote.tpdBenefit');
            var traumaAdded = this.get('quote.traumaCover') && this.get('quote.traumaBenefit');

            var stateObject = _forms.stateOptions.find(function (option) {
                return option.value === _this.get('quote.state');
            });
            var coverType = 'life cover only';
            coverType = tpdAdded ? 'life cover and added TPD' : coverType;
            coverType = traumaAdded ? 'life cover and added Trauma Cover' : coverType;
            coverType = tpdAdded && traumaAdded ? 'life cover and added TPD and Trauma Cover' : coverType;

            dataLayer.push({
                event: 'Cover Chosen',
                tpdInput: tpdAdded,
                traumaInput: traumaAdded,
                gender: this.get('quote.gender') === 'F' ? 'female' : 'male',
                age: this.get('quote.age'),
                state: stateObject.label,
                issmoking: this.get('quote.smoker') === 'N' ? 'No' : 'Yes',
                occupation: this.get('quote.occupation').value,
                covertype: coverType
            });
        },


        actions: {
            reset: function reset() {
                this.reset();
            },


            /**
             *
             * @method completeStep
             * @param  {string} redirect [route to enter following quote creation]
             */
            createQuote: function createQuote() {
                this.send('scrollToTop');
                this.trackCoverInputs();

                if (this.get('noCoverValue')) {
                    this.setProperties({
                        displayNoCoverMessage: true,
                        displayErrors: false
                    });
                } else if (this.get('isValid')) {
                    this.reset();
                    this.set('calculatorResultsInput', false);
                    return true;
                } else {
                    this.setProperties({
                        displayNoCoverMessage: false,
                        displayErrors: true
                    });
                }
            },


            /**
             * toggleCoverType
             *
             * @param  {string} coverType [property to be toggled]
             */
            toggleCoverType: function toggleCoverType(quote, coverType) {
                quote.toggleProperty(coverType);
            },
            toggleCoverAmounts: function toggleCoverAmounts() {
                this.toggleProperty('displayCoverAmounts', true);
            },
            redirectToCalculator: function redirectToCalculator() {
                var occupation = this.get('quote.occupation');
                var data = {
                    gender: this.get('quote.gender'),
                    age: this.get('quote.age'),
                    state: this.get('quote.state'),
                    smoker: this.get('quote.smoker'),
                    postcode: this.get('quote.postcode'),
                    occupation: occupation && occupation.reference
                };
                sessionStorage.setItem('comparisonData', JSON.stringify(data));
                window.location = calculatorUrl;
            }
        }

    });
});