define('projecthelm/components/quiz-answer', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            selectAnswer: function selectAnswer(answer) {
                this.get('onAnswer')(answer);
            }
        }
    });
});