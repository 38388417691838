define('projecthelm/utils/computed-properties', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.fallbackAction = fallbackAction;
    function fallbackAction(fallback) {
        return Ember.computed({
            get: function get() {
                return fallback.bind(this);
            },
            set: function set(_, v) {
                return v === undefined ? fallback.bind(this) : v;
            }
        });
    }
});