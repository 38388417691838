define('projecthelm/components/trust-pilot-carousel', ['exports', 'projecthelm/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed;
    exports.default = Ember.Component.extend({
        tagName: 'div',
        classNames: ['trustpilot-wrapper'],
        fade: false,
        theme: Ember.inject.service(),
        endpoint: _environment.default.marketingSiteApi,
        cfHeaders: _environment.default.cfHeaders,
        reviews: [],
        reviewsMeta: {},

        reviewStars: computed('reviewsMeta.rating', function () {
            var meta = this.get('reviewsMeta');
            var rating = meta.rating && Math.ceil(meta.rating);
            return Array.from({ length: Math.ceil(rating) }, function (_, i) {
                return i;
            });
        }),

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            var endpoint = this.get('endpoint');
            var cfHeaders = this.get('cfHeaders');
            try {
                var data = fetch(endpoint + '/reviews.json', {
                    method: 'GET',
                    headers: cfHeaders
                });

                data.then(function (res) {
                    return res.json();
                }).then(function (data) {
                    /* eslint-disable no-console */
                    console.log(data);
                    data.reviews.forEach(function (review) {
                        review.ratingArr = Array.from({ length: Math.ceil(review.rating) }, function (_, i) {
                            return i;
                        });
                    });
                    _this.set('reviews', data.reviews);
                    _this.set('reviewsMeta', data.meta);
                    console.log(data);
                    console.log(_this.get('reviewsMeta'));
                });
            } catch (error) {
                console.error(error);
            }
        },
        didUpdate: function didUpdate() {
            this.send('initCarousel');
        },


        actions: {
            initCarousel: function initCarousel() {
                console.log('initCarousel');
                var $el = Ember.$('.js-trustpilot');

                $el.owlCarousel({
                    loop: true,
                    dots: false,
                    mouseDrag: true,
                    nav: true,
                    navText: [],
                    responsive: {
                        0: {
                            center: true,
                            items: 1,
                            stagePadding: 20
                        },
                        850: {
                            items: 2,
                            stagePadding: 60
                        },
                        1100: {
                            items: 3,
                            stagePadding: 60
                        },
                        1500: {
                            items: 4,
                            stagePadding: 60
                        }
                    }
                });

                $el.on('click', '.owl-item', function () {

                    var $slide = Ember.$(this);

                    if ($slide.hasClass('active')) {
                        return;
                    }

                    // If next slide has active class we're user has clicked far left slide
                    if ($slide.next().hasClass('active')) {
                        $el.trigger('prev.owl.carousel');
                    } else {
                        $el.trigger('next.owl.carousel');
                    }
                });

                // Disable links for off screen items
                Ember.$('.js-trustpilot-item').on('click', function (e) {
                    e.preventDefault();
                    if (Ember.$(e.currentTarget).closest('.owl-item').hasClass('active')) {
                        window.open(e.currentTarget.href, '_blank');
                    }
                });
            }
        }
    });
});