define('projecthelm/models/feature-value', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = _emberData.default.Model.extend({
        value: attr(),
        type: attr('string'),
        ticked: attr('boolean', { defaultValue: false }),
        crossed: attr('boolean', { defaultValue: false }),
        feature: belongsTo('feature', {
            async: false
        })
    });
});