define('projecthelm/services/gtm', ['exports', 'lodash/string'], function (exports, _string) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        theme: Ember.inject.service(),
        currentUser: Ember.inject.service(),

        // Init data object with hardcoded values
        data: Ember.Object.create({
            ctmBrand: 'white-label-lifebroker'
        }),

        // Function for adding to the data object and making any changes needed
        setProps: function setProps(obj) {
            // If google analytics is present, add clientId to data object
            var gaClientId = null;
            if ('ga' in window) {
                window.ga(function (tracker) {
                    gaClientId = tracker.get('clientId');
                });
            }

            var userAnonymousId = sessionStorage.getItem("user_anonymous_id");

            // Remove quoteId from page URL
            if (obj.hasOwnProperty('pathName')) {
                var pageURL = _string.default.replace(obj.pathName, '/' + this.get('data.quoteId'), '');
                obj.pathName = pageURL;
            }

            this.get('data').setProperties(Ember.assign(obj, { gaClientId: gaClientId, userAnonymousId: userAnonymousId }));

            // If event key is present, push appropriate object to GTM
            if (obj.hasOwnProperty('event')) {

                switch (obj.event) {
                    case 'journey':
                        this.sendToCTM(this.eventJourney());
                        break;

                    case 'quoteResults':
                        this.sendToCTM(this.eventQuoteResults());
                        break;

                    case 'productClick':
                        this.sendToCTM(this.eventProductClick());
                        break;

                    case 'submitCallBack':
                        this.sendToCTM(this.eventSubmitCallBack());
                        break;
                }
            }
        },
        sendToCTM: function sendToCTM(data) {
            // eslint-disable-next-line no-console
            // console.log(data);
            window[this.get('theme.gtmDataLayer')].push(data);
            // Send to both dataLayers for CTM since they have two separate GTM containers....
            if (this.get('theme.gtmDataLayer') !== 'dataLayer') {
                window.dataLayer.push(data);
            }
        },


        // CTM's 4 data structures to be sent to GTM
        eventJourney: function eventJourney() {
            return {
                event: 'journey',
                ctmBrand: this.get('data.ctmBrand'),
                vertical: this.get('data.vertical'),
                sessionId: this.get('data.sessionId'),
                sessionStartTime: this.get('data.sessionStartTime'),
                hitTimeStamp: this.get('data.hitTimeStamp'),
                gaClientId: this.get('data.gaClientId'),
                user_anonymous_id: this.get('data.userAnonymousId'),
                prospectId: this.get('data.prospectId'),
                pathName: this.get('data.pathName'),
                pageName: this.get('data.pageName'),
                quoteId: this.get('data.quoteId'),
                form: {
                    person: this.get('data.person'),
                    address: this.get('data.address'),
                    cover: this.get('data.cover')
                }
            };
        },
        eventQuoteResults: function eventQuoteResults() {
            var occupation = this.get("currentUser.activeQuote.occupation");
            return {
                event: 'quoteResults',
                ctmBrand: this.get('data.ctmBrand'),
                vertical: this.get('data.vertical'),
                sessionId: this.get('data.sessionId'),
                sessionStartTime: this.get('data.sessionStartTime'),
                hitTimeStamp: this.get('data.hitTimeStamp'),
                gaClientId: this.get('data.gaClientId'),
                user_anonymous_id: this.get('data.userAnonymousId'),
                prospectId: this.get('data.prospectId'),
                pathName: this.get('data.pathName'),
                pageName: this.get('data.pageName'),
                quoteId: this.get('data.quoteId'),
                ecommerce: this.get('data.ecommerce'),
                gender: this.get("currentUser.activeQuote.gender") === 'M' ? 'Male' : 'Female',
                age: this.get("currentUser.activeQuote.age"),
                postcode: this.get("currentUser.activeQuote.postcode"),
                state: this.get("currentUser.activeQuote.state").toUpperCase(),
                smoking: this.get("currentUser.activeQuote.smoker") === 'N' ? 'No' : 'Yes',
                occupation: occupation && occupation.value,
                life_cover: this.get("currentUser.activeQuote.lifeBenefit"),
                tpd: this.get("currentUser.activeQuote.tpdBenefit"),
                trauma_cover: this.get("currentUser.activeQuote.traumaBenefit"),
                annual_income: this.get("currentUser.activeQuote.income"),
                monthly_benefit: this.get("currentUser.activeQuote.benefit")
            };
        },
        eventProductClick: function eventProductClick() {
            return {
                event: 'productClick',
                ctmBrand: this.get('data.ctmBrand'),
                vertical: this.get('data.vertical'),
                sessionId: this.get('data.sessionId'),
                sessionStartTime: this.get('data.sessionStartTime'),
                hitTimeStamp: this.get('data.hitTimeStamp'),
                gaClientId: this.get('data.gaClientId'),
                user_anonymous_id: this.get('data.userAnonymousId'),
                prospectId: this.get('data.prospectId'),
                pathName: this.get('data.pathName'),
                pageName: this.get('data.pageName'),
                quoteId: this.get('data.quoteId'),
                ecommerce: this.get('data.ecommerce')
            };
        },
        eventSubmitCallBack: function eventSubmitCallBack() {
            return {
                event: 'submitCallBack',
                ctmBrand: this.get('data.ctmBrand'),
                vertical: this.get('data.vertical'),
                sessionId: this.get('data.sessionId'),
                sessionStartTime: this.get('data.sessionStartTime'),
                hitTimeStamp: this.get('data.hitTimeStamp'),
                gaClientId: this.get('data.gaClientId'),
                user_anonymous_id: this.get('data.userAnonymousId'),
                prospectId: this.get('data.prospectId'),
                pathName: this.get('data.pathName'),
                pageName: this.get('data.pageName'),
                quoteId: this.get('data.quoteId'),
                submitCallBack: this.get('data.submitCallBack'),
                ecommerce: this.get('data.ecommerce')
            };
        }
    });
});