define('projecthelm/adapters/application', ['exports', 'ember-data', 'projecthelm/config/environment'], function (exports, _emberData, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed,
        inject = Ember.inject;
    var host = _environment.default.host;
    exports.default = _emberData.default.RESTAdapter.extend({
        host: host,
        theme: inject.service(),
        headers: computed('theme.apiUser', function () {
            return {
                'x-user': this.get('theme.apiUser')
            };
        }),
        namespace: 'api',
        shouldReloadAll: function shouldReloadAll() {
            return true;
        },
        shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
            return false;
        }
    });
});