define('projecthelm/utils/adobe-tracking', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var pageView = exports.pageView = function pageView() {
        dataLayer.push({
            'event': 'pageView',
            'brand': window.theme,
            'pageName': document.title, //i.e. Homepage
            'pageUrl': window.location.href //i.e. https://www.lifebroker.com.au/
        });
    };

    // Fire start event for iSelect & CTM only, since Lifebroker fires it when user clicks through from markeitng site. 
    if (window.theme !== 'Lifebroker') {
        dataLayer.push({
            'event': 'formStart',
            'brand': window.theme,
            'pageName': window.theme + ' Comparison',
            'clickSection': window.location.href.includes('life-insurance') ? 'Life Insurance' : 'Income Protection Insurance',
            'clickType': 'Comparison Funnel',
            'clickText': 'Compare', //e.g. “Compare”
            'clickUrl': window.location.href //e.g.  
        });
    }

    var stepOne = exports.stepOne = function stepOne(app) {
        dataLayer.push({
            'event': 'formStep',
            'brand': window.theme,
            'pageName': document.title, //e.g. “Lifebroker About You”
            'clickSection': 'Tell us about yourself', //e.g. “Tell us about yourself”
            'clickType': 'Form Step 1 Complete',
            'gender': app.get('gender') == 'M' ? 'Male' : 'Female', //e.g. “Male” or “Female”
            'age': app.get('age'), //e.g. “28”, “67” 
            'state': app.get('state').toUpperCase(), //e.g. “New South Wales”, “Victoria” 
            'postcode': app.get('postcode'), //e.g. “2077”, “2000” 
            'smoker': app.get('smoker') == 'N' ? 'No' : 'Yes', //e.g. “Yes” or “No” 
            'occupation': app.get('occupation').value, //e.g. “Agricultural Scientist” 
            'clickText': 'Next', //e.g. “Next”
            'clickUrl': window.location.href //e.g.  https://www.lifebroker.com.au/comparison/life-insurance/choose-cover
        });
    };

    var stepTwo = exports.stepTwo = function stepTwo(life, tpd, trauma, income, benefit) {
        dataLayer.push({
            'event': 'formConfirmation',
            'brand': window.theme,
            'pageName': document.title, //e.g. “Lifebroker Choose Cover”
            'clickSection': 'Choose your level of cover',
            'clickType': 'Form Complete',
            'lifeCover': life, //e.g. “$1,500,000”, “$900,000”
            'tpdCover': tpd, //e.g. “$400,000”, “$300,000” 
            'traumaCover': trauma, //e.g. “$150,000”, “$50,000” 
            'income': income, // IP path
            'benefit': benefit, // IP path
            'clickText': 'Compare Now', //e.g. “Compare Now”
            'clickUrl': window.location.href //e.g.  
        });
    };

    // Important - Update method call in code when editing this
    window.trackCall = function () {
        dataLayer.push({
            'event': 'clickToCall',
            'brand': window.theme,
            'pageName': document.title, //i.e. Homepage, “Contact Us | Lifebroker”
            'clickSection': 'Header', //e.g. “Header”, “Footer”, “Contact Us”  
            'clickType': 'Click to Call',
            'clickText': '13 54 33', //e.g. “13 54 33”
            'clickUrl': '13 54 33' //e.g.“tel:13 54 33”
        });
    };

    var complete = exports.complete = function complete(product) {
        dataLayer.push({
            'event': 'applicationSubmitted',
            'brand': window.theme,
            'pageName': document.title, //e.g. “Lifebroker compare products”  
            'clickSection': 'Confirm Details',
            'clickType': 'Application Submitted',
            'clickText': 'Submit', //e.g. “Submit”
            'product': product //e.g. “TAL Accelerated Protection”, “AIA Priority Protection”
        });
    };

    var gating = exports.gating = function gating() {
        dataLayer.push({
            'event': 'leadCapture',
            'brand': window.theme,
            'pageName': window.theme + ' Lead Capture',
            'clickSection': 'Pop up widget',
            'clickType': 'Submit Details',
            'clickText': 'Compare Policies Now', //e.g. “Compare policies now”
            'clickUrl': window.location.href //e.g.  
        });
    };

    var sortBy = exports.sortBy = function sortBy(sortOption) {
        // Don't tracking computed property firing initially on page load
        if (!window.sortFiredPageLoad) {
            window.sortFiredPageLoad = true;
            return;
        }
        dataLayer.push({
            'event': 'quoteFilter',
            'brand': window.theme,
            'pageName': document.title, //e.g. “Lifebroker compare products”  
            'clickSection': 'Quote Filter',
            'clickType': 'Quote Interaction',
            'clickText': 'Sort: ' + sortOption, //e.g. “View All”, “Sort: Claims Paid”, “Sort: Company Size”, “Previous Page (if < clicked)”, “Next Page (if > clicked)”, “Favorite (if <3 clicked)”, “Select”, “Shortlist”
            'product': '' //Capture when the user marks a product favorite or selects a product. E.g. “TAL Accelerated Protection”, “AIA Priority Protection”
        });
    };

    // Important - Update method call in code when editing this
    var trackToolbar = exports.trackToolbar = function trackToolbar(tab) {
        dataLayer.push({
            'event': 'quoteInteractions',
            'brand': window.theme,
            'pageName': document.title, //e.g. “Lifebroker compare products”  
            'clickSection': 'Quote Header',
            'clickType': 'Quote Interaction',
            'clickText': tab, //e.g. “View All”, “Sort: Claims Paid”, “Sort: Company Size”, “Previous Page (if < clicked)”, “Next Page (if > clicked)”, “Favorite (if <3 clicked)”, “Select”, “Shortlist”
            'product': '' //Capture when the user marks a product favorite or selects a product. E.g. “TAL Accelerated Protection”, “AIA Priority Protection”
        });
    };

    // Important - Update method call in code when editing this
    window.trackFilter = function (tab) {
        dataLayer.push({
            'event': 'quoteFilter',
            'brand': window.theme,
            'pageName': document.title, //e.g. “Lifebroker compare products”  
            'clickSection': 'Quote Filter',
            'clickType': 'Quote Interaction',
            'clickText': tab, //e.g. “View All”, “Sort: Claims Paid”, “Sort: Company Size”, “Previous Page (if < clicked)”, “Next Page (if > clicked)”, “Favorite (if <3 clicked)”, “Select”, “Shortlist”
            'product': '' //Capture when the user marks a product favorite or selects a product. E.g. “TAL Accelerated Protection”, “AIA Priority Protection”
        });
    };

    var addProduct = exports.addProduct = function addProduct(product) {
        dataLayer.push({
            'event': 'quoteFilter',
            'brand': window.theme,
            'pageName': document.title, //e.g. “Lifebroker compare products”  
            'clickSection': 'Quote Filter',
            'clickType': 'Quote Interaction',
            'clickText': 'Select', //e.g. “View All”, “Sort: Claims Paid”, “Sort: Company Size”, “Previous Page (if < clicked)”, “Next Page (if > clicked)”, “Favorite (if <3 clicked)”, “Select”, “Shortlist”
            'product': product //Capture when the user marks a product favorite or selects a product. E.g. “TAL Accelerated Protection”, “AIA Priority Protection”
        });
    };

    var saveQuote = exports.saveQuote = function saveQuote() {
        dataLayer.push({
            'event': 'quoteInteractions',
            'brand': window.theme,
            'pageName': document.title, //e.g. “Lifebroker compare products”  
            'clickSection': 'Quote Header',
            'clickType': 'Quote Interaction',
            'clickText': 'Save Quote', //e.g. “View All”, “Sort: Claims Paid”, “Sort: Company Size”, “Previous Page (if < clicked)”, “Next Page (if > clicked)”, “Favorite (if <3 clicked)”, “Select”, “Shortlist”
            'product': '' //Capture when the user marks a product favorite or selects a product. E.g. “TAL Accelerated Protection”, “AIA Priority Protection”
        });
    };

    var favourite = exports.favourite = function favourite(company, productName, _favourite) {
        dataLayer.push({
            'event': 'quoteFilter',
            'brand': window.theme,
            'pageName': document.title, //e.g. “Lifebroker compare products”  
            'clickSection': 'Quote Filter',
            'clickType': 'Quote Interaction',
            'clickText': _favourite ? 'Unfavorite' : 'Favorite', //e.g. “View All”, “Sort: Claims Paid”, “Sort: Company Size”, “Previous Page (if < clicked)”, “Next Page (if > clicked)”, “Favorite (if <3 clicked)”, “Select”, “Shortlist”
            'product': company + ' - ' + productName //Capture when the user marks a product favorite or selects a product. E.g. “TAL Accelerated Protection”, “AIA Priority Protection”
        });
    };

    var results = exports.results = function results() {
        dataLayer.push({
            'event': 'loadQuote',
            'brand': window.theme,
            'pageName': window.theme + ' compare products', //e.g. “Lifebroker compare products”, “Lifebroker no products”  
            'pageUrl': window.location.href //e.g.  
        });
    };

    var noResults = exports.noResults = function noResults(life, tpd, trauma, income, benefit) {
        // hardcode no-products URL since tracking happens before page transition
        var productType = window.location.href.includes('income') ? 'income-protection' : 'life-insurance';
        dataLayer.push({
            'event': 'loadQuote',
            'lifeCover': life, //life insurance path. e.g. “$1,500,000”, “$900,000”
            'tpdCover': tpd, //ife insurance path. e.g. “$400,000”, “$300,000” 
            'traumaCover': trauma, //ife insurance path. e.g. “$150,000”, “$50,000” 
            'income': income, // IP path
            'benefit': benefit, // IP path
            'brand': window.theme,
            'pageName': window.theme + ' no products',
            'pageUrl': window.location.origin + '/comparison/' + productType + '/no-products'
        });
    };
});