define('projecthelm/services/current-user', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend(Ember.Evented, {
        store: Ember.inject.service(),
        theme: Ember.inject.service(),
        gtm: Ember.inject.service(),
        router: Ember.inject.service(),
        flashMessages: Ember.inject.service(),
        prospectId: null,
        talApplyEnabled: undefined,

        init: function init() {
            var _this = this;

            this._super();

            this.setProperties({
                lifeQuote: this.get('store').createRecord('lifeQuote', { theme: this.get('theme.id') }),
                ipQuote: this.get('store').createRecord('ipQuote', { theme: this.get('theme.id') }),
                lead: this.get('store').createRecord('lead'),
                talApplyEnabled: localStorage.getItem('talApplyEnabled') === 'true'
            });

            if ('currentUser' in window) {
                this.setProperties(window.currentUser);
            }

            // Push fixed vars to gtm services
            this.get('gtm').setProps({
                sessionId: this.get('trackingData.sessionId'),
                sessionStartTime: this.get('trackingData.sessionStartTime')
            });

            Ember.$.ajax('https://api.ipify.org?format=json').then(function (response) {
                _this.set('ip', response.ip);
            });
        },


        ip: null,

        prospectToGtm: Ember.observer('prospectId', function () {
            this.get('gtm').setProps({ prospectId: this.get('prospectId') });
        }),

        // Needed to be triggered on init to start observing.
        // Check for null and loading state to avoid mutiple pushes to dataLayer
        pageToGtm: Ember.observer('router.currentRouteName', function () {
            if (this.get('router.currentRouteName') && this.get('router.currentRouteName') !== 'loading') {
                Ember.run.once(this, function () {
                    if (!this.get('gtm.vertical')) {
                        var currentRouteName = this.get('router.currentRouteName').split('.')[0];
                        this.get('gtm').setProps({
                            vertical: currentRouteName == 'income-protection' ? 'income' : 'life'
                        });
                    }

                    this.get('gtm').setProps({
                        event: 'journey',
                        pathName: this.get('router.currentURL'),
                        pageName: document.title,
                        hitTimeStamp: new Date()
                    });
                });
            }
        }).on('init'),

        personToGtm: Ember.observer('activeQuote.age', 'activeQuote.gender', 'activeQuote.state', 'lead.email', 'lead.name', 'lead.phone', function () {
            // Add quote info to gtm
            this.get('gtm').setProps({
                person: {
                    gender: this.get('activeQuote.gender'),
                    age: this.get('activeQuote.age'),
                    emailAddress: this.get('lead.email'),
                    name: this.get('lead.name'),
                    phone: this.get('lead.phone')
                },
                address: {
                    state: this.get('activeQuote.state'),
                    postcode: this.get('activeQuote.postcode')
                }
            });
        }),

        coverToGtm: Ember.observer('activeQuote.acceptedTerms', 'activeQuote.occupation', 'activeQuote.smoker', 'activeQuote.lifeBenefit', 'activeQuote.tpdBenefit', 'activeQuote.traumaBenefit', 'activeQuote.income', 'activeQuote.benefit', function () {
            // Add quote info to gtm
            this.get('gtm').setProps({
                cover: {
                    smoker: this.get('activeQuote.smoker'),
                    occupationId: this.get('activeQuote.occupation.id'),
                    occupationName: this.get('activeQuote.occupation.value'),
                    acceptedTerms: this.get('activeQuote.acceptedTerms'),
                    lifeCoverAmount: this.get('activeQuote.lifeBenefit'),
                    tpdCoverAmount: this.get('activeQuote.tpdBenefit'),
                    traumaCoverAmount: this.get('activeQuote.traumaBenefit'),
                    incomeAmount: this.get('activeQuote.income'),
                    benefitAmount: this.get('activeQuote.benefit')
                }
            });
        }),

        // CTM API
        viewString: Ember.computed('activeQuote.view', function () {
            switch (this.get('activeQuote.view')) {
                case "compare.simple.index":
                    return "Simple";
                case "compare.detailed.index":
                    return "Detailed";
                case "compare.simple.shortlist":
                    return "Simple-Shortlist";
                case "compare.detailed.shortlist":
                    return "Detailed-Shortlist";
                default:
                    return "Unknown";
            }
        }),
        coverString: Ember.computed('activeQuote.quoteType', 'activeQuote.lifeCover', 'activeQuote.tpdCover', 'activeQuote.traumaCover', function () {
            var _this2 = this;

            return this.get('activeQuote.quoteType') === 'ip' ? 'income' : ['life', 'tpd', 'trauma'].map(function (type) {
                return _this2.get('activeQuote.' + type + 'Cover') ? type : null;
            }).compact().join(';');
        }),

        trackingData: Ember.computed(function () {
            // window.clientId (and window.sessionId?) populated by LB GTM
            return Ember.merge({
                sessionId: window.sessionId || new Date().getTime() + '.' + Math.random().toString(36).substring(5),
                uaCid: window.clientId
            }, JSON.parse(sessionStorage.getItem('userTracker')));
        }).volatile(),

        /**
         * Submit lead
         * @param {object} properties - properties to be applied to the new lead
         * @param {object} options - are we gating or should we send an email
         */
        submitLead: function submitLead() {
            var _this3 = this;

            var properties = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
            var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};


            var lead = this.get('store').createRecord('lead', this.get('lead').toJSON({ includeId: false }));

            var quote = this.get('activeQuote');
            // if (quote) {
            //     lead.setProperties({
            //         lifeBenefit: quote.get('lifeBenefit'),
            //         tpdBenefit: quote.get('tpdBenefit'),
            //         traumaBenefit: quote.get('traumaBenefit'),
            //         income: quote.get('income'),
            //         benefit: quote.get('benefit'),
            //     });
            // }

            lead.setProperties(properties);
            lead.set('quote', quote);

            if (options.sendEmail) {
                lead.set('sendEmail', true);
            }

            if (options.gating) {
                quote.set('gatePassed', true);

                return quote.save().then(function () {
                    _this3.saveLead(lead);
                });
            }

            return this.saveLead(lead);
        },


        /**
         * Save lead to api
         * @param {lead model} lead - unsaved lead model
         * @description - Save new lead to the api and emit events for components to listen to
         */
        saveLead: function saveLead(lead) {
            var _this4 = this;

            this.trigger('leadSubmission', lead);
            lead.save().then(function () {
                _this4.set('lead', lead);
                _this4.trigger('leadSubmissionSuccess', lead);
            }).catch(function () {

                _this4.trigger('leadSubmissionFail', lead);

                var message = 'We’re sorry, something went wrong and we couldn’t process your comparison. To speak to a consultant, call 13 5433.';

                // Adjust the error message for brokers
                if (_this4.get('groups') && _this4.get('groups').includes('brokers')) {
                    message = 'We’re sorry, something went wrong and your referral wasn’t processed properly. Please try again or call 1300 304 964 for support.';
                }

                Ember.get(_this4, 'flashMessages').add({
                    message: message,
                    type: 'danger',
                    sticky: true
                });
            }).finally(function () {
                _this4.trigger('leadSubmissionComplete', lead);
            });
        }
    });
});