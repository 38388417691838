define('projecthelm/controllers/customise/policy-options/additional-options', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        customiser: Ember.inject.service(),
        activeQuote: Ember.computed.alias('customiser.activeQuote'),

        isLifeQuote: Ember.computed.equal('activeQuote.quoteType', 'life'),

        actions: {
            submit: function submit() {
                dataLayer.push({
                    event: 'applicationProgress',
                    eventAction: 'Step 6: Policy Options'
                });
                this.send('completeStep', this.get('step'));
                this.transitionToRoute('customise.summary');
            }
        }
    });
});