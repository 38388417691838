define('projecthelm/controllers/modals/feedback-complaints', ['exports', 'projecthelm/mixins/modal', 'projecthelm/controllers/formie-form'], function (exports, _modal, _formieForm) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _formieForm.default.extend(_modal.default, {
        formieFormHandle: 'feedbackComplaints',

        actions: {
            reset: function reset() {
                this._reset();
                this._super();
            },
            formSubmissionSuccess: function formSubmissionSuccess() {
                // Push event to GTM

                dataLayer.push({
                    event: 'submitModal',
                    name: 'feedbackComplaints'
                });
            }
        }
    });
});