define('projecthelm/controllers/customise/policy-options/type-of-premium', ['exports', 'ember-validations'], function (exports, _emberValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed,
        inject = Ember.inject;
    exports.default = Ember.Controller.extend(_emberValidations.default, {
        currentUser: inject.service(),
        customiser: inject.service(),
        activeQuote: computed.alias('currentUser.activeQuote'),

        validations: {
            'activeQuote.premiumType': {
                presence: { message: 'This field is required' }
            }
        },

        actions: {
            submit: function submit() {
                if (this.get('isValid')) {
                    this.send('completeStep', this.get('step'));
                } else {
                    this.get('displayErrors', true);
                }
                this.send('goToNextStep');
            }
        }
    });
});