define('projecthelm/breakpoints', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        palm: '(max-width: 758px)',
        lap: '(min-width: 759px) and (max-width: 1023px)',
        desk: '(min-width: 1024px)',
        jumbo: '(min-width: 1450px)'
    };
});