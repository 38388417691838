define('projecthelm/models/product', ['exports', 'ember-data', 'projecthelm/config/environment'], function (exports, _emberData, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed,
        inject = Ember.inject;
    var attr = _emberData.default.attr,
        hasMany = _emberData.default.hasMany;


    var suppliersEligibleForPurchase = [];
    var suppliersEligibleForFasttrack = [];

    exports.default = _emberData.default.Model.extend({
        theme: Ember.inject.service(),
        currentUser: inject.service(),
        quote: computed.alias('currentUser.activeQuote'),

        company: attr('string'),
        name: attr('string'),
        supplier_code: attr('string'),
        range: attr('string'),
        description: attr('string'),
        rating: attr('number'),
        pds: attr('string'),
        info: attr('string'),
        value: attr('number'),
        below_min: attr('string'),
        policy: attr('string'),
        link: attr('string'),
        featureValues: hasMany('feature-value', {
            async: false
        }),

        /**
         * Rounded value
         *
         * @method valueRounded
         * @returns {int}
         * @public
        */
        valueRounded: computed('value', function () {
            return this.get('value').toFixed(2);
        }).readOnly(),

        /**
         * Split dollars from product value for templating
         *
         * @method valueCents
         * @returns {string}
         * @public
        */
        valueDollars: computed('valueRounded', function () {
            return String(this.get('valueRounded')).split('.')[0];
        }).readOnly(),

        /**
         * Split cents from product value for templating
         *
         * @method valueCents
         * @returns {string}
         * @public
        */
        valueCents: computed('valueRounded', function () {
            return String(this.get('valueRounded')).split('.')[1];
        }).readOnly(),

        /**
         * @method unformattedCompany
         * @returns {string}
         * @public
        */
        unformattedCompanyName: computed('company', function () {
            return this.get('company').dasherize().split('-')[0];
        }).readOnly(),

        /**
         * Array of available company logos
         */
        availableCompanyLogos: attr('', {
            defaultValue: function defaultValue() {
                return ['aia', 'amp', 'asteron', 'bt', 'comm', 'macquarie', 'met', 'mlc', 'one', 'tal', 'zurich'];
            }
        }),

        /**
         * Check available company logos to see if product logo available
         *
         * @method hasCompanyLogo
         * @returns {boolean}
         * @public
         */
        hasCompanyLogo: computed('unformattedCompanyName', function () {
            return this.get('availableCompanyLogos').includes(this.get('unformattedCompanyName'));
        }).readOnly(),

        /**
         * @method companyLogoFolder
         * @returns {string} filepath to provider logo folder
         * @public
         */
        companyLogoFolder: _environment.default.APP.apppath + 'assets/images/provider-logos',

        /**
         * Generate Filepath for large company logo based on company name
         *
         * @method companyLogoLarge
         * @returns {string} logo URL
         * @public
        */
        companyLogoLarge: computed('company', function () {
            return this.get('companyLogoFolder') + '/large/' + this.get('unformattedCompanyName') + '.jpg';
        }).readOnly(),

        /**
         * Return Filepath for small company logo based on company name
         *
         * @method companyLogoSmall
         * @returns {string} logo URL
         * @public
        */
        companyLogoSmall: computed('company', function () {
            return this.get('companyLogoFolder') + '/small/' + this.get('unformattedCompanyName') + '.jpg';
        }).readOnly(),

        /**
         * @method spRating
         */
        spRating: computed('featureValues', function () {
            var featureValue = this.get('featureValues').findBy('feature.id', '4');
            return featureValue ? featureValue.get('value') : null;
        }).readOnly(),

        /**
         * Covert financialRating to a numeric value for sorting purposes
         *
         * @method financialRatingNumeric
         * @returns {int} financialRating as a number
         * @public
        */
        spRatingNumeric: computed('spRating', function () {

            var spRating = this.get('spRating');

            if (spRating) {

                var lookup = [{ label: "AAA", value: 100 }, { label: "AA+", value: 95 }, { label: "AA", value: 90 }, { label: "AA-", value: 84 }, { label: "A+", value: 77 }, { label: "A", value: 70 }, { label: "A-", value: 61 }, { label: "BBB+", value: 50 }, { label: "BBB", value: 40 }, { label: "BBB-", value: 30 }, { label: "BB", value: 10 }, { label: "B", value: 0 }, { label: "CCC", value: 0 }];

                var rating = lookup.find(function (rating) {
                    if (rating['label'] === spRating) {
                        return rating;
                    }
                });

                if (rating) {
                    return rating.value;
                }
            }
        }).readOnly(),

        /**
         * Save Company Size to product model for sorting
         *
         * @method companySize
         * @returns {string} company size
         * @public
        */
        size: computed('featureValues', function () {
            return this.get('featureValues').findBy('feature.id', '2');
        }).readOnly(),

        /**
         * Return ordinal suffix based on company size
         *
         * @method companySize
         * @returns {string} humanised company size ordinal suffix
         * @public
        */
        ordinalSuffix: computed('size', function () {

            var featureValue = this.get('size'),
                size,
                lastDigit,
                suffix;

            if (!featureValue) {
                return null;
            }

            size = featureValue.get('value');
            lastDigit = size % 10;

            if (size > 3 && size < 21) {
                suffix = 'th';
            } else if (lastDigit === 1) {
                suffix = 'st';
            } else if (lastDigit === 2) {
                suffix = 'nd';
            } else if (lastDigit === 3) {
                suffix = 'rd';
            } else {
                suffix = 'th';
            }

            return suffix;
        }).readOnly(),

        /**
         * Returns unformatted claims paid for sorting
        */
        claimsPaid: computed('featureValues', function () {
            return this.get('featureValues').findBy('feature.id', '3').get('value');
        }).readOnly(),

        /**
         * Return formatted claims paid
         *
         * @method formattedClaimsPaid
         * @returns {string} humanised claims paid rounded to millions or billions
         * @public
        */
        formattedClaimsPaid: computed('featureValues', function () {
            var featureValue = this.get('featureValues').findBy('feature.id', '3'),
                actualValue;

            if (featureValue) {
                actualValue = featureValue.get('value');
                if (parseInt(actualValue) / 1000000000 >= 1) {
                    return Number((parseInt(actualValue) / 1000000000).toFixed(2));
                } else {
                    return Number((parseInt(actualValue) / 1000000).toFixed(0));
                }
            } else {
                return null;
            }
        }).readOnly(),

        /**
         * Return the unit to be used in conjuction with formattedClaimsPaid
         *
         * @method formattedClaimsPaidUnit
         * @returns {string} the letter 'M' for millions, the letter 'B' for billions
         * @public
        */
        formattedClaimsPaidUnit: computed('featureValues', function () {

            var featureValue = this.get('featureValues').findBy('feature.id', '3'),
                actualValue;

            if (featureValue) {
                actualValue = featureValue.get('value');
                if (Math.round(parseInt(actualValue)) / 1000000000 >= 1) {
                    return 'b';
                } else {
                    return 'm';
                }
            } else {
                return null;
            }
        }).readOnly(),

        /**
        *
        * @method suicideTimeframe
        * @returns {string} Standard exclusion for suicide
        * @public
        */
        suicideTimeframe: computed('featureValues', function () {
            var featureValue = this.get('featureValues').findBy('feature.id', '6');
            return featureValue ? featureValue.get('value') : 'Suicide within first 13 months';
        }).readOnly(),

        /**
         *
         * @method isPurchasable
         * @returns {boolean}
         * @public
        */
        isPurchasable: computed('supplier_code', function () {
            return this.get('theme.onlinePurchaseEnabled') && suppliersEligibleForPurchase.includes(this.get('supplier_code'));
        }),

        /**
         *
         * @method isFasttrack
         * @returns {boolean}
         * @public
        */
        isFasttrack: computed('supplier_code', function () {
            return this.get('theme.onlinePurchaseEnabled') && suppliersEligibleForFasttrack.includes(this.get('supplier_code'));
        }),

        applyOnTalSite: computed('supplier_code', 'quote.age', 'quote.lifeBenefit', 'quote.tpdBenefit', 'quote.traumaBenefit', 'quote.tpdCover', 'quote.traumaCover', 'quote.benefit', 'quote.income', 'currentUser.talApplyEnabled', function () {
            var isLifeQuote = this.get('quote.quoteType') === 'life';
            var cutOffAge = isLifeQuote && !this.get('quote.tpdCover') && !this.get('quote.traumaCover') ? 62 : 55;

            // Only allow users to apply on TAL site after the lead API confirms their email is valid
            var apiVerified = this.get('currentUser.talApplyEnabled');
            var lifeCoverCutoff = 3000000;
            var incomeCoverCutoff = 342000;

            var excluded = this.get('quote.income') > incomeCoverCutoff || this.get('quote.age') > cutOffAge || this.get('quote.lifeBenefit') > lifeCoverCutoff || this.get('quote.tpdBenefit') > 1200000 || this.get('quote.traumaBenefit') > 500000;

            return this.get('supplier_code') === 'ACC' && !excluded && apiVerified;
        }).readOnly()
    });
});