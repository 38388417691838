define('projecthelm/services/customiser', ['exports', 'projecthelm/data/customiser'], function (exports, _customiser) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed;


    var Section = Ember.Object.extend({
        id: null,
        name: null,
        route: null,
        hasBeenSkipped: false,
        steps: [],
        enabledSteps: computed.filterBy('steps', 'isEnabled', true).readOnly(),
        totalSteps: computed('enabledSteps.[]', function () {
            return Math.max(1, this.get('enabledSteps.length'));
        }).readOnly(),
        completedSteps: computed.filterBy('enabledSteps', 'isComplete', true).readOnly(),
        totalCompletedSteps: computed('completedSteps.[]', 'isComplete', function () {
            if (this.get('isComplete')) {
                return this.get('totalSteps');
            } else {
                return this.get('completedSteps.length');
            }
        }).readOnly(),
        isComplete: computed.empty('pendingSteps'),
        pendingSteps: computed.filterBy('enabledSteps', 'isComplete', false).readOnly(),
        skippedSteps: computed.filterBy('enabledSteps', 'hasBeenSkipped', true).readOnly(),
        hasErrors: computed('hasBeenSkipped', 'skippedSteps.length', function () {
            return this.get('hasBeenSkipped') || this.get('skippedSteps.length');
        }).readOnly()
    });

    var Step = Ember.Object.extend({
        id: null,
        name: null,
        route: null,
        isEnabled: true,
        isComplete: false,
        errors: [],
        hasErrors: computed.bool('errors.length').readOnly()
    });

    function isStepDisabled(quote, step) {
        // Disable step if quote types of step and quote doesn't match.
        // No quote type specificed in step will not be disabled.
        // OR if step is flagged as tpdOnly but tpdCover is not selected in quote
        return step.get('quoteType') && step.get('quoteType') !== quote.get('quoteType') || step.get('tpdOnly') && !quote.get('tpdCover');
    }

    exports.default = Ember.Service.extend({
        assessment: {},
        currentUser: Ember.inject.service(),
        activeQuote: computed.readOnly('currentUser.activeQuote'),

        sections: computed('activeQuote', function () {
            var _this = this;

            return _customiser.sections.map(function (section) {
                section.steps = section.steps.map(function (step) {
                    step = Step.create(step);
                    if (isStepDisabled(_this.get('activeQuote'), step)) {
                        step.set('isEnabled', false);
                    }

                    return step;
                });
                return Section.create(section);
            });
        }),

        currentStep: null,
        isLoading: false,

        nextStep: computed('currentSection', 'currentStep', function () {
            var steps = this.get('currentSection.enabledSteps');
            var nextStep = steps.indexOf(this.get('currentStep')) + 1;
            return steps.objectAt(nextStep);
        }),

        completedSections: computed.filterBy('sections', 'isComplete', true).readOnly(),
        pendingSections: computed.filterBy('sections', 'isComplete', false).readOnly(),
        isComplete: computed.empty('pendingSections').readOnly(),
        stepCounts: computed.mapBy('sections', 'totalSteps').readOnly(),
        totalSteps: computed.sum('stepCounts').readOnly(),
        completedStepCounts: computed.mapBy('sections', 'totalCompletedSteps').readOnly(),
        totalCompletedSteps: computed.sum('completedStepCounts').readOnly(),
        percentageComplete: computed('totalSteps', 'totalCompletedSteps', function () {
            return Math.round(this.get('totalCompletedSteps') / this.get('totalSteps') * 100);
        }).readOnly()

    });
});