define('projecthelm/routes/quote', ['exports', 'projecthelm/utils/iselect-tracking', 'projecthelm/utils/adobe-tracking'], function (exports, _iselectTracking, _adobeTracking) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed,
        inject = Ember.inject;
    exports.default = Ember.Route.extend({
        currentUser: inject.service(),
        theme: inject.service(),

        model: function model() {
            return this.get('currentUser.' + this.get('quoteType').camelize());
        },
        afterModel: function afterModel(quote) {
            this.get('currentUser').set('activeQuote', quote);
        },


        quoteType: '',

        classifiedQuoteType: computed('quoteType', function () {
            return this.get('quoteType').classify();
        }),

        quoteTypeRoute: '',

        quoteRetrievalFailure: function quoteRetrievalFailure(error) {
            if (Ember.$.isEmptyObject(error.errors)) {
                (0, _iselectTracking.trackNoResults)();
                _adobeTracking.noResults();
                window.dataLayer.push({
                    event: 'no products found'
                });
                this.replaceWith(this.get('quoteTypeRoute') + '.no-products');
            } else {
                this.replaceWith('error500');
                Ember.get(this, 'flashMessages').add({
                    message: 'There has been an issue retrieving your quote. To speak to a consultant, call 13 5433.',
                    type: 'danger'
                });
            }
        },


        actions: {
            createThirdPartyQuote: function createThirdPartyQuote() {

                var newQuote = this.store.createRecord(this.get('quoteType'), {
                    thirdParty: true
                });

                this.get('currentUser').set(this.get('quoteType').camelize(), newQuote);

                dataLayer.push({
                    'event': 'getPartnerQuote'
                });

                this.transitionTo(this.get('quoteTypeRoute'));
            },
            createQuote: function createQuote(quote, redirect) {
                var _this = this;

                Ember.assert('You must specify a model and redirect.', !!quote && !!redirect);

                var applicationController = this.controllerFor('application');
                quote.set('view', redirect);
                applicationController.set('quoteProcessing', true);
                this.intermediateTransitionTo('loading');

                quote.save().then(function (quote) {
                    _this.transitionTo(_this.get('quoteTypeRoute') + '.compare', quote);
                }).catch(function (error) {
                    _this.quoteRetrievalFailure(error);
                }).finally(function () {
                    applicationController.set('quoteProcessing', false);
                });
            },
            editQuote: function editQuote() {
                this.transitionTo(this.get('quoteTypeRoute'));
            },


            /**
             * @method replaceQuote
             * @param {object} quote model
             *
             */
            replaceQuote: function replaceQuote(quote) {
                var _this2 = this;

                Ember.assert('You must specify a model.', !!quote);

                var applicationController = this.controllerFor('application');

                if (applicationController.get('quoteProcessing')) {
                    return;
                }

                this.intermediateTransitionTo('loading');
                applicationController.set('quoteProcessing', true);

                quote.save().then(function (model) {

                    if (!Ember.isEmpty(model.get('favouriteProducts'))) {
                        Ember.get(_this2, 'flashMessages').add({
                            message: 'Now that you’ve changed your cover amount, the prices displayed in your shortlist have been updated too',
                            type: 'info'
                        });
                    }

                    _this2.transitionTo(_this2.get('quoteTypeRoute') + '.compare', model);
                }).catch(function (error) {
                    _this2.quoteRetrievalFailure(error);
                }).finally(function () {
                    applicationController.set('quoteProcessing', false);
                });
            }
        }
    });
});