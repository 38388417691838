define('projecthelm/controllers/life-insurance/edit', ['exports', 'projecthelm/controllers/life-insurance-quote', 'projecthelm/utils/forms'], function (exports, _lifeInsuranceQuote, _forms) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var inject = Ember.inject;
    exports.default = _lifeInsuranceQuote.default.extend({
        applicationController: inject.controller('application'),
        tooltips: _forms.tooltips,

        calculatorResultsInput: Ember.computed.alias('applicationController.calculatorResultsInput'),
        currentRoute: Ember.computed.reads('applicationController.currentRouteName'),

        validations: _forms.lifeInsuranceValidations,

        actions: {
            reset: function reset() {

                if (this.get('model')) {
                    this.get('model').rollbackAttributes();
                }

                this.setProperties({
                    displayNoCoverMessage: false,
                    displayErrors: false
                });
            },
            confirmExit: function confirmExit() {
                var quote = this.get('model');

                if (quote.get('hasDirtyAttributes') || !quote.get('leadCreated')) {
                    this.send('openModal', 'saveBeforeNewQuote', quote);
                } else {
                    this.send('createThirdPartyQuote');
                }
            },


            /**
             *
             * @method updateCoverTriggered
             *
             */
            updateCoverTriggered: function updateCoverTriggered() {

                if (this.get('coverHasNotChange')) {
                    return;
                }

                if (this.get('noCoverValue')) {

                    this.setProperties({
                        displayNoCoverMessage: true,
                        displayErrors: false
                    });
                } else if (this.get('isValid')) {

                    if (this.get('hasCoverTypeChanged') && this.get('hasShortlist')) {
                        this.send('openModal', 'displayCoverHasChanged', this.get('model'));
                        return;
                    }

                    this.send('replaceQuote', this.get('model'));

                    this.setProperties({
                        displayNoCoverMessage: false,
                        displayErrors: false
                    });
                } else {
                    this.setProperties({
                        displayNoCoverMessage: false,
                        displayErrors: true
                    });
                }
            }
        }
    });
});