define('projecthelm/routes/comparison', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model() {
            var occupationsAvailable = 'occupations' in window;

            if (!occupationsAvailable) {
                return this.get('store').findAll('occupation');
            }
        }
    });
});