define('projecthelm/routes/income-protection/compare/detailed/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        controllerName: 'income-protection.compare',

        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('shortlist', false);
        }
    });
});