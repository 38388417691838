define('projecthelm/routes/customise/policy-options', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        customiser: Ember.inject.service(),

        model: function model() {
            return this.get('customiser.sections').findBy('id', 4);
        },
        afterModel: function afterModel(model) {
            this.get('customiser').set('currentSection', model);
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('section', model);
        }
    });
});