define('projecthelm/models/ip-quote', ['exports', 'projecthelm/models/quote', 'ember-data'], function (exports, _quote, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr;


    var IpQuote = _quote.default.extend({

        quoteType: attr('string', {
            defaultValue: 'ip'
        }),

        income: attr('number'),

        benefit: attr('number'),

        benefitPeriod: attr('number', {
            defaultValue: 2
        }),

        waitPeriod: attr('number', {
            defaultValue: 90
        }),

        indemnity: attr('string', {
            defaultValue: 'I'
        }),

        increasingClaims: attr('boolean', { defaultValue: true }),

        secondaryQuote: attr('boolean', { defaultValue: false }),
        hasViewedSecondaryQuoteMessage: attr('boolean', { defaultValue: false })
    });

    exports.default = IpQuote;
});