define('projecthelm/routes/request-a-callback', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        theme: Ember.inject.service(),
        afterModel: function afterModel() {
            if (this.get('theme.id') === 'lifebroker') {
                document.title = 'Request a Call Back | Life Insurance | Lifebroker';
                document.querySelector('meta[name="description"]').setAttribute("content", 'Need help or advice with your life insurance? Schedule a call with our team at a time that suits you.');
            }
        }
    });
});