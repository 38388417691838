define('projecthelm/components/external-link', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'a',
        attributeBindings: ['href'],
        href: null,

        click: function click(event) {
            event.stopPropagation();
            event.preventDefault();
            window.open(this.get('href'));
            return false;
        }
    });
});