define('projecthelm/components/input-weight-height', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        options: {
            mask: '999',
            placeholder: '',
            rightAlign: false
        }
    });
});