define('projecthelm/routes/customise/important-information/standard-exclusions', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model() {
            var section = this.modelFor('customise.important-information');
            return section.get('steps').findBy('id', 3);
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('step', model);
        },
        deactivate: function deactivate() {
            this._super.apply(this, arguments);
            this.send('completeStep', this.controller.get('step'));
        }
    });
});