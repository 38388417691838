define('projecthelm/controllers/ip-insurance-quote', ['exports', 'projecthelm/controllers/quote', 'projecthelm/mixins/ip-cover-options', 'projecthelm/utils/forms'], function (exports, _quote, _ipCoverOptions, _forms) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed;
    var empty = computed.empty,
        not = computed.not,
        notEmpty = computed.notEmpty,
        lte = computed.lte;
    exports.default = _quote.default.extend(_ipCoverOptions.default, {
        eligibleForCover: lte('quote.age', 65).readOnly(),

        hasNoIncome: empty('quote.income'),
        hasBenefit: notEmpty('quote.benefit'),

        maxBenefit: computed('quote.income', function () {
            return (0, _forms.calculateMaxBenefit)(this.get('quote.income'));
        }),

        hasCoverChanged: computed('quote.benefit', 'quote.indemnity', 'quote.benefitPeriod', 'quote.waitPeriod', function () {

            var permissableChangedAttributes = ['benefit', 'indemnity', 'benefitPeriod', 'waitPeriod'];

            var changedAttributes = Object.keys(this.get('quote').changedAttributes());

            return changedAttributes.any(function (attribute) {
                return permissableChangedAttributes.includes(attribute);
            });
        }).readOnly(),

        coverHasNotChange: not('hasCoverChanged')

    });
});