define('projecthelm/routes/income-protection/choose-cover', ['exports', 'projecthelm/utils/iselect-tracking', 'projecthelm/utils/adobe-tracking'], function (exports, _iselectTracking, _adobeTracking) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var inject = Ember.inject;
    exports.default = Ember.Route.extend({

        currentUser: inject.service(),

        beforeModel: function beforeModel() {
            // Redirect to about you if it is invalid
            if (!this.controllerFor('income-protection.about-you').get('isValid')) {
                this.replaceWith('income-protection.about-you');
            }
        },
        model: function model() {
            return this.get('currentUser.ipQuote');
        },
        afterModel: function afterModel(model) {
            (0, _iselectTracking.trackNeeds2)(model, "income");
            _adobeTracking.stepOne(model, "income");
        },
        resetController: function resetController(controller, isExiting) {

            if (isExiting) {
                controller.send('reset');
            }
        }
    });
});