define("projecthelm/data/data", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        "config": {
            "providerName": "Lifebroker"
        }
    };
});