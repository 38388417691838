define('projecthelm/routes/quote/compare/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        /**
         * Split routeName to return parent route
         *
         * @method parentRoute
         * @returns {string} parent route
         */
        parentRoute: Ember.computed('routeName', function () {
            return this.routeName.split('.')[0];
        }),

        afterModel: function afterModel(model) {
            var view = model.get('view');

            if (Ember.isEmpty(model.get('favouriteProducts'))) {
                // Don't transition into shortlist when there are no favourite products
                // We've stripped the end of the view string to leave the parent route
                view = view.substring(0, view.lastIndexOf('.'));
            }

            this.transitionTo(this.get('parentRoute') + '.' + view, model);
        }
    });
});