define('projecthelm/routes/tal-calc-calculator/index', ['exports', 'tal-calculator/routes/tal-calc-calculator/index'], function (exports, _index) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _index.default;
    }
  });
});