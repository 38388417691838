define('projecthelm/components/owl-carousel', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'ul',
        classNames: ['owl-carousel'],
        fade: false,

        didInsertElement: function didInsertElement() {
            var options = {};

            if (this.get('fade')) {
                options = {
                    animateOut: "fadeOut",
                    autoplay: true,
                    autoplayHoverPause: true,
                    autoplayTimeout: 6000,
                    center: true,
                    items: 1,
                    loop: true
                };
            } else {
                options = {
                    autoplay: true,
                    autoplayHoverPause: true,
                    autoplaySpeed: 12000,
                    autoplayTimeout: 0,
                    autoWidth: true,
                    loop: true
                };
            }

            this.$().owlCarousel(options);
        },
        willDestroyElement: function willDestroyElement() {
            var carousel = this.$().owlCarousel();
            carousel.trigger('destroy.owl.carousel');
        }
    });
});