define('projecthelm/components/x-checkbox', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['checkbox'],
        classNameBindings: ['checked:checkbox--checked', 'disabled:is-disabled'],
        changed: null,

        change: function change() {
            if (this.get('changed')) this.sendAction('changed');
        }
    });
});