define('projecthelm/components/modal-dialog', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tabindex: 0,
        dialogue: false,
        isDialogue: Ember.computed.alias('dialogue'),
        classNames: ['modal', 'mfp-with-anim'],
        classNameBindings: ['isDialogue:modal--dialogue'],
        modal: Ember.inject.service(),
        animation: 'mfp-zoom-in',
        alignTop: false,

        attributeBindings: ['tabindex'],

        inserted: Ember.on('didInsertElement', function () {

            var self = this;

            Ember.$.extend(true, Ember.$.magnificPopup.defaults, {
                prependTo: '#modal',
                callbacks: {
                    afterClose: function afterClose() {
                        self.send('closeModal');
                        self.get('modal').cleanup();
                    }
                }
            });

            Ember.$.magnificPopup.open({
                closeMarkup: '<button title="%title%" class="mfp-close"></button>',
                mainClass: this.get('animation'),
                alignTop: this.get('alignTop'),
                removalDelay: 300,
                items: {
                    src: this.$(),
                    type: 'inline'
                },
                modal: this.get('isDialogue')
            });
        }),

        actions: {
            closeModal: function closeModal() {
                this.sendAction();
            }
        }
    });
});