define("projecthelm/transitions/fade-up", ["exports", "liquid-fire"], function (exports, _liquidFire) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = fadeUp;
  function fadeUp(oldView, insertNewView, opts) {
    (0, _liquidFire.stop)(oldView);

    return insertNewView().then(function (newView) {
      return _liquidFire.Promise.all([(0, _liquidFire.animate)(newView, {
        opacity: [1, 0],
        translateY: ["0%", "8%"]

      }, opts)]);
    });
  }
});