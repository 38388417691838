define('projecthelm/adapters/lead', ['exports', 'projecthelm/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        flashMessages: Ember.inject.service('flash-messages'),
        cookies: Ember.inject.service('cookies'),
        currentUser: Ember.inject.service(),

        handleResponse: function handleResponse(status, headers, payload, requestData) {
            this.get('currentUser').setProperties({
                talApplyEnabled: payload.talApplyEnabled
            });

            localStorage.setItem('talApplyEnabled', payload.talApplyEnabled);

            return this._super(status, headers, payload, requestData);
        }
    });
});