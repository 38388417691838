define('projecthelm/routes/customise/pre-assessment', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        customiser: Ember.inject.service(),

        model: function model() {
            return this.get('customiser.sections').findBy('id', 3);
        },
        afterModel: function afterModel(model) {
            this.get('customiser').set('currentSection', model);
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('section', model);
        },
        deactivate: function deactivate() {
            this._super.apply(this, arguments);

            if (this.controller.get('isInvalid')) {
                this.controller.set('displayErrors', true);
                this.send('skipSection', this.controller.get('section'));
            }
        }
    });
});