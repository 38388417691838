define('projecthelm/controllers/modals/need-help', ['exports', 'projecthelm/mixins/modal', 'projecthelm/controllers/lead', 'projecthelm/utils/forms'], function (exports, _modal, _lead, _forms) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed,
        inject = Ember.inject;
    var alias = computed.alias,
        equal = computed.equal,
        not = computed.not;


    var params = new URLSearchParams(window.location.search);

    exports.default = _lead.default.extend(_modal.default, {
        applicationController: inject.controller('application'),
        showLiveChat: alias('applicationController.showLiveChat'),
        tooltips: _forms.tooltips,
        requestCall: false,
        isNotPalm: not('media.isPalm'),
        iSelectData: params.get('iselect'),

        // Active states
        currentlyActive: 'requestcall',
        callnow: equal('currentlyActive', 'callnow'),
        requestcall: equal('currentlyActive', 'requestcall'),
        livechat: equal('currentlyActive', 'livechat'),
        needhelp: equal('currentlyActive', 'needhelp'),

        validations: {
            'lead.name': _forms.validation.name,
            'lead.email': _forms.validation.email,
            'lead.state': _forms.validation.state,
            'lead.phone': _forms.validation.phone,
            'callDay': _forms.validation.callDay,
            'callWindow': _forms.validation.callWindow
        },

        actions: {
            toggleNav: function toggleNav(item) {
                this.set('currentlyActive', item);
            },
            close: function close() {
                this._super();
                this.set('currentlyActive', 'callnow');
            },


            /**
             * Reset modal and show flash message when lead is submitted
             *
             * @method leadSubmission
             */
            leadSubmission: function leadSubmission() {
                this._super();
                this._reset();
            },


            /**
             * Reset modal and show flash message when lead is submitted
             *
             * @method leadSubmissionSuccess
             */
            leadSubmissionSuccess: function leadSubmissionSuccess() {
                this._super();

                Ember.get(this, 'flashMessages').add({
                    message: this.get('successMessage'),
                    type: 'success'
                });

                // Push event to GTM
                dataLayer.push({
                    'event': 'pillRequestCall'
                });

                dataLayer.push({
                    event: 'submitModal',
                    name: 'needHelp'
                });
            }
        }
    });
});