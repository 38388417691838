define('projecthelm/controllers/life-insurance-quote', ['exports', 'projecthelm/controllers/quote'], function (exports, _quote) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed;
    var not = computed.not,
        or = computed.or;
    exports.default = _quote.default.extend({

        /**
         * eligibleForLifeCover
         * @type {boolean}
         */
        eligibleForLifeCover: computed('quote.age', function () {
            return this.get('quote.age') === undefined || this.get('quote.age') <= 75;
        }),

        /**
         * eligibleForTpdCover
         * @type {boolean}
         */
        eligibleForTpdCover: computed('quote.age', function () {
            return this.get('quote.age') === undefined || this.get('quote.age') <= 75;
        }),

        /**
         * eligibleForTraumaCover
         * @type {boolean}
         */
        eligibleForTraumaCover: computed('quote.age', function () {
            return this.get('quote.age') === undefined || this.get('quote.age') <= 65;
        }),

        quoteAgeUndefined: computed('quote.age', function () {
            return this.get('quote.age') === undefined;
        }),

        /**
         * eligibleForCover
         * @type {boolean}
         */
        eligibleForCover: or('quoteAgeUndefined', 'eligibleForLifeCover', 'eligibleForTpdCover', 'eligibleForTraumaCover'),

        /**
         * eligibleForTraumaOrTpd
         * @type {boolean}
         */
        eligibleForTraumaOrTpd: or('quoteAgeUndefined', 'eligibleForTpdCover', 'eligibleForTraumaCover'),

        noCoverValue: computed('quote.lifeBenefit', 'quote.tpdBenefit', 'quote.traumaBenefit', function () {
            return !this.get('quote.lifeBenefit') && !this.get('quote.tpdBenefit') && !this.get('quote.traumaBenefit');
        }),

        /**
         * Check to see if only one cover type is selected.
         * Used in template to hide and/remove coverType button
         *
         * @method isLastCoverType
         *
         * @return {boolean} only one coverType selected
         */
        isLastCoverType: computed('quote.lifeCover', 'quote.traumaCover', 'quote.tpdCover', function () {

            var coverTypesSelected = [this.get('quote.lifeCover'), this.get('quote.tpdCover'), this.get('quote.traumaCover')].without(false);

            return coverTypesSelected.length <= 1;
        }),

        hasCoverChanged: computed('quote.lifeCover', 'quote.lifeBenefit', 'quote.tpdCover', 'quote.tpdBenefit', 'quote.traumaCover', 'quote.traumaBenefit', function () {

            var permissableChangedAttributes = ['lifeCover', 'lifeBenefit', 'tpdCover', 'tpdBenefit', 'traumaBenefit', 'traumaCover'],
                changedAttributes = Object.keys(this.get('quote').changedAttributes());

            return changedAttributes.any(function (attribute) {
                return permissableChangedAttributes.includes(attribute);
            });
        }).readOnly(),

        coverHasNotChange: not('hasCoverChanged'),

        hasCoverTypeChanged: computed('quote.lifeCover', 'quote.tpdCover', 'quote.traumaCover', function () {

            var permissableChangedAttributes = ['lifeCover', 'tpdCover', 'traumaCover'],
                changedAttributes = Object.keys(this.get('quote').changedAttributes());

            return changedAttributes.any(function (attribute) {
                return permissableChangedAttributes.includes(attribute);
            });
        }).readOnly()

    });
});