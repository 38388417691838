define('projecthelm/serializers/ip-quote', ['exports', 'projecthelm/serializers/application', 'ember-data'], function (exports, _application, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var inject = Ember.inject;
    exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
        currentUser: inject.service(),
        primaryKey: 'affiliateId',

        attrs: {
            products: { embedded: 'always', serialize: false },
            favouriteProducts: { serialize: 'ids', deserialize: 'ids' },
            categories: { embedded: 'always', serialize: false },
            features: { embedded: 'always', serialize: false },
            hasViewedSecondaryQuoteMessage: { serialize: false }
        },

        keyForRelationship: function keyForRelationship(key) {

            if (key === 'favouriteProducts') {
                key = 'favouriteProductIds';
            }

            return key;
        },


        /*
        * Remove product data
        * Add lead data
        */
        serialize: function serialize(snapshot, options) {
            var json = this._super(snapshot, options);
            var leadData = this.get('currentUser.lead').getProperties('name', 'email', 'phone');

            json = Object.assign(json, leadData);

            delete json.categories;
            delete json.products;
            delete json.features;

            return json;
        },
        normalizeQuote: function normalizeQuote(quote) {
            var _this = this;

            var normalizedQuote = Ember.assign({}, quote.details, quote);

            normalizedQuote.gatePassed = normalizedQuote.details.gatePassed;
            normalizedQuote.leadCreated = normalizedQuote.details.leadCreated;

            this.store.peekAll('feature').forEach(function (feature) {
                if (!normalizedQuote.details.features.isAny('id', parseInt(feature.id))) {
                    _this.store.unloadRecord(feature);
                }
            });

            delete normalizedQuote.details;

            return normalizedQuote;
        },
        normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
            var _this2 = this;

            if (Ember.isEmpty(payload)) {
                return payload;
            }

            var data = {
                ipQuotes: []
            };

            payload.quotes.forEach(function (quote) {
                data.ipQuotes.push(_this2.normalizeQuote(quote));
            });

            return this._super(store, primaryModelClass, data, id, requestType);
        },
        normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {

            if (Ember.isEmpty(payload)) {
                return payload;
            }

            var data = {
                ipQuote: this.normalizeQuote(payload)
            };

            return this._super(store, primaryModelClass, data, id, requestType);
        }
    });
});