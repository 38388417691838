define('projecthelm/components/apply-online', ['exports', 'ember-validations', 'projecthelm/utils/forms', 'projecthelm/utils/adobe-tracking'], function (exports, _emberValidations, _forms, _adobeTracking) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_emberValidations.default, {
        validations: {
            'lead.name': _forms.validation.name,
            'lead.email': _forms.validation.email,
            'lead.phone': _forms.validation.phone
        },

        actions: {
            submit: function submit() {
                if (!this.get('isValid')) {
                    this.set('displayErrors', true);
                    return;
                }

                _adobeTracking.complete(this.get('product').get('name') + ' - ' + this.get('product').get('company'));

                var lead = Ember.Object.create({
                    product: this.get('product'),
                    additional: {
                        progress: 'Apply'
                    }
                });

                this.get('onSubmit')(lead);
            }
        }

    });
});