define('projecthelm/components/cover-summary', ['exports', 'projecthelm/mixins/ip-cover-options'], function (exports, _ipCoverOptions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed;
    exports.default = Ember.Component.extend(_ipCoverOptions.default, {

        showSummary: computed('media.matches.[]', {
            get: function get() {
                return !this.get('media.isPalm');
            },
            set: function set(key, value) {
                return value;
            }
        }),

        formattedIndemnity: computed('quote.indemnity', function () {

            var lookup = this.get('indemnityOptions').findBy('value', this.get('quote.indemnity'));

            if (lookup) {
                return lookup.label;
            }
        }),

        formattedBenefitPeriod: computed('quote.benefitPeriod', function () {
            var lookup = this.get('benefitPeriodOptions').findBy('value', this.get('quote.benefitPeriod'));

            if (lookup) {
                return lookup.label;
            }
        }),

        formattedWaitPeriod: computed('quote.waitPeriod', function () {

            var lookup = this.get('waitPeriodOptions').findBy('value', this.get('quote.waitPeriod'));

            if (lookup) {
                return lookup.label;
            }
        }),

        actions: {
            toggleSummary: function toggleSummary() {
                this.toggleProperty('showSummary');
            }
        }
    });
});