define('projecthelm/components/product-card', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        isExpanded: false,

        actions: {
            toggleCard: function toggleCard() {
                this.toggleProperty('isExpanded');
            }
        }
    });
});