define('projecthelm/components/input-age', ['exports', 'projecthelm/components/input-mask'], function (exports, _inputMask) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _inputMask.default.extend({
        type: 'tel',
        autocomplete: 'off',
        options: {
            mask: '99',
            placeholder: '',
            rightAlign: false
        }
    });
});