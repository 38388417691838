define('projecthelm/routes/comparison/error404', ['exports', 'projecthelm/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var inject = Ember.inject;
    exports.default = Ember.Route.extend({
        theme: inject.service(),

        redirect: function redirect() {
            if (_environment.default.environment !== 'development') {
                var page = this.get('theme.id') == 'lifebroker' ? '/404' : '/error404';
                window.location = page;
            }
        }
    });
});