define('projecthelm/controllers/application', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed;
    exports.default = Ember.Controller.extend({
        activeQuote: undefined,
        activeLifeQuote: undefined,
        activeIpQuote: undefined,
        isComparing: false,
        quoteSaving: false,
        calculatorResultsInput: false,
        incomeResultsInput: false,
        deviceHasTouchCapability: !!('ontouchstart' in window),
        isPureTouchDevice: computed('deviceHasTouchCapability', function () {
            return this.get('deviceHasTouchCapability');
        }),

        /**
         * Show LiveChat based on agent availability and screen size
         * Uses third party API
         * @return {boolean}
         */
        showLiveChat: Ember.computed(function () {
            return false;
        })
    });
});