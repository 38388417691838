define('projecthelm/components/cta-sticky', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var inject = Ember.inject;


    var stateOptions = [{ value: 'act', label: 'ACT' }, { value: 'nsw', label: 'NSW' }, { value: 'nt', label: 'NT' }, { value: 'qld', label: 'QLD' }, { value: 'sa', label: 'SA' }, { value: 'tas', label: 'TAS' }, { value: 'vic', label: 'VIC' }, { value: 'wa', label: 'WA' }];

    var genderOptions = [{ value: 'F', label: 'female' }, { value: 'M', label: 'male' }];

    exports.default = Ember.Component.extend({
        cookies: inject.service(),
        genderOptions: genderOptions,
        stateOptions: stateOptions,

        init: function init() {
            this._super.apply(this, arguments);

            // default to vic
            this.set('state', 'vic');

            if (this.get('cookies').read('hasDismissedStickyCta') === undefined) {
                this.set('isVisible', true);
            }
        },


        isExpanded: Ember.computed('media.isDesk', {
            get: function get() {
                return this.get('media.isDesk');
            },
            set: function set(key, value) {
                return value;
            }
        }),

        dismiss: function dismiss() {
            this.get('cookies').write('hasDismissedStickyCta', '1', { path: '/' });
        },


        actions: {
            expand: function expand() {
                this.set('isExpanded', true);
            },
            handleSubmit: function handleSubmit() {

                dataLayer.push({
                    event: 'stickyCtaSubmit'
                });

                this.dismiss();
                var params = this.$('form').serialize();
                window.location = '/comparison/life-insurance?' + params;
            },
            close: function close() {
                this.dismiss();
                this.set('isHidden', true);
            }
        }
    });
});