define('projecthelm/components/radio-button-input', ['exports', 'ember-radio-button/components/radio-button-input'], function (exports, _radioButtonInput) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _radioButtonInput.default.extend({
        classNames: ['radio-button'],

        click: function click() {
            var _this = this;

            Ember.run.scheduleOnce('afterRender', function () {

                if (_this.get('groupValue') === _this.get('value')) {
                    Ember.run.once(_this, 'sendChangedAction');
                }
            });
        }
    });
});