define('projecthelm/controllers/modals/display-cover-has-changed', ['exports', 'projecthelm/mixins/modal'], function (exports, _modal) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_modal.default, {
        actions: {
            resetQuote: function resetQuote() {
                this.send('resetEditController');
                this.send('closeModal');
            },
            notify: function notify() {
                this.send('replaceQuote', this.get('model'));
                this.send('closeModal');
            }
        }
    });
});