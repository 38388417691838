define('projecthelm/components/feature-value', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed;
    var equal = computed.equal,
        or = computed.or,
        not = computed.not;
    exports.default = Ember.Component.extend({
        isSize: equal('featureValue.feature.id', "2").readOnly(),
        isClaimsPaid: equal('featureValue.feature.id', "3").readOnly(),
        isStarRating: equal('featureValue.type', 'star').readOnly(),
        isSpecialValue: or('isSize', 'isClaimsPaid', 'isStarRating').readOnly(),
        isNotSpecialValue: not('isSpecialValue').readOnly(),
        starKlass: computed('featureValue.type', function () {
            return 'star-rating--' + this.get('featureValue.value').toString().replace(".", "-");
        }).readOnly(),

        featureValue: computed('feature', 'product', function () {
            var featureValue = this.get('product.featureValues').findBy('feature', this.get('feature'));

            if (featureValue) {
                return featureValue;
            } else {
                return false;
            }
        }).readOnly(),

        /**
         * Check boolean property of featureValue to check if the
         * feature has a tick associated with it
         *
         * @method isTicked
         * @return {boolean}
         */
        isTicked: computed('featureValue', function () {
            var featureValue = this.get('featureValue');
            return featureValue ? featureValue.get('ticked') : false;
        }).readOnly(),

        /**
         * Check boolean property of featureValue to check if the
         * feature has a cross associated with it
         *
         * @method isCrossed
         * @return {boolean}
         */
        isCrossed: computed('featureValue', function () {
            var featureValue = this.get('featureValue');
            return featureValue ? featureValue.get('crossed') : false;
        }).readOnly(),

        /**
         * Return default feature value unless we're on claims paid
         *
         * @method value
         * @return {string} feature value text
         */
        value: computed('featureValue', function () {
            var featureValue = this.get('featureValue');

            if (this.get('isClaimsPaid')) {
                return this.get('product.formattedClaimsPaid');
            } else {
                return featureValue ? featureValue.get('value') : null;
            }
        }).readOnly()

    });
});