define('projecthelm/controllers/formie-form', ['exports', 'ember-validations', 'projecthelm/utils/forms', 'projecthelm/config/environment'], function (exports, _emberValidations, _forms, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var phoneQuote = _environment.default.theme.phoneQuote;
    exports.default = Ember.Controller.extend(_emberValidations.default, Ember.Evented, {
        displayErrors: false,
        isProcessing: false,
        formSubmitted: false,
        formieFormHandle: undefined,

        validations: {
            'question': {
                presence: { message: "Please enter your question" }
            },
            'customerName': _forms.validation.name,
            'email': _forms.validation.email
        },

        _reset: function _reset() {
            this.setProperties({
                formSubmitted: false,
                displayErrors: false
            });
        },
        getFields: function getFields() {
            return this.getProperties('question', 'email', 'state', 'customerName', 'phone');
        },


        actions: {
            createSubmission: function createSubmission() {
                var _this = this;

                if (!this.get('isValid')) {
                    this.set('displayErrors', true);
                    return;
                }

                this.set('isProcessing', true);
                this.send('formSubmission');

                var formData = new FormData();
                Object.keys(this.getFields()).forEach(function (key) {
                    return formData.append('fields[' + key + ']', _this.getFields()[key]);
                });
                formData.append('action', 'formie/submissions/submit');
                formData.append('handle', this.get('formieFormHandle'));
                formData.append(window.csrfTokenName, window.csrfTokenValue);

                fetch('/', {
                    method: 'POST',
                    credentials: 'same-origin',
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        Accept: 'application/json'
                    },
                    body: formData
                }).then(function () {
                    _this.send('formSubmissionSuccess');
                }).catch(function (error) {
                    _this.send('formSubmissionFail', error);
                }).finally(function () {
                    _this.set('isProcessing', false);
                });
            },
            formSubmission: function formSubmission() {
                this.set('formSubmitted', true);
            },
            formSubmissionFail: function formSubmissionFail(error) {
                this._reset();

                Ember.get(this, 'flashMessages').add({
                    message: 'We\'re sorry, something went wrong and we couldn\'t process your question. To speak to a consultant, call ' + phoneQuote + '.',
                    type: 'danger',
                    sticky: true
                });

                this.send('error', error);
            },
            formSubmissionSuccess: function formSubmissionSuccess() {}
        }
    });
});