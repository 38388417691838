define('projecthelm/models/quote', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr,
        hasMany = _emberData.default.hasMany;
    var computed = Ember.computed;
    exports.default = _emberData.default.Model.extend({
        affiliateId: attr('string'),
        name: attr('string'),
        email: attr('string'),
        phone: attr('string'),
        gender: attr('string'),
        age: attr('number'),
        state: attr('string'),
        postcode: attr('string'),
        occupation: attr('occupation'),
        smoker: attr('string'),
        acceptedTerms: attr('boolean'),
        leadCreated: attr('boolean', { defaultValue: false }),
        gatePassed: attr('boolean'),
        expiryDate: attr('date'),
        sortBy: attr('string', { defaultValue: 'value:asc' }),
        view: attr('string', { defaultValue: 'compare.simple' }),
        thirdParty: attr('boolean', { defaultValue: false }),
        memberNumber: attr('string'),
        additional: attr('', {
            defaultValue: function defaultValue() {
                return {
                    'progress': 'Start'
                };
            }
        }),

        occupationName: computed('occupation', function () {
            return this.get('occupation.value');
        }).readOnly(),

        // Premium type (S or L)
        premiumType: attr('string', {
            defaultValue: null
        }),

        frequency: attr('string', {
            defaultValue: 'M'
        }),

        theme: attr('string', { defaultValue: 'lifebroker' }),

        // Relationships
        products: hasMany('products', {
            async: false
        }),

        favouriteProducts: hasMany('products', {
            async: false
        }),

        categories: hasMany('category', {
            async: false
        }),

        features: hasMany('feature', {
            async: false
        }),

        smokerFormatted: computed('smoker', function () {
            return this.get('smoker') === "Y" ? "Smoker" : "Non-smoker";
        }).readOnly(),

        genderFormatted: computed('gender', function () {
            return this.get('gender') === "F" ? "Female" : "Male";
        }).readOnly(),

        hasExpired: computed('expiryDate', function () {
            var today = new Date(),
                expiryDate = this.get('expiryDate');
            return today > expiryDate;
        }).readOnly(),

        apply: function apply(params) {
            var adapter = this.store.adapterFor(this.constructor.modelName);
            return adapter.apply(this, params);
        }
    });
});