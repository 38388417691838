define("projecthelm/routes/life-insurance/choose-cover", ["exports", "projecthelm/utils/iselect-tracking", "projecthelm/utils/adobe-tracking"], function (exports, _iselectTracking, _adobeTracking) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var inject = Ember.inject;
    exports.default = Ember.Route.extend({
        currentUser: inject.service(),

        beforeModel: function beforeModel() {
            // Redirect to about you if it is invalid
            if (!this.controllerFor('life-insurance.about-you').get('isValid')) {
                this.replaceWith('life-insurance.about-you');
            }
        },
        model: function model() {
            return this.get('currentUser.lifeQuote');
        },
        afterModel: function afterModel(model) {
            (0, _iselectTracking.trackNeeds2)(model, "life");
            _adobeTracking.stepOne(model, "life");
        },
        resetController: function resetController(controller, isExiting) {

            if (isExiting) {
                controller.send('reset');
            }
        }
    });
});