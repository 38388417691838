define('projecthelm/components/modal-trigger', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service,
        htmlSafe = Ember.String.htmlSafe;
    exports.default = Ember.Component.extend({
        tagName: 'a',
        attributeBindings: ['data-context', 'style'],
        modalName: undefined,
        model: undefined,
        options: {},
        style: htmlSafe('cursor: pointer'),
        modal: service(),

        init: function init() {
            this._super.apply(this, arguments);
            this.content = this.get('text').htmlSafe();
        },
        click: function click() {
            this.get('modal').open(this.get('modalName'), this.get('model'), this.get('options'));
        }
    });
});