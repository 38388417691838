define('projecthelm/controllers/income-protection/compare', ['exports', 'projecthelm/controllers/compare'], function (exports, _compare) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed,
        inject = Ember.inject;
    exports.default = _compare.default.extend({
        currentUser: inject.service(),
        quote: computed.alias('currentUser.activeQuote'),
        isIpQuote: computed.equal('quote.quoteType', 'ip')
    });
});