define('projecthelm/components/x-header', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var inject = Ember.inject;
    exports.default = Ember.Component.extend({
        theme: inject.service(),
        modal: inject.service(),
        customiser: inject.service(),
        activeQuote: Ember.computed.alias('customiser.activeQuote'),

        quoteType: Ember.computed('activeQuote.quoteType', function () {
            return this.get('activeQuote.quoteType') === 'life' ? 'Life Insurance' : 'Income Protection';
        }),

        actions: {
            openModal: function openModal() {
                this.get('modal').open('need-help');
            }
        }
    });
});