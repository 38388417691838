define('projecthelm/components/product-price', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['product-price'],
        theme: Ember.inject.service(),

        premiumFrequency: Ember.computed('frequency', function () {
            switch (this.get('frequency')) {
                case 'Y':
                    return 'a';
                case 'H':
                    return 'hy';
                default:
                    return 'm';
            }
        }),

        premiumFrequencyFull: Ember.computed('frequency', function () {
            switch (this.get('frequency')) {
                case 'Y':
                    return 'year';
                case 'H':
                    return 'half-year';
                default:
                    return 'month';
            }
        })
    });
});