define('projecthelm/adapters/ip-quote', ['exports', 'projecthelm/adapters/quote'], function (exports, _quote) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _quote.default.extend({
        urlForFindRecord: function urlForFindRecord(id) {
            var url = this.get('host') + '/' + this.get('namespace') + '/quotes/' + id + '?quoteType=ip';
            return url;
        }
    });
});