define('projecthelm/components/x-datepicker', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        class: Ember.computed('media.matches.[]', function () {
            if (this.get('media.isPalm')) {
                return 'ember-power-calendar--small';
            }
            return 'ember-power-calendar--medium';
        })
    });
});