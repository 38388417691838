define('projecthelm/models/feature', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = _emberData.default.Model.extend({
    name: attr('string'),
    tip: attr('string'),
    category: belongsTo('category', {
      async: false
    })
  });
});