define('projecthelm/mixins/modal', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        actions: {
            close: function close() {
                this.send('closeModal');
            },
            reset: function reset() {}
        }
    });
});