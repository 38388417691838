define('projecthelm/routes/life-insurance/about-you', ['exports', 'projecthelm/mixins/check-validation-before-transition', 'projecthelm/utils/iselect-tracking'], function (exports, _checkValidationBeforeTransition, _iselectTracking) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var inject = Ember.inject;
    exports.default = Ember.Route.extend(_checkValidationBeforeTransition.default, {

        currentUser: inject.service(),
        theme: inject.service(),

        afterModel: function afterModel() {
            if (this.get('theme.id') === 'lifebroker') {
                document.title = 'Compare Life Insurance Quotes |TPD | Trauma | Lifebroker';
                document.querySelector('meta[name="description"]').setAttribute("content", 'Compare life insurance quotesfrom leading insurance providers in a few simple steps.');
            }
        },
        model: function model() {
            return this.get('currentUser.lifeQuote');
        },


        setupController: function setupController(controller, model) {
            this._super(controller, model);
            var currentUser = this.get('currentUser');

            if (currentUser.get('calculatorResturnVisit')) {
                controller.send('handleCalculatorReturnVisit');
                currentUser.set('calculatorResturnVisit', false);
            }
            (0, _iselectTracking.trackNeeds1)('life');
        }

    });
});