define('projecthelm/routes/life-insurance/compare/simple/shortlist', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        controllerName: 'life-insurance.compare',

        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('shortlist', true);
        }
    });
});