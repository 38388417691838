define('projecthelm/mixins/check-validation-before-transition', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        /**
         * Reset display Errors on controller on exit route
         *
         * @method resetControllerValidation
         */
        resetControllerValidation: Ember.on('deactivate', function () {
            this.controller.set('displayErrors', false);
        }),

        actions: {
            willTransition: function willTransition(transition) {
                if (transition.intent.name === 'tal-calc-calculator') return; // don't block transition to calculator...
                // Block transition controller validation returns invalid
                if (!this.controller.get('isValid')) {
                    this.controller.set('displayErrors', true);
                    transition.abort();
                }
            }
        }
    });
});