define('projecthelm/controllers/modals/got-feedback', ['exports', 'projecthelm/mixins/modal', 'projecthelm/controllers/formie-form', 'projecthelm/utils/forms'], function (exports, _modal, _formieForm, _forms) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _formieForm.default.extend(_modal.default, {
        formieFormHandle: 'gotFeedback',

        validations: {
            'question': {
                presence: { message: "Please enter your feedback" }
            },
            'customerName': _forms.validation.name,
            'email': _forms.validation.email,
            'phone': _forms.validation.phone
        },

        actions: {
            reset: function reset() {
                this._reset();
                this._super();
            },
            formSubmissionSuccess: function formSubmissionSuccess() {
                // Push event to GTM
                dataLayer.push({
                    event: 'submitModal',
                    name: 'gotFeedback'
                });
            }
        }
    });
});