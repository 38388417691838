define('projecthelm/controllers/modals/gating', ['exports', 'projecthelm/mixins/modal', 'projecthelm/controllers/lead', 'projecthelm/utils/forms', 'projecthelm/utils/iselect-tracking', 'projecthelm/utils/adobe-tracking'], function (exports, _modal, _lead, _forms, _iselectTracking, _adobeTracking) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var inject = Ember.inject;
    exports.default = _lead.default.extend(_modal.default, {
        progress: 'Start',
        gating: true,
        sendEmail: false,
        theme: inject.service(),

        validations: {
            'lead.name': _forms.validation.name,
            'lead.phone': _forms.validation.phone,
            'lead.email': _forms.validation.email
        },

        init: function init() {
            this._super.apply(this, arguments);
            this.get('currentUser').setProperties({
                talApplyEnabled: undefined
            });
            this.set('sendEmail', this.get('theme.id') !== 'compare-the-market');

            localStorage.removeItem('talApplyEnabled');
        },


        actions: {

            /**
             * Reset modal and show flash message when lead is submitted
             *
             * @method isSubmittedObserver
             */
            leadSubmission: function leadSubmission() {
                this._reset();
                this.send('scrollToTop');
                (0, _iselectTracking.trackResults)();
                _adobeTracking.gating();
                _adobeTracking.results();

                var quoteType = window.location.href.includes("income") ? "Income Protection" : "Life Insurance";

                var occupation = this.get("currentUser.activeQuote.occupation");

                // Push event to GTM
                dataLayer.push({
                    event: 'submitContactDetails',
                    product_category: quoteType,
                    gender: this.get("currentUser.activeQuote.gender") === 'M' ? 'Male' : 'Female',
                    age: this.get("currentUser.activeQuote.age"),
                    postcode: this.get("currentUser.activeQuote.postcode"),
                    state: this.get("currentUser.activeQuote.state").toUpperCase(),
                    smoking: this.get("currentUser.activeQuote.smoker") === 'N' ? 'No' : 'Yes',
                    occupation: occupation && occupation.value,
                    life_cover: this.get("currentUser.activeQuote.lifeBenefit"),
                    tpd: this.get("currentUser.activeQuote.tpdBenefit"),
                    trauma_cover: this.get("currentUser.activeQuote.traumaBenefit"),
                    annual_income: this.get("currentUser.activeQuote.income"),
                    monthly_benefit: this.get("currentUser.activeQuote.benefit")
                });
            },


            /**
             * Reset modal and show flash message when lead is submitted
             *
             * @method isSubmittedObserver
             */
            leadSubmissionSuccess: function leadSubmissionSuccess() {
                dataLayer.push({
                    event: 'submitModal',
                    name: 'gating'
                });

                this.send('triggerSecondaryQuoteFlash');
            }
        }
    });
});