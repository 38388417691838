define("projecthelm/utils/global-tracking", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var showResults = exports.showResults = function showResults(quoteType, products, tab) {
    // Don't track products until API data is returned to decide whether applyOnTalSite buttons are active.
    var poll = window.setInterval(function () {
      // Only passes with strings for 'true' and 'false' from API response 
      var talApplyVerified = localStorage.getItem('talApplyEnabled');
      if (!talApplyVerified) return;
      window.clearInterval(poll);
      quoteType = quoteType === "life" ? "Life Insurance" : "Income Protection";

      var items = products.map(function (product, index) {
        var item_variant = product.get('applyOnTalSite') ? 'Online' : 'Now';
        return {
          item_id: product.get("id"), // Offer id
          item_name: product.get("company") + " - " + product.get("name"), // Offer name
          discount: 0, // Offer discount
          index: index + 1, // Offer position
          item_brand: product.get("company"), // Brand name
          item_category: quoteType, // Offer category
          item_variant: item_variant,
          price: product.get("valueRounded"), // Offer price
          price_cd: "" + product.get("valueRounded"), // Offer price as dimension,
          location_id: "" + product.get("valueRounded") // Offer price as dimension
        };
      });

      dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      dataLayer.push({
        event: "GTM",
        event_name: "view_item_list",
        currency: "AUD", // Currency
        product_category: quoteType,
        ecommerce: {
          item_list_name: tab, // List name
          items: items
        }
      });
    }, 500);
  };

  var selectProduct = exports.selectProduct = function selectProduct(product) {
    var quoteType = window.location.href.includes("income") ? "Income Protection" : "Life Insurance";
    var tab = window.location.href.includes("detailed") ? "Features" : "Price";
    var item_variant = product.get('applyOnTalSite') ? 'Online' : 'Now';
    var index = document.querySelector("#" + product.get('id')).closest('.comparison-table__product-cell, .quick-quote').dataset.productPosition;

    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    dataLayer.push({
      event: "GTM",
      event_name: "select_item",
      currency: "AUD", // Currency
      product_category: quoteType, // Page category
      product_name: product.get("company") + " - " + product.get("name"),
      ecommerce: {
        item_list_name: tab, // List name
        items: [{
          item_id: product.get("id"), // Offer id
          item_name: product.get("company") + " - " + product.get("name"), // Offer name
          discount: 0, // Offer discount
          index: Number(index) + 1, // Offer position
          item_brand: product.get("company"), // Brand name
          item_variant: item_variant,
          item_category: quoteType, // Offer category
          price: product.get("valueRounded"), // Offer price
          price_cd: "" + product.get("valueRounded"), // Offer price as dimension,
          location_id: "" + product.get("valueRounded") // Offer price as dimension
        }]
      }
    });
  };

  var submitSidebarForm = exports.submitSidebarForm = function submitSidebarForm(product) {
    var quoteType = window.location.href.includes("income") ? "Income Protection" : "Life Insurance";
    var tab = window.location.href.includes("detailed") ? "Features" : "Price";
    var index = document.querySelector("#" + product.get('id')).closest('.comparison-table__product-cell, .quick-quote').dataset.productPosition;

    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    dataLayer.push({
      event: "GTM",
      event_name: "add_to_cart",
      currency: "AUD", // Currency
      product_name: product.get("company") + " - " + product.get("name"),
      product_category: quoteType, // Page category
      ecommerce: {
        item_list_name: tab, // List name
        items: [{
          item_id: product.get("id"), // Offer id
          item_name: product.get("company") + " - " + product.get("name"), // Offer name
          discount: 0, // Offer discount
          index: Number(index) + 1, // Offer position
          item_brand: product.get("company"), // Brand name
          item_category: quoteType, // Offer category
          price: product.get("valueRounded"), // Offer price
          price_cd: "" + product.get("valueRounded"), // Offer price as dimension
          location_id: "" + product.get("valueRounded"), // Offer price as dimension
          quantity: 1
        }]
      }
    });
  };

  var closeRedirectModal = exports.closeRedirectModal = function closeRedirectModal(product) {
    var quoteType = window.location.href.includes("income") ? "Income Protection" : "Life Insurance";

    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    dataLayer.push({
      event: "GTM",
      event_name: "close_redirect_modal",
      product_name: product.get("company") + " - " + product.get("name"),
      product_category: quoteType // Page category
    });
  };

  var confirmRedirectModal = exports.confirmRedirectModal = function confirmRedirectModal(product) {
    var quoteType = window.location.href.includes("income") ? "Income Protection" : "Life Insurance";
    var index = document.querySelector("#" + product.get('id')).closest('.comparison-table__product-cell, .quick-quote').dataset.productPosition;
    var tab = window.location.href.includes("detailed") ? "Features" : "Price";

    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    dataLayer.push({
      event: "GTM",
      event_name: "confirm_redirect_modal",
      product_name: product.get("company") + " - " + product.get("name"),
      product_category: quoteType, // Page category
      currency: "AUD", // Currency
      ecommerce: {
        item_list_name: tab, // List name
        items: [{
          item_id: product.get("id"), // Offer id
          item_name: product.get("company") + " - " + product.get("name"), // Offer name
          discount: 0, // Offer discount
          index: Number(index) + 1, // Offer position
          item_brand: product.get("company"), // Brand name
          item_category: quoteType, // Offer category
          item_variant: 'Online', // Application type: Now | Online
          price: product.get("valueRounded"), // Offer price
          price_cd: "" + product.get("valueRounded"), // Offer price as dimension
          quantity: 1
        }]
      }
    });
  };

  var generalData = exports.generalData = function generalData(life_cover, tpd, trauma_cover, annual_income, monthly_benefit, gender, age, postcode, state, smoking, occupation, event_name) {
    var quoteType = window.location.href.includes("income") ? "Income Protection" : "Life Insurance";

    dataLayer.push({
      event: 'GTM',
      product_category: quoteType,
      event_name: event_name,
      gender: gender,
      age: age,
      postcode: postcode,
      state: state,
      smoking: smoking,
      occupation: occupation && occupation.value,
      life_cover: life_cover,
      tpd: tpd,
      trauma_cover: trauma_cover,
      annual_income: annual_income,
      monthly_benefit: monthly_benefit
    });
  };
});