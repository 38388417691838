define('projecthelm/services/config', ['exports', 'ember-config-service/services/config'], function (exports, _config) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _config.default;
    }
  });
});