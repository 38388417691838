define('projecthelm/routes/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        theme: Ember.inject.service(),

        beforeModel: function beforeModel() {
            if (this.get('theme.id') !== 'lifebroker') {
                this.transitionTo('life-insurance');
            }
        },
        renderTemplate: function renderTemplate() {
            this.render('cms');
        }
    });
});